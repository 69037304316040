/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__flyout {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 500px;
    background-color: rgba(0, 0, 0, .25);
    z-index: 600;
    transition: transform .2s @easing,
                width .5s @easing;
    max-width: 100%;

    @media (max-width: @break-m) {
        width: 100% !important; // needed because the flyout width is set inline
        min-width: 0;
        margin-top: -67px;
        background-color: @c-grey;
    }

    &:not(&--is-open) {
        transform: translateX(100%);
        z-index: 499;
    }

    &__resizeHandle {
        position: absolute;
        top: 0;
        bottom: 0;
        right: ~"calc(100% - 2px)";
        width: 4px;
        cursor: col-resize;
        transition: background-color .2s ease-in;
        z-index: 1;

        &:hover {
            background-color: #0d5e28;

            html.is-dark-mode & {
                background-color: #138b3b;
            }
        }

        &::before {
            position: absolute;
            left: -5px;
            right: -5px;
            top: 0;
            bottom: 0;
            content: ' ';
        }

        &:active::before {
            left: -100vw; // prevents quick resizing from entering a sub-document (web proofing)
            right: -100vw;
        }
    }

    &:not(&--is-open) &__resizeHandle {
        display: none;
    }

    &__panel {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        transform: translateZ(0); // fixes: 'repaints on scroll'
    }

    &__content {
        padding: 20px;
    }

    &__arrow {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 100%;
        top: 50%;
        margin-right: 12px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.25);
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }

        &__icon {
            width: 24px;
            height: 24px;
            fill: @c-white;
        }

        &__up {
            margin-top: -100px;
        }

        &__down {
            margin-top: 50px;
        }
    }

    &__handle {
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, .45);
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        text-align: center;
        cursor: pointer;
        padding: 10px;
        transition: background-color .2s @easing;
        min-width: 75px;
        min-height: 60px;

        @media (max-width: @break-m) {
            min-width: 35px;
            min-height: 35px;
        }

        .spinner {
            &__outer {
                transform: scale(0.75);
            }

            &__inner:before {
                display: none;
            }

            &__top,
            &__left,
            &__right,
            &__bottom {
                background-color: @c-white;
            }
        }
    }

    &--is-open &__handle {
        background-color: rgba(0, 0, 0, .25);

        .app__flyout__label {
            font-size: 50px;
        }
    }

    &__label {
        font-size: 36px;
        line-height: 36px;
        color: @c-white;

        @media (max-width: @break-m) {
            font-size: 20px;
            line-height: 30px;
        }
    }

    &__heading,
    &__privateComments {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 4px;
        color: @c-white;
    }

    &__heading {
        @media (max-width: @break-m) {
            display: none;
        }
    }

    &__privateComments {
        margin-bottom: 2px;
        white-space: nowrap;
        background-color: #ffe6d3;
        color: #cb671e;
        border-radius: 100px;
        padding: 0 6px;
        display: flex;
        align-items: center;
        gap: 0.3em;

        &__icon {
            display: inline;
            fill: #cb671e;
            height: 14px;
            width: 14px;

            svg {
                height: 100%;
                width: 100%;
            }

            @media (min-width: (@break-m + 1)) {
                display: none;
            }
        }

        &__label {
            display: inline;

            @media (max-width: @break-m) {
                display: none;
            }
        }
    }
}
