/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__proof {
    &__dialog {
        .app__modal__content {
            max-width: 1000px;
        }

        @media (max-width: @break-m) {
            .app__modal__container {
                width: 100%;
            }
        }
    }

    &__secondary-title {
        opacity: 0;
        transition: opacity .2s ease-in-out;
        position: absolute;
        transform: translateY(-50%);
        top: -50px;
        left: 100px;
        overflow: hidden;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: @c-txt-m;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        visibility: visible;
        -webkit-box-orient: vertical;
        word-wrap: break-word;

        html.hide-header & {
            opacity: 1;
        }
    }

    &__container {
        position: fixed;
        top: 100px;
        left: 0;
        right: 0;
        bottom: 100px;
        transform: translateY(0);
        transition: top .2s ease-in-out;

        @media (max-width: @break-m) {
            top: 67px;
            bottom: 67px;
        }

        &__compare {
            .app__flyout {
                top: -100px;
            }
        }
    }

    .app__flyout {
        transition: all .2s ease-in-out;

        @media (min-width: @break-m) {
            html.feature-no-header &,
            html.hide-header & {
                top: -100px;
            }
        }

    }

    &__video-container {
        position: absolute;
        top: -100px;
        left: 0;
        right: 0;
        bottom: -100px;
        transition: right .2s @easing;
        z-index: 1;

        &__thumb {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            opacity: .5;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
        }

        @media (max-width: @break-m) {
            left: 0;
            right: 0;
        }
    }

   &__audio-container {
        position: absolute;
        left: 100px;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: @break-m) {
            left: 0;
            right: 0;
        }

        &--waveform-tooltip {
            display: flex;
            justify-content: center;
            width: -moz-fit-content;
            width: fit-content;
            position: relative;
            padding: 6px 10px;
            border-radius: 6px;
            color: @c-white;
            transform: translateX(-50%);
            background-color: @c-txt-d;
            font-size: 12px;
        }
    }

    // &__container .app__video-player__element {
    //     transition: width .2s @easing,
    //                 height .2s @easing;
    // }

    &__footer {
        position: absolute;
        bottom: -100px;
        left: 0;
        right: 0;
        height: 101px;
        border-top: 1px solid @c-border;
        z-index: 600;

        @media (max-width: @break-m) {
            height: 68px;
            bottom: -68px;
        }

        .page__proof__toolbar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }

        &--has-dialog {
            @media (min-width: @break-m) {
                .page__proof__toolbar {
                    filter: blur(5px);
                }
            }

            @media (max-width: @break-m) {
                height: 100px;
            }
        }

        &__exit-btn {
            display: none;
            height: 32px;
            width: 32px;
            position: absolute;
            left: 20px;
            top: -60px;
        }
    }

    &__toolbar {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        padding: 0 25px;
        background-color: @c-grey-ll;
        transition: transform .25s @easing,
                    opacity .25s @easing,
                    filter .25s @easing;

        @media (max-width: @break-m) {
            height: 67px;
        }

        @media (max-width: @break-6) {
            padding: 0;
        }

        &__version {
            @media (max-width: @break-m) {
                display: none;
            }
        }

        &.ng-enter {
            opacity: 0;
            transform: translateY(100px);
        }

        &.ng-enter-active,
        &.ng-leave {
            transform: translateY(0);
            opacity: 1;
        }

        &.ng-leave-active {
            opacity: 0;
            transform: translateY(100px);
        }

        #toggle-keyboard-shortcuts {
            margin-left: -15px;
        }

        [select] {
            display: inline-block;
            vertical-align: middle;

            @media (min-width: @break-m) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &__group {
            flex: 1;
            font-size: 0;
            white-space: nowrap;

            * {
                vertical-align: middle;
            }

            &__separator:not(:first-child):not(:last-child) {
                background-color: @c-border;
                margin: auto 20px auto 24px;
                height: 32px;
                width: 2px;

                html.is-dark-mode & {
                    background-color: @c-darkgrey-divider;
                }

            }

            &__page-controls {
                position: relative;
            }

            &--left {
                z-index: 550;
                text-align: left;

                @media (max-width: @break-6) {
                    margin-right: 15px;
                    margin-left: 15px;
                }

                @media (max-width: @break-m) {
                    .page__proof__toolbar__button:not(.page__proof__toolbar__button--mobile) {
                        display: none;
                    }

                    &.page__proof__toolbar__group--confirm {
                        display: none;
                    }
                }
            }

            &--right {
                text-align: right;

                .mobile-button {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    border-radius: 100%;
                    margin: 5px;
                    user-select: none;

                    svg {
                        width: 16px;
                        height: 16px;
                        margin: 12px;
                    }

                    &__editor-upload {

                        svg {
                            width: 16px;
                            height: 20px;
                            margin: 12px;
                        }
                    }

                    &__text {
                        font-size: 9px;
                        text-transform: uppercase;
                        text-align: center;
                        font-weight: bold;
                        margin-top: 15px;

                        &__send-to-editor {
                            margin-top: 14px;
                        }
                    }

                    &--green {
                        background-color: @c-green;
                        color: @c-white;

                        svg {
                            fill: @c-white;

                            .cls-1 {
                                fill: @c-white;
                            }
                        }

                        &__disabled {
                            opacity: 0.5;
                            pointer-events: none;
                        }
                    }

                    &--outline {
                        color: @c-green;
                        border: 1px solid @c-green;
                    }

                    &--grey {
                        background-color: @c-grey;
                        color: @c-txt-m;

                        svg {
                            fill: @c-txt-m;

                            .cls-1 {
                                fill:@c-grey-xd;
                            }
                        }
                    }
                }

                @media (max-width: @break-m) {
                    .page__proof__toolbar__button {
                        display: none;
                    }

                    .desktop-button {
                        display: none;
                    }
                }

                @media (min-width: @break-m) {
                    .mobile-button {
                        display: none;
                    }
                }

                @media (max-width: @break-6) {
                    margin-right: 15px;

                    .app__btn-group {
                        display: flex;
                        flex-direction: row;
                        margin-left: -20px;

                        .app__btn {
                            margin-top: 4px;
                            margin-bottom: 4px;
                        }
                    }
                }
            }

            &--center {
                text-align: center;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 25px;

                @media (max-width: @break-m) {
                    display: none;
                }
            }

            &__buttons {
                span {
                    display: inline-flex;
                }
            }
        }

        &__button {
            position: relative;
            display: inline-block;
            background-color: transparent;
            border: 0;
            padding: 0;
            margin: 0;
            outline: none;
            height: 75px;
            width: 75px;

            &--disabled {
                cursor: default;

                &:before {
                    content: ' ';
                    position: absolute;
                    top: 37px;
                    left: 19px;
                    width: 40px;
                    height: 2px;
                    background-color: @c-grey-icon;
                    transform: rotate(135deg);
                }
            }

            &:not(:disabled):not(&--active):not(&--video-buttons-disabled) {
                &:hover {
                    path, polygon, rect, circle {
                        fill: darken(@c-grey-icon, 20%);
                    }
                }
            }

            .sprite-icon {
                margin: 0 auto;
                transition: opacity .25s @easing;
            }

            &:not(:disabled):not(:hover) .sprite-icon {
                opacity: .5;
            }

            svg {
                width: 24px;
                height: 24px;
            }

            &__icon-compare {
                svg {
                    width: 31px;

                    path {
                        fill: @c-grey-icon; 

                        &:hover {
                            fill: darken(@c-grey-icon, 20%);
                        }
                    }
                }

                @media (max-width: @break-3) {
                    display: none;
                }
            }

            &__icon-checklist,
            &__icon-share-link {
                @media (max-width: @break-m) {
                    width: 100%;
                    height: 100%;
                }
            }

            &__icon-share-link {
                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            &__icon-checklist {
                svg {
                    width: 28px;
                    height: 28px;
                }
            }

            &__tooltip {
                position: absolute;
                top: 100%;
                left: 50%;
                font-size: 10px;
                text-transform: uppercase;
                color: @c-txt-k;

                transform: translateX(-50%);
                opacity: 0;

                transition: transform .25s @easing,
                            opacity .25s @easing;
            }

            &:hover &__tooltip {
                transform: translateX(-50%) translateY(-14px);
                opacity: 1;
            }

            &__download-progress {
                transform: translateX(-50%) translateY(-14px);
                opacity: 1;
            }

            &--small {
                width: 50px;
                svg {
                    width: 18px;
                    height: 18px;
                }

                @media (max-width: @break-m) {
                    display: none;
                }
            }

            &--big {
                svg {
                    width: 27px;
                    height: 27px;
                }
            }

            path, polygon, rect, circle {
                fill: @c-grey-icon;
                transition: fill .25s @easing;
            }

            &:disabled {
                path, polygon, rect {
                    fill: none;
                }
            }

            &--active {
                path, polygon, rect, circle {
                    fill: @c-green;
                    transition: fill .25s @easing;
                }

                &:hover  path, polygon, rect, circle {
                    fill: @c-green;
                }
            }

            &--mobile {
                height: 40px;
                width: 40px;
                border-radius: 100%;
                display: inline-block;
                margin: 0 5px;
                font-size: 20px;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
                cursor: pointer;

                @media (min-width: @break-m) {
                    display: none;
                }
            }

            &--comments {
                background-color: rgba(0, 0, 0, 0.45);
                color: @c-white;
                font-size: 16px;
                padding-top: 8px;
                margin-top: -7px;

                &-small {
                    font-size: 10px;
                    padding-top: 13px;
                }
            }

            &--video-buttons {
                &--skip {
                    svg {
                        width: 36px;
                        height: 36px;
                    }
                }

                svg {
                    width: 42px;
                    height: 42px;
                }
                &-playing {
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }

                &-disabled {
                    path, polygon, rect {
                        fill: lighten(@c-grey-icon, 20%);
                    }
                }
            }
        }

        &--confirm {
            opacity: 0;
            transform: translateY(100px);
        }

        &--confirmed {
            opacity: 1;
            transform: translateY(0px);
            background-color: @c-body;
        }

        &__print-button {
            display: inline-block;

            &__header {
                font-weight: 600;
            }
        }

        &__share-button {
            display: inline-block;

            @media (max-width: @break-m) {
                height: 24px;
                margin: 0 5px;
            }
        }
    }

    &__duration {
        font-size: 12px;
        text-transform: uppercase;
        color: @c-txt-k;
        font-variant-numeric: tabular-nums;

        html.is-dark-mode & {
            color: @c-txt-k-dark;
        }

        &--desktop {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 2px;
            left: 50%;
            transform: translateX(-50%);

            @media (max-width: @break-m) {
                display: none;
            }    
        }

        &--mobile {
            @media (min-width: @break-m) {
                display: none;
            }
        }
    }

    .grid {
        svg {
            width: 25px;
            height: 25px;
        }

        rect {
            transition: transform .25s @easing,
                        height .25s @easing;
        }

        &--active rect {
            @x-mod: 15px;
            @y-mod: 11px;
            // TODO Turn grid into a fake page icon

            &:nth-child(1) {
                transform: translateX(@x-mod) translateY(@y-mod);
            }
            &:nth-child(2) {
                transform: translateY(@y-mod);
            }
            &:nth-child(3) {
                transform: translateX(-@x-mod) translateY(@y-mod);
            }
            &:nth-child(7) {
                transform: translateX(@x-mod);
            }
            &:nth-child(8) {
            }
            &:nth-child(9) {
                transform: translateX(-@x-mod);
            }
            &:nth-child(4) {
                transform: translateX(@x-mod) translateY(-@y-mod);
            }
            &:nth-child(5) {
                transform: translateY(-@y-mod);
            }
            &:nth-child(6) {
                transform: translateX(-@x-mod) translateY(-@y-mod);
            }
        }
    }

    &__focus {
        top: 0px;
        bottom: 0px;

        .page__proof__video-container {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
        }

        .page__proof__video {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .px-canvas {
            top: 0;
            bottom: 0;
        }

        .WebProof {
            left: 100px;
            right: 100px;
            top: 10px;
            bottom: 10px;

            &--compact {
                right: 600px;
            }
        }

        .app__flyout {
            top: 0;

            @media (min-width: @break-m) {
                html.feature-no-header &,
                html.hide-header & {
                    top: 0;
                }
            }
        }

        .app__tools--mobile {
            position: fixed;
            top: 90%;
            left: 20px;
        }

        .page__proof__footer__exit-btn {
            display: inline-block;
         }
    }
}
