/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.PageHeading {
    text-align: center;
    margin: 100px 0;

    &--compact {
        margin-top: 60px;
        margin-bottom: 50px;
    }

    > h1 {
        font-size: 40px;
        margin-bottom: 20px;

        @media (max-width: @break-m) {
            font-size: 24px;
        }
    }

    > div {
        font-size: 16px;
        color: @c-txt-k;
    }

    @media (max-width: @break-m) {
        margin: 50px 0;
    }
}

.PageSubheading {
    text-align: left;
    margin: 20px 0;

    &__title {
      display: flex;
      align-items: center;

      &__helpBubble {
        padding-left: 6px;
      }
    }
    
    &--center {
        text-align: center;
    }

    > h2 {
        color: #555;
        font-size: 20px;
        margin-bottom: 10px;
    }

    > div {
        font-size: 16px;
        color: @c-txt-k;
    }

    @media (max-width: @break-m) {
        > h2 {
            font-size: 18px;
        }
        
        > div {
            font-size: 14px;
        }
    }
}
