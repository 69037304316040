/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__comparison {
    z-index: 11;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;

    &__toolbar-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100px;
        z-index: 12;
    }

    &__scrubber {
        position: absolute;
        left: 25%;
        right: 25%;

        &__left-version,
        &__right-version {
            content: ' ';
            position: absolute;
            top: -5px;
            cursor: pointer;
            border-radius: 100%;
            background-color: #f9f9f9;
            border: 1px solid #728182;
            width: 10px;
            height: 10px;

            &:hover {
                background-color: #dcdcde;
            }
        }

        &__left-version {
            left: -10px;

            &-active {
            background-color: @c-green;
            }
        }

        &__right-version {
            right: -10px;

            &-active {
            background-color: @c-green;
            }
        }

        &__button {
            position: absolute;
            top: -5px;
            left: 50%;
            height: 10px;
            width: 10px;
            border-radius: 100%;
            background-color: #999;
            transition: left .25s @easing;

            &:hover {
                background-color: #dcdcde;
            }

            &-active {
                background-color: @c-green;
            }

            &:before {
                content: ' ';
                position: absolute;
                top: 0;
                bottom: 0;
                left: -5px;
                right: -5px;
                cursor: pointer;
            }

            &-text {
                position: absolute;
                font-size: 14px;
                top: -20px;
                left: 50%;
                margin-left: -20px;
                width: 40px;
                background: #e1e5e6;
                border-radius: 100%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                z-index: 4;
                color: #999;
            }
        }
    }

    &__btn-bar {
        display: flex;
        padding: 15px 100px;
        height: 100%;
        align-items: center;
        justify-content: space-between;

        &--smart-compare {
            justify-content: center;
        }

        &-left {
            width: 40%;
            padding: 10px;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-right {
            width: 40%;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-center {
            display: inline-flex;

            &__smart-compare-btn {
                padding-top: 10px;
            }
        }

        &-left,
        &-right {
            &__pagination {
                display: inline-block;
                position: relative;
                vertical-align: middle;
            }

            .app__select {
                z-index: 1;
                min-width: 141px;
            }
        }

        &-left,
        &-center,
        &-right {
            path,
            polygon,
            rect {
                fill: @c-grey-icon;
                transition: fill .25s @easing;
            }

            &__select {
                display: inline-block;
                vertical-align: middle;
                align-self: center;
            }

            &__player {
                display: flex;
                flex-direction: column;
                padding-top: 11px;

                &-duration {
                    font-size: 12px;
                    color: @c-txt-k;
                    font-variant-numeric: tabular-nums;
                }
            }

            &__separator {
                background-color: #dcdcde;
                margin: auto 20px auto 24px;
                height: 32px;
                width: 2px;

                html.is-dark-mode & {
                    background-color: @c-darkgrey-divider;
                }
            }

            &__container {
                display: flex;
                justify-content: center;

                &-text {
                    color: #8c8c8c;
                    text-align: center;
                    font-size: 15px;
                    padding: 5px;
                    line-height: 2;
                }
            }

            &__version {
                position: relative;
                display: inline-flex;
            }

            &__button {
                background-color: transparent;
                border: none;
                outline: none;
                vertical-align: middle;

                &:hover {
                    path,
                    polygon,
                    rect {
                        fill: darken(@c-grey-icon, 20%);
                    }
                }

                svg {
                    width: 25px;
                    height: 25px;
                    fill: @c-grey-icon;

                    &:hover {
                        fill: #444;
                    }
                }

                &:disabled {
                    path, polygon, rect {
                        fill: none;
                    }
                }

                &--small {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }

                &--medium {
                    display: flex;
                    justify-content: center;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }

                &--grid {
                    padding-left: 30px;
                    padding-right: 30px;
                }

                &__tool {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 75px;
                    height: 75px;
                }

                &--flip {
                    display: flex;
                    justify-content: center;
                    width: 50px;
                    padding-left: 15px;

                    svg {
                        width: 36px;
                        height: 36px;
                    }
                }

                &--slider,
                &--layer {
                    margin-left: 26px;
                    width: 44px;

                    &--active {
                        svg,path {
                            fill: @c-green;
                        }
                        
                        &:hover {
                            svg,path {
                                fill: @c-green-ld;
                            }
                        }
                    }
                }

                &__icon {
                    &--layer {
                        display: flex;
                        justify-content: center;
                        width: 28px;

                        svg {
                            height: 28px;
                            width: 28px;
                        }

                    }

                    &--slider {
                        display: flex;
                        justify-content: center;
                        width: 28px;
                        padding-left: 1px;

                        svg {
                            height: 26px;
                            width: 26px;
                        }
                    }
                }
            }
        }
    }

    &__no-compare-text {
        display: inline-block;
        text-align: center;
        vertical-align: sub;
        text-transform: uppercase;
        color: @c-txt-k;

        p > {
            font-size: 10px;
            margin: 0;
        }
    }

    &__compare-btn {
        display: flex;
        align-items: center;

        &__group {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &-active {
                align-items: center;
                justify-content: center;
                gap: 10px;
            }
        }

        .app__select {
            min-width: 162px;
            display: inherit;
        }

        &-spinner {
            bottom: 5px;
        }
    }

    &__pagination-text {
        font-size: 12px;
        text-transform: uppercase;
        color: @c-txt-k;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
