/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/*

    .LESS include - Profile

*/

.view-edit-avatar	{
    .rel;
}

.user-avatar {
    .rel; 
    .cen; 
    margin-bottom: @gap*3; 
    margin-top: 
    @gap*4; 
    .transition(500ms);
}

.toggle-text {
    font-size: 14px;
    color: @c-grey-xd;
}

.switch {
    margin-left: 47px;
    margin-top: -46px;
    
    .cmn-toggle {
        position: absolute;
        margin-left: -9999px;
        visibility: hidden;
    }

    .cmn-toggle + label {
        display: block;
        position: relative;
        cursor: pointer;
        outline: none;
        user-select: none;
    }

    input.cmn-toggle-round-flat + label {
        padding: 2px;
        width: 65px;
        height: 30px;
        background-color: @c-green;
        border-radius: 60px;
        transition: background 0.4s;
    }

    input.cmn-toggle-round-flat + label:before,
    input.cmn-toggle-round-flat + label:after {
        display: block;
        position: absolute;
        content: "";
    }
    
    input.cmn-toggle-round-flat + label:before {
        top: 2px;
        left: 2px;
        bottom: 2px;
        right: 2px;
        background-color: @c-white;
        border-radius: 24px;
        transition: background 0.4s;
    }

    input.cmn-toggle-round-flat + label:after {
        top: 4px;
        left: 4px;
        bottom: 4px;
        width: 22px;
        background-color: @c-green;
        border-radius: 20px;
        transition: margin 0.4s, background 0.4s;
    }
    
    input.cmn-toggle-round-flat:checked + label {
        background-color: @c-green;
    }

    input.cmn-toggle-round-flat:checked + label:after {
        margin-left: 34px;
        background-color: @c-green;
    }
}
