/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.ToggleView {
    display: block;
    margin-left: auto;
    margin-right: auto;

    svg {
        width: 20px;
        height: 20px;
        margin: 5px;
        transition: fill .25s @easing,
                    transform .25s @easing;
    }

    &--selected svg {
        transform: scale(1.3);
    }

    .InlineSVG:not(.ToggleView--selected) svg:hover {
        cursor: pointer;
    }

    &--grey {
        svg {
            fill: #999;
        }

        .ToggleView--selected svg {
            fill: @c-green;
        }

        .InlineSVG:not(.ToggleView--selected) svg:hover {
            fill: #444;
        }
    }

    &--green {
        svg {
            fill: @c-grey-d;
        }

        .ToggleView--selected svg {
            fill: #fff;
        }
    }
}
