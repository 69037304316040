/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.rzslider {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 4px;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rzslider.with-legend {
  margin-bottom: 40px;
}

.rzslider[disabled] {
  cursor: not-allowed;
}

.rzslider[disabled] .rz-pointer {
  cursor: not-allowed;
  background-color: #d8e0f3;
}

.rzslider span {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
}

.rzslider .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}

.rzslider .rz-bar-wrapper {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 32px;
  padding-top: 16px;
  margin-top: -16px;
  box-sizing: border-box;
}

.rzslider .rz-bar-wrapper.rz-draggable {
  cursor: move;
}

.rzslider .rz-bar {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 1px;
  background: @c-txt-k;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.rzslider .rz-bar.rz-selection {
  z-index: 2;
  background: #0db9f0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.rzslider .rz-pointer {
  top: -20px;
  z-index: 3;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 100%;
  outline: 0;
}

.rzslider .rz-pointer:hover:after {
  background-color: #ffffff;
}

.rzslider .rz-pointer.rz-active {
  z-index: 4;
}

.rzslider .rz-pointer.rz-active:after {
  background-color: #451aff;
}

.rzslider .rz-bubble {
  display: block;
  top: -20px;
  color: #999;
  cursor: default;
  z-index: 2;
  font-size: 14px;
  background-color: #e1e5e6;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  line-height: 40px;
}