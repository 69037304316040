/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* Footer =======================================================================================*/
footer {
    .cen;

    color: @c-txt-m;
    border-top: @border;
    padding-left: @gap * 2;
    padding-right: @gap * 2;

    &.marketing-footer {
        margin-bottom: -50px;
    }

    .footer-nav {
        .collapsed {
            max-width: 0;
            padding: 0;
        }

        a,
        a:active,
        a:visited {
            overflow: hidden;
            white-space: nowrap;
            max-width: 200px;

            padding: 0 40px;

            color: #999;
            text-transform: uppercase;
            display: inline-block;
            margin: 0 0 0 -4px;
            font-size: 12px;
            font-weight: bold;
        }

        a:hover {
            color: @c-green;
        }

        .social	{
            .collapsed;
        }

        &.show-social {
            a {
                .transition(500ms);
            }

            .social {
                max-width: 200px;
                padding: 0 @gap * 2;
            }

            .follow	{
                .collapsed;
            }
        }
    }
}

@media all and (max-width: @break-4) {
    footer {
        .footer-nav {
            a {
                &,
                &:active,
                &:focus {
                    padding: 0 @gap;
                }
            }

            &.show-social {
                .social {
                    padding: 0 @gap;
                }
            }
        }
    }
}

@media all and (max-width: @break-m) {
    .footer-nav {
        a {
            padding: 0 @gap / 2;
            font-size: 10px;

            &:active,
            &:focus {
                padding: 0 @gap !important;
            }
        }
    }
}

.footer-note {
    margin-top: 26px;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 16px;
}
