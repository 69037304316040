/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.OAuthAuthorize {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;

    &Details {
        position: relative;
        width: 100%;
        max-width: 400px;
        background-color: #fff;
        border-radius: 6px;
        padding: 32px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .1);
        overflow: hidden;

        &__connection {
            position: relative;
            display: flex;
            justify-content: center;
            margin-bottom: 25px;
        }

        &__3rd-party-logo,
        &__pageproof-logo {
            width: 60px;
            height: 60px;
        }

        &__3rd-party-logo {
            margin-right: 120px;
        }

        &__tick {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -14px 0 0 -14px;

            &,
            svg {
                width: 28px;
                height: 28px;
            }

            svg {
                position: absolute;
                background-color: @c-green;
                box-shadow: 0 0 0 5px white;
                border-radius: 100%;
                padding: 4px;
            }

            path {
                fill: #fff;
            }

            &:before {
                content: ' ';
                position: absolute;
                width: 80px;
                height: 1px;
                border-top: 1px dashed #ccc;
                top: 50%;
                left: -26px;
            }
        }

        @media (max-width: 350px) {
            &__3rd-party-logo {
                margin-right: 0;
            }

            &__pageproof-logo,
            &__tick {
                display: none;
            }
        }

        &__pageproof-logo {
            padding-left: 8px;
            padding-right: 8px;
        }

        &__name {
            font-size: 20px;
            position: relative;
        }

        &__permissions {
            color: #666;
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }

    @media (max-width: @break-m) {
        top: 67px;
    }

    &Details {
        .spinner__top,
        .spinner__left,
        .spinner__right,
        .spinner__bottom {
            background-color: #333;
        }
    }
}