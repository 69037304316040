/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.EmailBrandingPreview {
    position: relative;

    &,
    &__background {
        display: block;
        width: 600px; // not responsive

        @media (max-width: @break-m) {
            width: 100%;
        }    
    }

    &:not(:hover) &__options,
    &:hover &__image {
        opacity: 0;
        pointer-events: none;
    }

    &__image,
    &__options {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 80px;
        transition: opacity .2s @easing;
    }

    &__options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
    }

    &--leftAlign {
        margin-right: auto;
    }

    &--centerAlign {
        margin-right: auto;
        margin-left: auto;
    }

    &--rightAlign {
        margin-left: auto;
    }

    &__image {
        display: block; 
        max-width: 100%;
        object-fit: contain;

        &--top {
            object-position: bottom;
        }

        &--bottom {
            object-position: top;
        }
    }

    &__banner {
        position: relative;
        margin: 5px 0;
        height: 80px;
    }
}
