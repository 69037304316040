/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Radio {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 100%;
    border: inset 3px rgba(0, 0, 0, .15);
    background-color: transparent;
    cursor: default; // override the default button cursor (pointer)

    &:focus {
        outline: none;
    }

    &__dot {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 14px;
        height: 14px;
        margin-top: -7px;
        margin-left: -7px;
        border-radius: 100%;
        background-color: transparent;
        transition: transform .2s @easing,
                    background-color .2s @easing;
    }

    &:not(&--selected) &__dot {
        transform: scale(.5);
    }

    &:not(&--selected) &__input:hover + &__dot,
    &:not(&--selected) &__input:focus + &__dot,
    &:not(&--selected):hover &__dot,
    &:not(&--selected):focus &__dot {
        background-color: #ddd;
    }

    &--green&--selected &__dot {
        background-color: @c-green;
    }

    &--green&--selected &__input:hover + &__dot,
    &--green&--selected &__input:focus + &__dot,
    &--green&--selected:hover &__dot,
    &--green&--selected:focus &__dot {
        background-color: @c-green-d;
    }

    &--white&--selected &__dot {
        background-color: #ddd;
    }

    &--white&--selected &__input:hover + &__dot,
    &--white&--selected &__input:focus + &__dot,
    &--white&--selected:hover &__dot,
    &--white&--selected:focus &__dot {
        background-color: #fff;
    }

    &__input {
        .invisible();
    }
}
