/* Copyright (C) PageProof Holdings limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * September 2014
 */

/*

	.LESS include - Interactive elements

*/

/* Animations ------------------------------------------------------------------------------------------------------------*/
.anim-props( @duration, @name, @count:infinite )		{
	-webkit-animation-iteration-count: @count; -webkit-animation-duration: @duration; -webkit-animation-name: @name; -webkit-animation-fill-mode: forwards;
	-moz-animation-iteration-count: @count; -moz-animation-duration: @duration; -moz-animation-name: @name; -moz-animation-fill-mode: forwards;
	-ms-animation-iteration-count: @count; -ms-animation-duration: @duration; -ms-animation-name: @name; -ms-animation-fill-mode: forwards;
	animation-iteration-count: @count; animation-duration: @duration; animation-name: @name; animation-fill-mode: forwards;
}

.anim-alert												{

	@anim-time : 1000ms;

	.frames()						{
		10% 	{ .transform( none ); }
		25% 	{ .transform( translateY( -10px ) ); }
		75% 	{ .transform( translateY( 0 ) ); }
		90% 	{ .transform( none ); }
	}
	
	@-webkit-keyframes animAlert 	{ .frames; }
	@-moz-keyframes animAlert 		{ .frames; }
	@-ms-keyframes animAlert 		{ .frames; }
	
	.anim-props( @anim-time, animAlert );
	
}
.anim-bounce-once										{

	@anim-time : 1000ms;

	.frames()						{
		10% 	{ .transform( none ); }
		25% 	{ .transform( translateY( -10px ) ); }
		75% 	{ .transform( translateY( 0 ) ); }
		90% 	{ .transform( none ); }
	}
	
	@-webkit-keyframes animAlert 	{ .frames; }
	@-moz-keyframes animAlert 		{ .frames; }
	@-ms-keyframes animAlert 		{ .frames; }
	
	.anim-props( @anim-time, animAlert, 1 );
	
}
.anim-highlight-pin										{

	@anim-time : 2000ms;

	.frames()						{
		0%	 	{ .transform( scale(0) ); }
		50% 	{ .transform( scale(1.25) ); }
		100% 	{ .transform( scale(0) ); }
	}
	
	@-webkit-keyframes animPin	 	{ .frames; }
	@-moz-keyframes animPin 		{ .frames; }
	@-ms-keyframes animPin	 		{ .frames; }
	
	.anim-props( @anim-time, animPin, 1 );
	
}
.anim-highlight-pin2									{

	@anim-time : 2000ms;

	.frames()						{
		0%	 	{ .transform( scale(1) ); }
		25% 	{ .transform( scale(2) ); }
		50% 	{ .transform( scale(1) ); }
		75% 	{ .transform( scale(2) ); }
		100% 	{ .transform( scale(1) ); }
	}
	
	@-webkit-keyframes animPin2	 	{ .frames; }
	@-moz-keyframes animPin2 		{ .frames; }
	@-ms-keyframes animPin2	 		{ .frames; }
	
	.anim-props( @anim-time, animPin2, 1 );
	
}
.anim-highlight-pin3									{

	@anim-time : 1000ms;

	.frames()						{
		0%	 	{ .transform( scale(1) ); opacity: 0; }
		20% 	{ .transform( scale(2) ); opacity: 1; }
		40% 	{ .transform( scale(1) ); }
		55% 	{ .transform( scale(1.5) ); opacity: 1; }
		70% 	{ .transform( scale(1) ); }
		100% 	{ .transform( scale(0) ); opacity: 0; }
	}
	
	@-webkit-keyframes animPin3	 	{ .frames; }
	@-moz-keyframes animPin3 		{ .frames; }
	@-ms-keyframes animPin3	 		{ .frames; }
	
	.anim-props( @anim-time, animPin3, 1 );
	
}
.anim-highlight-pin4										{

	@anim-time : 2000ms;

	.frames()						{
		0%	 	{ .transform( scale(0) rotate(45deg) ); }
		50% 	{ .transform( scale(1.5) rotate(45deg) ); }
		100% 	{ .transform( scale(1.25) rotate(45deg) ); }
	}
	
	@-webkit-keyframes animPin4	 	{ .frames; }
	@-moz-keyframes animPin4 		{ .frames; }
	@-ms-keyframes animPin4	 		{ .frames; }
	
	.anim-props( @anim-time, animPin4, 1 );
	
}
.fake-page-load						{

	@anim-time : 3000ms;

	.frames()						{
		0%	 	{ width: 0%; left: 0; right: auto; }
		50% 	{ width: 100%; left: 0; right: auto; }
		51% 	{ width: 100%; left: auto; right: 0; }
		100% 	{ width: 0%; left: auto; right: 0; }
	}
	
	@-webkit-keyframes fakePageLoad 	{ .frames; }
	@-moz-keyframes fakePageLoad 		{ .frames; }
	@-ms-keyframes fakePageLoad 		{ .frames; }
	
	.anim-props( @anim-time, fakePageLoad );
	
}

/* Side-of-UI 'tab' ------------------------------------------------------------------------------------------------------*/
.tab-switch( @w: 40px, @h: 60px)	{
	.dim(@w,@h); .block; .abs(@t:50%, @l:-@w); margin-top: -@h/2; background: rgba(0, 0, 0, 0.2); .roundSome(2,0,2,0); .cen; .transition( @comments-anim-t/2 );
	.origin(100%,50%);
	span 							{ .abs(@t:0px, @r:@w); line-height: @h; padding-right: @gap; font-size: 40px; color: @c-txt; .transition( @comments-anim-t/2 ); .delay( @comments-anim-t ); pointer-events: none; }
	&:before 						{ content:'N'; .arr; line-height: @h; font-size: 30px; color: #fff; }
	&:hover,
	&:active 						{ background: rgba(0, 0, 0, 0.2); }

}

.tab-switch-activity( @w: 40px, @h: 60px)	{
  .dim(@w,@h); .block; .abs(@t:50%, @l:-@w); margin-top: (-(@h/2)-100); background: rgba(0, 0, 0, 0.2); .roundSome(2,0,2,0); .cen; .transition( @comments-anim-t/2 );
  .origin(100%,50%);
  span 							{ .abs(@t:0px, @r:@w); line-height: @h; padding-right: @gap; font-size: 40px; color: @c-txt; .transition( @comments-anim-t/2 ); .delay( @comments-anim-t ); pointer-events: none; }
  &:before 						{ .arr; line-height: @h; font-size: 30px; color: #fff; }
  &:hover,
  &:active 						{ background: rgba(0, 0, 0, 0.2); }

}

.proof-comments-active              {
	.proof-comments-switch			{
	  &:before 					{ content:'n'; }
	  span 						{ .transform( translateX(-60px) ); .delay( 0 ); opacity: 0;
	  }
	}
}

/* Tabbed Interface ------------------------------------------------------------------------------------------------------*/

.tabbed									{
		
	nav									{
		overflow: hidden;
		a								{
			.block; .no-dec; float: left; .outline; padding: @gap/2 @gap; .roundSome(@r, @r, 0, 0);
			&.selected					{ .outline2; }
		}
		&.fill-w						{
			display: table; width: 100%; table-layout: fixed;
			.row						{
				display: table-row;
				a						{ float: none; display: table-cell; .cen; }
			}
		}
	}
	.pane								{
		.outline2; padding: @gap; .roundSome(0, @r, @r, @r); display: none;
		&.selected						{ .block; }
	}
	
}
