/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.UploadPlaceholder {
    position: relative;
    padding-left: 220px;
    margin-bottom: 20px;
    min-height: 150px;

    &__preview {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 150px;
        padding: 10px;
        background-color: #ddd;
        justify-content: center;
        align-items: center;
        border-radius: 4px;

        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }

        svg {
            height: 50px;
            width: 50px;

            path {
                fill: rgba(0, 0, 0, .25);
            }
        }
    }

    &__button {
        margin-bottom: 20px;
    }

    &__description,
    &__message {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}