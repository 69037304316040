/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;

    z-index: 998;
    // background: rgba(255, 255, 255, 0.9);
    background-color: #fff;
    transition: opacity .2s @easing;

    &.ng-enter,
    &.animate-enter {
        opacity: 0;
    }

    &,
    &.ng-enter-active,
    &.ng-leave,
    &.animate-enter-active,
    &.animate-leave {
        opacity: 1;
        background-color: rgba(0,0,0,.35);
    }

    &.ng-leave-active,
    &.animate-leave-active {
        opacity: 0;
    }

    &__avatars {
        margin-block: 8px 12px;
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        min-height: 100vh;
    }

    &__container {
        display: block;
        max-width: 900px;
        text-align: center;
        background: @c-white;
        padding: 45px 0;
        border-radius: 7px;
        box-shadow: 0 3px 32px 0 rgba(0,0,0,.14);
        position: relative;
        align-self: center;
        margin: 32px;
        z-index: 1200;

        @media (max-width: @break-m) {
            width: 100%;
        }
    }

    &__content {
        display: block;
        max-width: 800px;
        font-size: 14px;
        margin: 0 125px;
        z-index: 1100;

        &__lets-proof {
            .app__modal__container {
                max-width: 1050px;
                width: 100%;
            }

            &__container {
                max-width: 800px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                position: relative;
                margin-top: 40px;

                &__video {
                    position: relative;
                    // This padding-bottom is necessary to maintain the 16*9 aspect ratio
                    padding-bottom: 56.25%;
                    height: 100%;
                    width: 100%;
                    margin-top: 40px;

                    iframe {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        max-width: 800px;
                        max-height: 450px;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }

        &__keyboard-shortcuts {
            .app__modal__container {
                max-width: 950px;
                width: 100%;

                .app__modal__content {
                    margin: 0 80px;
                }
            }
        }

        @media (max-width: @break-m) {
            margin: 0 10px;

            h1 {
                font-size: 30px;
                margin-top: 50px;
            }
            .desktop-text {
                display: none;
            }
        }

        @media (min-width: @break-m) {
            .mobile-text {
                display: none;
            }
        }
    }

    &__options {
        margin-top: 40px !important;

        @media (max-width: @break-m) {
            .app__btn {
                display: block;
                margin: 0 auto 10px auto !important;
            }
        }
    }

    &__info {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 20px;

        &__green {
            color: @c-green;
        }

        &__orange {
            color: @c-warning;
        }

        &__grey {
            color: @c-grey-xd;
        }
    }


    &__btn-sub {
        color: @c-green;
        font-size: 10px;
        font-weight: normal;
        text-transform: uppercase;
        border: none;
        outline: none;
        background: none;
        transition: transform .1s ease-in;

        &:hover, &:focus {
            color: @c-green-d;
        }
        &:active {
            transform: scale(0.9);
        }
    }

    &__proof-combine {
        padding: 20px 0;

        &__thumb {
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            height: 190px;

            img {
                position: relative;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                display: block;
                margin: 0 auto;
                max-width: 100%;
                max-height: 190px;
            }
        }

        &__arrow {
            display: inline-block;
            color: @c-green;
            width: 80px;
            height: 80px;
            font-size: 50px;
            font-family: 'ArrrowsRegular';
            font-weight: normal;
            transform: rotate(180deg);
        }
    }

    &__inner {
        position: relative;
        margin: 30px auto;
        display: flex;
        min-height: 25px;
        justify-content: center;
        align-items: center;

        &__message {
            font-size: 12px;
            margin-left: 10px;
            line-height: 18px;
        }
    }

    &__lock {
        position: relative;
        top: 2px;
        display: inline-block;
        width: 10px;
        height: 15px;
        opacity: 1;
        margin-left: 3px;
        background-image: url("../../img/interface/padlock-grey.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &__close-button {
        display: block;
        position: absolute;
        top: 18px;
        right: 18px;
        cursor: pointer;

        svg {
            width: 28px;
            height: 28px;
            fill: @c-txt-k;
        }

        @media (max-width: @break-m) {
            width: auto;
            right: 20px;
            top: 22px;
        }
    }

    &__cover {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1000; // The cover will be between the container and the content allowing it to be clicked
    }
}
