/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
 
 &__comments {
    .app__flyout & {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
        overflow-y: auto;
        //transform: translateZ(0);
    }

    @media (max-width: @break-m) {
         margin-top: 50px;
    }

    &-flyout--with-heading & {
        top: 130px;
        padding-top: 0;
    }

    &-flyout--without-heading & {
        top: 0;
    }
}
