/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.LogoBrandingPreview {
    position: relative;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .1);

    &,
    &__background {
        display: block;
        width: 600px;

        @media (max-width: @break-m) {
            width: 100%;
        } 
    }

    &__image {
        position: absolute;
        top: 13px;
        left: 14px;
        height: 40px;

        @media (max-width: @break-6) {
            height: 21px;
            top: 2%;
        }
    }
}