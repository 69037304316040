/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Integration {
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;

    .app__on-off-toggle {
        display: inline-block;
        vertical-align: middle;

        @media (max-width: @break-4) {
            display: block;
            float: right;
            padding-top: 0;

            &__options {
                margin-right: 0;
            }
        }
    }

    label {
        padding-right: 10px;
    }

    &__logo {
        display: inline-block;
        vertical-align: middle;
        margin: 0 20px;
        
        &--disabled {
            filter: grayscale(1);
        }
    }
    
    .msg-note {
        color: @c-green;

        &__note-with-button {
            @media (max-width: @break-4) {
               position: absolute;
               left: 0;
               top: 175px;
               padding: 0;
            }
        }
    }

    &__input {
        float: right;

        .Input {
            width: 210px;
            font-size: 16px;
        }


        @media (max-width: @break-4) {
            margin-top: 20px;
            margin-bottom: 20px;
            width: 100%;

            .Input {
                width: 100%;
            }

            .msg-note {
                position: static;
                padding-left: 0;
            }
        }
    }

    &__button-wrap {
        width: 100%;

        button {
            bottom: 50px;
        }

        @media (max-width: @break-4) {
            margin: 100px 0 0 0;
        } 
    }

    &__button-holder {
        @media (max-width: @break-4) {
            width: 100px;
            margin: 0 auto;    
        } 
    }

    .app__btn {
        float: right;
        margin-right: 50px;
        margin-top: 4px;

        @media (max-width: @break-4) {
            position: absolute;
            left: 0;
            bottom: 25px;
        }    
    }
}
