/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.DomainAdminInviteTeam {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    h1 {
        margin-bottom: 40px;

        @media (max-width: @break-m) {
            font-size: 30px;
        }
    }

    .Input {
        width: 100%;

        @media (max-width: @break-m) {
            font-size: 18px;
        }
    }

    &__sent {
        &__description {
            margin-bottom: 60px;
        }
    }
}
