/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.WebProof {
    position: absolute;
    top: 1px;
    left: 100px;
    right: 100px;
    bottom: 2px;

    @media (max-width: @break-m) {
        left: 0;
        right: 0;
    }

    &__frame {
        background-color: #fff;
    }

    html.takingSnapshot & {
        z-index: 10000000001;
    }

    &__dropdownOverlay {
        display: none;
        inset: 0;
        z-index: 1;
        position: absolute;
      
        html.dropdown-is-open & {
            display: block;
        }
    }
}

.WebProofOptions {
    .Dropdown,
    &__refresh,
    &__fullscreen,
    &__home {
        margin-left: 3px;
        margin-right: 3px;
    }

    &__fullscreen,
    &__refresh,
    &__home {
        border: 0;
        background-color: transparent;
        padding: 5px 6px;
        display: inline-block;
        cursor: pointer;
    }

    .Dropdown + &__fullscreen {
        margin-left: 10px;
    }

    &__fullscreen {
        svg {
            width: 29px;
            height: 29px;
            fill: @c-grey-icon;
        }

        &:hover svg {
            fill: darken(@c-grey-icon, 20%);
        }
    }

    &__home {
        svg {
            width: 26px;
            height: 26px;
            fill: @c-grey-icon;
        }

        &:hover svg {
            fill: darken(@c-grey-icon, 20%);
        }
    }

    &__refresh {
        position: relative;

        path {
            fill: @c-grey-icon;
        }

        &:hover path {
            fill: darken(@c-grey-icon, 20%);
        }

        &--loading .animate-spin path {
            fill: @c-green;
        }

        &:focus {
            outline: none;
        }

        &__start,
        &__stop {
            display: block;

            svg {
                width: 26px;
                height: 26px;
            }
        }

        &__stop svg {
            padding: 4px;
        }

        &--loading:hover &__start,
        &__stop {
            display: none;
        }

        &--loading:hover &__stop {
            display: block;
        }
    }
}
