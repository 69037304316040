/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
@media (max-width: @break-m) {
    .intercom-launcher-frame,
    .intercom-launcher-discovery {
        transform: scale(0.66) !important;
    }
}
