/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.TagInput[type="text"] {
    display: inline-block;
    background-color: transparent;
    border: 0;
    padding: 0;
    line-height: 26px;
    font-size: 18px;
    min-width: 100px;
    max-width: 100%;

    // Overriding [type=text] in forms.less
    height: 26px;
    transition: none !important;

    &:focus {
        outline: none;
    }
}
