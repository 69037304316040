/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__file-preview {
    &__button {
        position: absolute;
        top: 50%;
        margin-top: -50px;
        width: 100px;
        height: 100px;
        font-size: 50px;
        font-family: 'ArrrowsRegular';
        font-weight: normal;

        &--left {
            left: 0;
        }

        &--right {
            right: 0;

            &--rotate {
                display: inline-block;
                transform: rotate(180deg);
            }
        }
    }

    &__inner {
        position: relative;
        display: block;
        max-width: 800px;
        margin: 0 auto;
        padding: 0 100px;
        user-select: none;

        @media (max-width: @break-m) {
            padding: 0 5%;
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            max-height: 600px;
            @media (max-width: @break-0) {
                max-height: 400px;
            }
        }
    }

    &__download-progress {
        position: absolute;
        top: -1px;
        left: 0;
        bottom: -1px;
        background-color: @c-green-d;
        overflow: hidden;
        transition: width .25s @easing;

        &.ng-leave {
            transition: none;
        }

        > span {
            display: block;
            width: 158px;
            line-height: 40px;
            color: white;
        }
    }

    &__download-progress-text {
        opacity: .99;
    }

    .app__btn-group {
        margin-top: 30px;

        @media (max-width: @break-m) {
            .app__btn {
                display: block;
                width: 100%;
                margin: 0 auto 10px auto !important;
                padding: 15px 0;
            }
        }
    }
}