/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.VideoScrubberContainer {
    max-height: 40px;
    display: flex;
    flex-grow: 0;

    @media (max-width: @break-m) {
        max-height: 30px;
    }

    &--loading {
        visibility: hidden;
    }
}
