/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.FormControl {
    position: relative; // needed for the message to anchor to
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;

    > label {
        position: relative; // needed for the label to achor to
        display: block;
    }

    &__label,
    &__message {
        display: block;
        text-transform: uppercase;
    }

    &__label {
        color: #999;
        font-size: 12px;
        font-weight: bold;
        text-align: left;
    }

    &--disableAutoCompact:not(&--compact) &__label {
      position: absolute;
      right: ~"calc(100% + 15px)";
      text-align: right;
      width: ~"var(--FormControl-labelWidth, 200px)";
      transform: ~'translateY(calc(16px - ((100% - 16px) / 2)))';
      max-height: 48px;
    }
    
    &:not(&--compact) &__label {
        @media (min-width: @break-m) {
            position: absolute;
            right: ~"calc(100% + 15px)";
            text-align: right;
            width: ~"var(--FormControl-labelWidth, 200px)";
            transform: ~'translateY(calc(16px - ((100% - 16px) / 2)))';
            max-height: 48px;
        }
    }

    &__message {
        color: @c-txt-k;
        font-size: 10px;
        margin-top: 10px;
    }

    &--disableAutoCompact:not(&--compact) &__message {
      position: absolute;
      left: ~"calc(100% + 15px)";
      margin-top: 0;
      width: ~"var(--FormControl-labelWidth, 200px)";
      transform: ~'translateY(calc(16px - ((100% - 16px) / 2)))';
      max-height: 48px;
    }

    &:not(&--compact) &__message {
        @media (min-width: @break-m) {
            position: absolute;
            left: ~"calc(100% + 15px)";
            margin-top: 0;
            width: ~"var(--FormControl-labelWidth, 200px)";
            transform: ~'translateY(calc(16px - ((100% - 16px) / 2)))';
            max-height: 48px;
        }
    }

    &__field {
        position: relative;
    }
}
