/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
html.is-dark-mode {
    body {
        color: #999;
    }
    header,
    .proof-footer {
        background-color: rgba(31, 31, 31, 0.9);
        border-bottom: 0;
    }

    header h1 {
        color: #cccccc;
    }

    .page__proof__toolbar {
        background-color: rgba(31, 31, 31, 0.9);
    }

    .page__proof__footer {
        border-top-color: rgba(31, 31, 31, 0.9);
    }

    body,
    .slide,
    .slideBack {
        background-color: #2d2d2d;
    }

    .NavigationPanel--grey {
        background-color: #1f1f1f;
        border-right: 0;
    }

    .VerticalTab--selected {
        background-color: #141414;
    }

    .DomainAdminProfile__access__subheading {
        color: #999;
    }

    .flyout {
        background-color: #1f1f1f;
    }

    .flyout-cover {
        background-color: #2d2d2d;
    }

    #page-load,
    .loading,
    .user-details {
        background-color: rgba(45, 45, 45, 0.9);
    }

    .user-details .details .user-text {
        color: #ccc;
    }

    .app__modal__container {
        background: #000000;
        color: #999;
        border: 1px solid @c-black-ll;
        
        h1, h2, h3, h4, h5, h6 {
            color: #999;
        }
    }


    .app__comments-page__heading--current-page {
        background-color: #2d2d2d !important;
        box-shadow: none;
    }

    .app__comments-page__heading__text {
        color: #878787;
    }

    .app__comments-page__heading__text.active,
    .app__comments-page__heading__text strong {
        color: #878787;
    }

    .app__comments-page__heading--opaque {
        background-color: #2d2d2d;
    }
    @media (max-width: @break-m) {
        .app__comments-page__header h3 {
            color: #333;
        }
    }

    .tag,
    a.tag {
        background: #737373;
        color: #fff;

        &:hover {
            background-color: #464646;
        }
    }

    .app__tool__options {
        background-color: #1f1f1f;
    }

    .NavigationPanel__separator {
        border-bottom-color: #333333;
    }

    .tabs__nav__item:not(.tabs__nav__item--selected):hover {
        color: #fff;
    }

    .app__proof-action__form,
    .app__proof-action__heading--mobile,
    .app__ncomment--selected {
        background-color: transparent;
    }

    #team-menu,
    #main-menu,
    .app__proof-manage,
    .app__proof-manage__confirm,
    .flyout__collection-manage {
        background-color: #033e16;

        &-box {
            background-color: #1f1f1f;
        }
    }
    .app__proof-manage__message-box {
        background-color: #000000;

        textarea {
            background-color: @c-black-ll;
            border: none;
        }
    }

    .Integration {
        &__input {
            .Input {
                color: #f8f8f8;
            }
        }
    }

    .Integrations,
    .Reporting {
        &__description {
            color: @c-txt-m;
        }
        
        &__subheading {
            color: @c-txt-m;
        }
    }

    .dashboard__proof,
    .dashboard__lpu {
        background-color: #1f1f1f;
        color: #f8f8f8;
    }
    .dashboard__lpu--reupload.dashboard__lpu--show-options .dashboard__lpu__options {
        background-color: transparent;
    }

    .dashboard__proof--tile .dashboard__proof__meta:before {
        background: linear-gradient(to top, #1f1f1f, transparent);
    }

    .app__proof-thumbnail__image {
        background-color: #1f1f1f;
        border: 1px solid #3c3c3c;
    }

    .app__proof-pages {
        background-color: #2d2d2d;
    }

    .app__proof-page__number {
        color: #f8f8f8;
    }

    .app__tool__icon {
        background-color: #1f1f1f;
    }

    .app__tool:hover .app__tool__icon {
        background-color: #000;
    }

    .db-add-proof:before {
        background-image: url(/img/interface/@2x/bg-add-proof.png);
        background-size: 58%;
    }

    @media (max-width: 1600px) {
        .db-add-proof:before {
            background-size: 46%;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        color: #d8d8d8;
    }

    .server-msg {
        background: @c-black-l;
    }

    .server-msg:not(.server-msg__no-pointer):after {
        color: @c-black-l;
    }

    ::-webkit-input-placeholder {
        color: @c-grey-input-dark;
    }

    .notifications {
        background-color: #252525;
        border-left-color: #1b1b1b;
    }

    .notifications__header {
        background-color: #1b1b1b;
    }

    .notification {
        background-color: #1d1d1d;

        a,
        span {
            color: #3ac167;
        }
    }

    .notifications__title {
        color: #f8f8f8;
    }

    .notification-icon {
        display: none;
    }

    .dashboard__lpu__hint__muted {
        color: #ccc;
    }

    .app__proof-thumbnail__overlay {
        background-color: transparent !important;
    }

    .app__proof-thumbnail__image img {
        opacity: 1 !important;
    }

    .app__ncomment__content {
        textarea {
            border: 1px solid #3c3c3c;
            background: #2d2d2d;
            color: #ccc;
        }
        textarea::placeholder {
            color: @c-grey-l;
        }
    }

    .VerticalTab--grey:hover:not(.VerticalTab--selected) {
        color: @c-white;
    }

    .NavigationPanel--grey {
        .NavigationPanel__heading {
            color: #f8f8f8;
        }

        .NavigationPanel {
            &__tabButton {
                color: @c-txt-m;  
            }

            &__tabButton:hover {
                color: @c-white;
            }
        }
    }

    select:not(.unstyled),
    textarea,
    input[type="text"],
    input[type="email"],
    input[type="date"],
    input[type="password"] {
        color: #f8f8f8;
        border-bottom-color: #464646;
    }

    .Input[readonly] {
        color: @c-grey-input-dark;
    }

    .Tags {
        border-bottom-color: #464646;
    }

    .Tags--readonly {
        border-bottom-color: #2d2d2d;
    }

    .workflow-structure {
        .wf-step {
            background: #1b1b1b;
            &.new-wf-step {
                background: #2d2d2d;
                p {
                    color: #f8f8f8;
                }
            }

            .proofer-role__share__button .cls-1 {
                fill: #1b1b1b;
            }
        }

        .proofer-email {
            color: #f8f8f8;
        }
    }
    
    .flyout--proof-info {
        background-color: #1b1b1b;

        .proof-info__title {
            color: #ccc;
        }

        .proof-info__meta {
            background-color: #1f1f1f;
        }

        .proof-info__users {
            background-color: #1b1b1b;
        }

       .message-box fieldset {
            background: #1f1f1f !important;
        }

        .proof-info__meta,
        .proof-info__users {
            border-left-color: #1b1b1b;
        }

        .wf-step {
            background-color: #1b1b1b !important;
        }

        .proofer-role__share .cls-1 {
            fill: #1b1b1b;
        }

        .proof-info__heading {
            color: #f8f8f8;
        }

        .proofer-email {
            color: #f8f8f8;
        }

        .info,
        .proof-info__extension {
            color: #ccc;
        }

        .additional-owners {
            background-color: #6b6b6b !important;
            color: #fff !important;
        }

        .message-box {
            textarea {
                border: 1px solid #3c3c3c !important;
                background: #2d2d2d !important;
                color: #ccc;
            }
        }

        .message-read-only {
            border: 1px solid #3c3c3c !important;
            background: #2d2d2d !important;
        }
    }

    .Dropdown {
        &__label .DropdownOption {
            background-color: @c-black-ll;
            border: 1px solid @c-black-l;
            color: @c-txt-l;

            &:hover {
                background-color: @c-black-ll;
            }
        }

        &.Dropdown--open .Dropdown__label .DropdownOption {
            border-radius: 0px;
            color: @c-txt-l;
        }

        &--up {
            &.Dropdown--open .Dropdown__label .DropdownOption {
                border-top: none;
            }
        }

        &--down {
            &.Dropdown--open .Dropdown__label .DropdownOption {
                border-bottom: none;
            }
        }

        &--up .Dropdown__options .DropdownOption--selected {
            background-color: @c-black-ll;
        }

        &--down .Dropdown__options .DropdownOption--selected {
            background-color: @c-black-ll;
        }

        &--default {
          .DropdownOption {
            &:hover,
            &.hover,
            &:active {
                background-color: @c-grey-xxdd;
            }
    
            &__icon {
              svg {
                fill: @c-txt-k;
              }
            }
          }
        }
    }

    .DropdownOption {
        background-color: @c-black-ll;
        // border: 1px solid @c-black-l;
        color: @c-txt-l;
    }

    .DomainAdminInviteTeam {
        .Input {
            color: @c-grey-input-dark;
        }
    }
}
