/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/*

	.LESS include - Buttons

*/

.btn,
.btn-sub								{ min-width: 160px; }

.btn-shadow () {
	.inDrop( @strength:0.05, @blur:0, @col:0, @x:0, @y:-2, @spread:0 );
}

.btn {
	border: none;
}

.btn-editor-margin-200 {
  	margin-right: 200px;
}

.btn-editor-margin-260 {
  margin-right: 260px;
}

.btn,
.btn:active,
.btn:visited							{
	box-sizing: border-box;

	.no-dec; background: none; color: @c-grey-xd; font-size: 12px; .upper; .bold; outline: none; cursor: pointer;
	.in-block; background: @c-grey; padding: @gap/2 @gap; .round(@r); text-align: center;

	-webkit-appearance: none;

	&.disabled, &[disabled], &:disabled { opacity: 0.5; pointer-events: none; }
	&.btn-l								{ font-size: 14px; padding: @gap/2 @gap*1.5 @gap/2 + 1; }
	&.btn-s								{ font-size: 11px; padding: @gap*.4 @gap/2; }
	&.btn-xs							{ font-size: 10px; padding: @gap*.2 @gap/2; }

	&:hover,
	&:focus								{ background: @c-grey-xd; color: #fff; }
	&:active							{ background: @c-grey-xd; color: #fff; .inDrop( @strength:0.05, @blur:0, @col:0, @x:0, @y:2, @spread:0 ); }

	&.btn-g								{
		background: @c-green; color: #fff; .btn-shadow();
		&:hover,
		&:active,
		&:focus							{ background: @c-green-d; }
	}
	&.btn-w								{
		background: transparent; color: white; .btn-shadow(); border: 1px solid white;
		&:hover,
		&:active,
		&:focus							{ background: #fff; color: @c-green; }
	}
	&.btn-dg							{
		background: @c-green-d; color: #fff; .btn-shadow();
		&:hover,
		&:active,
		&:focus							{ background: darken(@c-green-d,10%); }
	}
	&.btn-gr							{
		background: #fff; color: @c-green; .btn-shadow();
		&:hover,
		&:active,
		&:focus							{ background: @c-green-d; color: #fff; }
	}
	&.btn-dw							{
		background: @c-warning-d; color: #fff; .btn-shadow();
		&:hover,
		&:active,
		&:focus							{ background: darken( @c-warning-d, 5% ); }
	}
	&.btn-d								{
		background: @c-danger; color: #fff; .btn-shadow();
		&:hover,
		&:active,
		&:focus							{ background: @c-danger-d; }
	}

}
.btn + .btn,
.btn + .btn-sub,
.btn-sub + .btn							{ margin-left: @gap; }

.close									{
	@s : 40px;
	.block; .dim(@s,@s); line-height: 18px; padding-top: 6px; border: 2px solid #fff; .cen; .transition( 200ms ); opacity: 0.5;
	&:before							{ .xApp; font-size: 38px; color: #fff; .transition(200ms); }
	&:hover,
	&:active							{ opacity: 1; }
	@media (max-width: @break-m) {
		margin-right: -25px;
		margin-top: -20px;
		transform: scale(.9, .9)!important;
	}
}

.close-proof-info						{
	@s : 40px;
	.block; .dim(@s,@s); line-height: 18px; padding-top: 6px; border: 2px solid @c-grey-xd; .cen; .transition( 200ms ); opacity: 0.5;
	&:before							{ .xApp; font-size: 38px; color: @c-grey-xd; .transition(200ms); }
	&:hover,
	&:active							{ opacity: 1; }
}

.close-info-pane						{
	&,
	&:before {
		color: #9c9c9c;
	}

	border-color: #9c9c9c;

    &:hover,
	&:active {
		&,
		&:before {
			color: #ffffff;
		}

		border-color: #ffffff;
	}
}

.btn-sub								{
	.in-block; font-size: 12px; .upper; .bold; outline: none; padding: @gap/2 @gap; .cen; background: none; border: none; color: @c-link;
	&:hover,
	&:active 							{ color: @c-link-h; }
}

.btn-add								{

	@s : 60px;
	.circ(@s); .in-block; border: 1px solid @c-border; line-height: 0; font-size: 40px; background: none; padding: 0 0 @gap/4 + 2 0; color: @c-txt-m; outline: none;
	&:hover,
	&:active							{ color: @c-green; border-color: @c-green; }
}

.btn-add-new-step								{
  &:before {
	content: '+';
  }

  @s : 60px;
  .circ(@s); .in-block; border: 1px solid @c-border; line-height: 0; font-size: 40px; background: none; padding: 0 0 @gap/4 + 2 0; color: @c-txt-m; outline: none;
  &:hover,
  &:active							{ color: @c-green; border-color: @c-green; }
}

.btn-add-admin								{
  @s : 60px;
  margin-bottom: 30px;
  .circ(@s); .in-block; border: 1px solid #878787; line-height: 0; font-size: 40px; background: none; padding: 0 0 @gap/4 + 2 0; color: #878787; outline: none;
  &:hover,
  &:active							{ color: @c-green; border-color: @c-green; }
}

.btn-marketing {
	&,
	&:hover,
	&:focus {
		padding: 20px 40px;
		width: 250px;
		max-width: 100%;
	}
}

.btn-grey {
	border: 1px solid #C2C2C5;
	background-color: transparent;
	color: #808080;

	&:hover,
	&:focus {
		color: white;
		background: #999;
		border-color: #999;
	}
}

.btn-dropbox {
	@dropbox-color: #007ee5;
	color: #fff;

	&,
	&:hover,
	&:active,
	&:active:active,
	&:focus {
		padding-left: 50px;
		background-image: url('../../img/interface/dropbox-btn-logo.png') !important;
		background-color: @dropbox-color;
		background-repeat: no-repeat;
		background-position: 10px center;
		background-size: auto 100%;
	}

	&:hover,
	&:active,
	&:active:active,
	&:focus {
		background-color: darken(@dropbox-color, 10%);
	}
}

.app__proof-info__coowner__add-btn {
	min-width: auto !important;
	position: absolute !important;
	top: 5px;
	left: 100%;
	box-shadow: none !important;
}

.app__close-btn {
	position: absolute;
	top: 36px;
	right: 35px;
	font-size: 0;
	opacity: 1;

	&:hover {
		opacity: .6;
	}

	&-white {
		svg {
			fill: #fff;
		}
	}

	&-black {
		svg {
			fill: @c-txt-k;
		}
	}

	&__menu-close-btn {

		@media (max-width: @break-m) {
			top: 22px;
			right: 20px;
		}

		svg {
			width: 28px;
			height: 28px;

			@media (max-width: @break-m) {
				width: 25px;
				height: 25px;
			}
		}
	}
}
