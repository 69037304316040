/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.app__mention {
    //background-color: #fffde9;
    //border-radius: 2px;

    .px-mention {
        position: relative;

        &__textarea {
          position: relative;
          //background: #fff;
          //border: 1px solid #dcdcde;
          //font-size: 14px;
          overflow: hidden;
        }

        &__highlight {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          /*height: 100%;*/
          color: rgba(0, 0, 0, 0);
          white-space: pre-wrap;
        }

        &__highlight span {
          position: relative;
          background-color: #cfe8d7;
        }

        &__element {

          > textarea {
            position: relative;
            //border: 0;
            //background-color: rgba(0, 0, 0, 0);
            width: 100% !important;
            outline: none;
            /*resize: none;*/
          }
        }

        &__highlight,
        &__element textarea {
          display: block;
          //padding: 20px;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.4;
          overflow-y: scroll;
          overflow-x: hidden;
        }

        &__options {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          margin-top: -1px; /* Fixes double border */
          max-height: 300px;
          overflow-y: auto;
          border: 1px solid #dcdcde;
          background-color: #eff1f2;
          // To sit on top of collapsed replies (z-index: 100) and 'show all X replies' button (z-index: 101)
          z-index: 102;
        }

        &__options {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
            }
        }

        &__options {
            li {
                display: block;
                padding: 12.5px 15px;
                color: #999;
                font-size: 14px;
                cursor: pointer;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__options li.active {
          background-color: #fff;
        }

        &__options li:not(:first-of-type) {
          border-top: 1px solid #dcdcde;
        }
    }
}