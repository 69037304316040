/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Integrations {
    width: 700px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;

    h1 {
        text-align: center;
    }

    @media (max-width: @break-m) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (max-width: @break-6) {
        padding-top: 60px;
        padding: 22px 15px;

        h1 {
          font-size: 24px;
        }
    }

    &__description {
        font-size: 16px;
        text-align: center;
        color: @c-txt-k;

        @media (max-width: @break-m) {
            line-height: 1.4;
        }    
    }


    &__introduction {
        font-size: 16px;
        color: @c-txt-k;   
    }

    &__zapier-container {
        h3 {
            margin: 30px 0;
        }
        
        #zapier-folder {
            padding-top: 20px;

            .zap-widget {
                font-family: 'Circular Pro',sans-serif;
                max-width: 750px;
                width: 100%;

                .zap-button {
                    font-size: 10px;
                    text-transform: uppercase;
                }
            }
        }
    }

    &__line {
        margin-top: 60px;
        margin-bottom: 40px;
        border-top: solid 1px @c-border;
    }

    &__subheading {
        font-size: 20px;
        color: #555;
    }
}
