/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.VerticalTab {
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    min-height: 45px;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 15px 20px 15px 15px;
    float: right;
    text-align: left;

    &--initialCasing {
        text-transform: initial;
        font-weight: 500;
        font-size: 14px;
    }

    &--grey {
        color: @c-txt-m;

        .VerticalTab__close {
            color: @c-txt-m;
        }

        &:hover:not(.VerticalTab--selected) {
            color: @c-txt;

            .VerticalTab__tip {
                color: @c-txt-m;
            }
        }

        &.VerticalTab--selected {
            background-color: @c-grey-d;
            color: @c-green;

            .VerticalTab__tip {
                color: @c-green;
            }
        }

        &__label {
            max-width: 90%;
        }

        .VerticalTab__arrow svg {
            fill: @c-green;
        }

        &.VerticalTab--highlighted:not(.VerticalTab--selected) .VerticalTab__proof-count {
            color: @c-danger;
        }

        &__link {
            .VerticalTab__link {
                color: @c-txt-m;

                &:hover, &:active {
                    color: @c-txt;
                }
            }

            &:hover {
                background-color: @c-grey-d;
            }
        }
    }

    &--green {
        color: @c-white;

        .VerticalTab__close {
            color: @c-white;
        }

        &.VerticalTab--selected {
            background-color: @c-green-d;
        }

        .VerticalTab__arrow svg {
            fill: @c-white;
        }

        &.VerticalTab--highlighted:not(.VerticalTab--selected) .VerticalTab__proof-count {
            color: @c-white;
        }
    }

    &__close {
        position: absolute;
        right: 6px;
        top: 15px;
        font-size: 15px;
        width: 14px;
        transform: translateX(0);
        transition: transform 0.25s @easing;
    }

    &__options {
        position: absolute;
        right: 3px;
        display: inline-flex;
        align-items: center;
        align-self: center;
        width: auto;
        transform: translateX(100px);
        transition: transform 0.25s @easing;

        &__gear,
        &__trash {
            padding: 0 5px;
            line-height: 0;
        }

        .VerticalTab__arrow {
            position: relative;
            transform: none;
        }
    }

    &--label {
        margin-bottom: 40px;
    }

    &__label {
        padding-right: 15px;
        display: inline-flex;
        align-self: center;
    }

    &:hover {
        .VerticalTab__tip {
            opacity: 1;
            transform: translateX(0);
        }

        .VerticalTab__options {
            transform: translateX(0);
        }
    }

    &--selected {
        border-bottom: none;
        padding-right: 10px;

        .VerticalTab__close {
            transform: translateX(-20px);
        }

        .VerticalTab__label {
            padding-right: 50px;
        }

        .VerticalTab__options {
            transform: translateX(0);
            right: 0;
        }
    }

    &--canDelete {
        .VerticalTab__label {
            padding-right: 70px;
        }
    }

    &__proof-count {
        font-size: 10px;
        margin-left: 5px;
        font-weight: 400;
        display: inline-flex;
        align-items: center;
    }

    &__arrow {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        svg {
            transform: rotate(270deg);
            height: 30px;
        }
    }

    &__tip {
        display: inline-block;
        text-transform: none;
        font-size: 12px;
        font-weight: normal;
        margin-left: 15px;
        opacity: 0;
        pointer-events: none;
        transform: translateX(130px);
        transition: opacity 0.2s @easing,
                    transform 0.2s @easing;

        @media (max-width: @break-m) {
            display: none;
        }
    }

    &:not(.VerticalTab--selected) .VerticalTab__arrow svg {
        display: none;
    }
}
