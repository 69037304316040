/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__orb-select {
    &__orb {
        width: 20px;
        height: 20px;
        background-color: @c-txt-k;
        border-radius: 100%;
        transition: background-color .25s @easing;
                    //transform .1s @easing;

        //.app__context:not(.app__context--active) & {
        //    transform: scale(.5);
        //}

        .app__orb-select--red & {
            background-color: @c-danger;
        }

        .app__orb-select--orange & {
            background-color: @c-warning;
        }

        .app__orb-select--green & {
            background-color: @c-green;
        }

        .app__orb-select--blue & {
            background-color: @c-info;
        }
    }

    &__options {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
            font-size: 10px;
            text-transform: uppercase;
            line-height: 20px;
            color: @c-txt-k;
            cursor: pointer;

            &:hover {
                color: #444;
            }
        }
    }
}