/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__proof-thumbnail {
    position: relative;
    margin: 0 auto 20px;
    display: inline-block;
    width: 160px;
    height: 152px;
    overflow: hidden;

    &--tile {
        width: 200px;
        height: 190px;
        margin-bottom: 0;

        .app__proof-thumbnail__image {
            background-color: @c-body;
            img {
                max-width: 200px;
                max-height: 190px;
            }
        }
    }

    &--list {
        width: 88px;
        height: 100%;

        .app__proof-thumbnail__image {
            background-color: @c-body;
            img {
                max-width: 88px;
                max-height: 68px;
            }
        }

        .app__proof-thumbnail__overlay {
            text-align: center;

            &__comments {
                line-height: 54px;
                height: 100%;

                &__count {
                    font-size: 30px;
                }

                &__label {
                    margin-top: 0;
                }
            }
        }

        .dashboard__proof__progress {
            transform: translateY(-22px);
            width: 78px;
        }
    }

    &--tiny {
        width: 40px;
        height: 100%;

        .app__proof-thumbnail__image {
            img {
                max-width: 40px;
                max-height: 30px;
            }
        }
    }

    @media (min-width: @break-0) {
        width: 200px;

        &--list {
            width: 88px;
        }

        &--tile {
            width: 250px;

            .app__proof-thumbnail__image {
                img {
                    max-width: 250px;
                }
            }
        }

        &--tiny {
            width: 40px;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        background-color: #fff;

        img {
            max-width: 160px;
            max-height: 152px;
            display: inline-block;
            // filter: grayscale();
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            transition: filter 200ms @easing,
                        opacity 200ms @easing;

            @media (min-width: @break-0) {
                max-width: 200px;
            }
        }
    }

    &__overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity 200ms @easing;

        &__comments {
            color: @c-txt-d;
            position: absolute;
            top: 35%;
            width: 100%;
            transform: translateY(-50%);
            transition: opacity 200ms @easing,
                        transform 200ms @easing;

            &__count {
                font-size: 50px;
                font-weight: normal;
            }

            &__label {
                font-size: 10px;
                font-weight: 500;
                text-transform: uppercase;
                line-height: 0;
            }

            &--list {
                top: 50%;
            }
        }
    }

    &--email {
        display: flex;
        justify-content: center;
        align-items: center;
        background: fade(@c-grey-xd, 25%);

        inline-svg {
            width: 96px;
            height: 96px;
            background-color: @c-green;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 50%;
                height: 50%;
                fill: @c-white;
            }
        }

        &:hover {
            .app__proof-thumbnail--email > inline-svg {
                background-color: @c-green-ld;
            }
        }
    }

    &--yellow {
        .app__proof-thumbnail__overlay {
            background: fade(@c-yellow, 65%);
        }
    }

    &--grey {
        .app__proof-thumbnail__overlay {
            background: fade(@c-grey-xd, 25%);
        }

        .app__proof-thumbnail__image__uploader {
            background: fade(@c-grey-xd, 25%);
        }
    }

    &--blue {
        .app__proof-thumbnail__overlay {
            background: rgba(129, 204, 241, .65);
        }

        .app__proof-thumbnail__image__uploader {
            background: rgba(129, 204, 241, .65);
        }
    }

    &--green {
        .app__proof-thumbnail__image img {
            opacity: 0.15;
        }

        .app__proof-thumbnail__overlay {
            background-color: fade(@c-green, 25%);

            &__comments {
                color: @c-danger;
            }
        }

        .app__proof-thumbnail__image__uploader {
            background: fade(@c-green, 25%);
        }
    }

    &--file-error {
        .app__proof-thumbnail__image {
            background-color: @c-grey;
        }

        .app__proof-thumbnail__overlay {
            background: none;
        }
    }

    &:hover,
    &:active {
        .app__proof-thumbnail__overlay {
            opacity: 0;

            &__comments {
                transform: translateY(0);
            }
        }

        .app__proof-thumbnail__image img {
            filter: none;
            opacity: 1;
        }
    }

    &--processing,
    &--uploading {
        &:hover,
        &:active {
            .app__proof-thumbnail__overlay {
                opacity: 1;
            }
        }
    }
}
