/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.DecisionButton {
    line-height: 32px;
    padding: 5px 0px 5px 10px;

    &__arrow-down {
        transition: transform ease-in-out 200ms;
    }

    &__arrow-up {
        transition: transform ease-in-out 200ms;
        transform: rotate(180deg);
    }
}
