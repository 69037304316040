/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.MutedText,
.ValidationText {
    display: block;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 20px;
}

.MutedText {
    color: @c-txt-k;
}

.ValidationText {
    color: @c-danger;
}

.HighlightText {
    padding: 2px 4px;
    margin: -2px -4px;
    background-color: #fffef0;
    border: 1px inset rgba(0, 0, 0, .05);
    border-radius: 3px;
}