/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: initial;

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__controls {
        position: relative;
        overflow: hidden;
        z-index: 455; // comment pins are 454
    }

    &__cover {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        flex-direction: column;

        &--is-fullscreen {
            &,
            .app__pin-surface {
                cursor: default; // prevent grab cursor when in fullscreen
            }
        }
    }

    &__cover--is-fullscreen &__element {
        width: 100%;
        flex-grow: 1;
    }

    &__element {
        position: relative;
        flex-grow: 1;
        width: unset;
        height: unset;

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__overlay {
        position: absolute;
        inset: 0;
        bottom: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__inner {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }
}
