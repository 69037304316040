/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__drag-drop {
    &__clone {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2000;
        pointer-events: none;
        opacity: .75;
        //transition: transform .1s; // Adds a "smooth" transition
    }
    &__onto {
        border: 1px solid #7f8c8d !important; // Left this is as to match the old version

        .workflow-step &,
        .workflow-step--does-fall-through:first-of-type & {
            border-color: #7f8c8d !important;
        }
    }
}