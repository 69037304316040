/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
@list-height: 70px;
@list-thumb-height: @list-height - 2;
@list-thumb-width: 88px;
@list-height-mobile: 60px;
@list-thumb-height-mobile: @list-height-mobile - 2;
@list-thumb-width-mobile: 75px;

.dashboard {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;

    &__tab {
        &__proof-count {
            display: inline-block;
            font-size: 10px;
            margin-left: 5px;
            font-weight: normal;

            .tabs__nav__item--highlighted:not(.tabs__nav__item--selected) & {
                color: @c-danger;
            }
        }
    }

    &__muted {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        color: @c-txt-m;
    }

    &__tiles,
    &__tiles * {
        vertical-align: top; // Fix the broken upload tile bug

        &::selection {
            background-color: inherit;
            color: inherit;
        }
    }

    &__upload,
    &__proof {
        position: relative;
        display: inline-block;
        overflow: hidden;
        width: 200px;
        height: 330px;
        margin: 20px;

        .dashboard__proof__preview__image img {
            max-width: 180px;

            @media (min-width: @break-0) {
                max-width: 230px;
            }
        }

        @media (min-width: @break-0) {
            width: 250px;
        }
    }

    &__upload {
        .db-add-proof {
            margin-left: 0;
            margin-right: 0;
        }

        &__version {
            width: 180px;
            margin: 10px 0;
        }

        &--list {
            display: block;
            width: 100%;
            height: auto;
            margin: 0;
        }
    }

    &--list &__upload {
        display: block;
        width: 100%;
        height: auto;
        margin: 0;
    }

    &__empty-message {
        font-size: 24px;
        margin: 0 auto 40px;
        color: @c-txt-k;
        max-width: 600px;

        @media (max-width: @break-m) {
            font-size: 20px;
        }
    }

    &__proof {
        background-color: @c-white;
        box-shadow: 0 3px 0 0 rgba(0, 0, 0, .025);
        vertical-align: top;
        overflow: inherit;

        &__example {
            position: absolute;
            top: 100%;
            left: 50%;
            margin-top: 30px;
            transform: translateX(-50%);
            color: @c-txt-k;
        }

        &--overdue {
            overflow: visible;

            &:before {
                content: ' ';
                position: absolute;
                top: -10px;
                right: -10px;
                width: 20px;
                height: 20px;
                background-color: @c-danger;
                border-radius: 100%;
                z-index: 4;
            }

            & .dashboard__proof__footer__status {
                color: @c-danger;
            }
        }

        &--owned {
            width: 100%;
            height: 100%;
            position: absolute;
            cursor: pointer;
            z-index: 4;

            div {
                position: absolute;
                top: -12px;
                right: -12px;
                width: 25px;
                height: 25px;
                background-color: @c-white;
                border-radius: 100%;
                border: 1px solid @c-txt-m;
                color: @c-white;
                line-height: 25px;
            }

            &--selected {
                span {
                    color: @c-green;
                    border-color: @c-green;
                }
            }
        }

        &--not-started,
        &--needs-proofers,
        &--closed,
        &--archived,
        &--approved-and-archived {
            .dashboard__proof__preview {
                &:after {
                    background-color: rgba(127, 140, 141, .25);
                }
            }

            .dashboard__proof__preview__overlay--comments {
                opacity: 0;
            }
        }

        &--file-error,
        &--processing {
            .dashboard__proof__preview {
                &__image,
                &__image:hover {
                    img {
                        opacity: 0;
                    }
                }
            }
        }

        &--file-error {
            .dashboard__proof__preview {
                &:after,
                &:hover:after {
                    background-color: rgba(127, 140, 141, .25);
                }
            }
        }

        &--processing {
           .dashboard__proof__preview {
                &:after,
                &:hover:after {
                    background-color: rgba(19, 139, 59, .25);
                }
            }
        }

        &--approved,
        &--changes-requested {
            .dashboard__proof__preview {
                &:after {
                    background-color: rgba(19, 139, 59, .25);
                }
            }
        }

        &--brief-setup-needed {
            & .dashboard__proof__footer__status {
                color: @c-danger;
            }
        }

        &--approved {
            .dashboard__proof__preview__overlay--comments {
                opacity: 0;
            }
        }

        &--brief-upload-proof {
            .dashboard__proof__preview__overlay--comments {
                opacity: 0;
            }

            .dashboard__proof__preview {
                &:after,
                &:hover:after {
                    background-color: rgba(129, 204, 241, .65);
                }

                &:hover {
                    .dashboard__proof__preview__image img {
                        filter: grayscale(100%);
                    }
                }
            }
        }

        &--changes-requested {
            .dashboard__proof__preview:not(:hover) .dashboard__proof__preview__image img {
                opacity: .15;
            }
        }

        &--awaiting-new-version {
            .dashboard__proof__preview {
                &:after,
                &:hover:after {
                    background-color: rgba(19, 139, 59, .25);
                }

                &:hover {
                    .dashboard__proof__preview__image img {
                        filter: grayscale(100%);
                    }
                }
            }

            .dashboard__proof__preview__overlay--comments {
                opacity: 0;
            }

            .dashboard__proof__footer__status {
                @media (max-width: @break-m) {
                    padding-right: 15px;
                }
            }

            &.dashboard__proof--list {
                .dashboard__proof__footer__status--tile {
                    display: none;
                }
                .dashboard__proof__footer__status--list {
                    text-transform: none;
                    cursor: pointer;
                    padding-left: 2px;
                    font-size: 14px;
                    color: @c-green;
                    z-index: 1;

                    &.dashboard__lpu__hint {
                        @media (max-width: @break-m) {
                            display: none;
                        }
                    }

                    .dashboard__lpu__hint__muted {
                        margin-left: 10px;
                        margin-top: 4px;
                        display: inline-block;

                        @media (max-width: @break-m) {
                            display: none;
                        }
                    }
                }
            }

            &.dashboard__proof--tile .dashboard__proof__footer__status--list {
                display: none;
            }
        }

        &__progress {
            @height: 4px;

            display: inline-block;
            width: 100px;
            height: @height;
            background: @c-white;
            border-radius: @height;
            transform: translateY(-50px);

            &__bar {
                position: relative;
                display: block;
                height: @height;
                background: @c-green;
                border-radius: @height;
                transition: width .2s @easing;

                &__label {
                    position: absolute;
                    bottom: 100%;
                    left: 100%;
                    transform: translate3d(-50%, -10px, 0);
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: bold;
                    color: @c-grey-xxd;
                    pointer-events: none;
                    transition: bottom .25s @easing,
                                opacity .25s @easing;
                }
            }
        }

        &__try-reupload {
            position: absolute;
            font-size: 14px;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
            color: @c-green;

            &--list {
                display: none;
            }
        }

        &__thumb {
            display: block;
            height: 190px;
            width: 100%;
            overflow: hidden;
            cursor: pointer;
        }

        /* workflow delete confirm box */
        &__confirm {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            color: @c-white;
            background-color: @c-grey-tile;
            padding: 10px;
            width: 100%;
            z-index: 3;

            h3 {
                color: @c-txt-k;
                font-size: 16px;
            }

            &--list {
                height: 100%;

                h3 {
                    display: inline;
                    margin-right: 20px;
                }

                .dashboard__proof__confirm__inner {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    .app__btn--red {
                        order: 2;
                    }

                    .app__btn--green-text {
                        order: 1;
                    }

                    h3 {
                        margin-bottom: 0;
                        line-height: 2.5;
                    }

                    @media (min-width: @break-m) {
                        margin-top: 5px;
                    }

                    @media (max-width: @break-m) {
                        h3 {
                            display: none;
                        }
                    }
                }
            }

            &--tile {
                height: 188px;

                h3 {
                    margin: 23px 0 25px;
                }

                button:last-child {
                    margin: 15px 0 0 0;
                }

                html.is-dark-mode & {
                  background-color: #414545;
                }
            }
        }
        /* /workflow delete confirm box */

        &__preview {
            position: relative;
            display: table;
            height: 190px;
            width: 100%;

            &:after {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(255, 253, 233, .65);
                transition: background-color .3s @easing;
                z-index: 1;
            }

            &:hover {
                &:after {
                    background-color: transparent;
                }
            }

            &__overlay {
                position: absolute;
                top: 50%;
                width: 100%;
                text-align: center;
                z-index: 2;
                transform: translateY(-50%);

                .spinner {
                    display: block;
                    margin: 0 auto;
                }

                .dashboard__proof__progress {
                    transform: translateY(50px);
                }

                &--comments {
                    font-size: 60px;
                    line-height: 60px;
                    transition: opacity .2s @easing,
                                transform .2s @easing;

                    .dashboard__proof--pending & {
                        opacity: 0;
                    }

                    .dashboard__proof__preview:hover & {
                        opacity: 0;
                        transform: translateY(0);
                    }

                    &__label {
                        position: absolute;
                        left: 0;
                        width: 100%;
                        text-align: center;
                        font-size: 10px;
                        text-transform: uppercase;
                        line-height: 0;
                        margin-top: 10px;
                    }
                }

                &--comments {
                    color: @c-txt-d;
                }

                .dashboard__proof--changes-requested &--comments {
                    color: @c-danger;
                }
            }

            &__image {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                height: 190px;

                img {
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    max-height: 190px;
                    filter: grayscale(100%);
                    transition: filter .2s @easing,
                                opacity .2s @easing;
                    -webkit-transition: -webkit-filter .2s @easing,
                                        opacity .2s @easing;

                    &.ng-enter {
                        opacity: 0;
                    }

                    &,
                    &.ng-enter-active,
                    &.ng-leave {
                        opacity: 1;
                    }

                    &.ng-leave-active {
                        opacity: 0;
                    }

                    .dashboard__proof__preview:hover & {
                        filter: grayscale(0%);
                    }

                    .dashboard__proof--pending & {
                        opacity: 0;
                    }
                }
            }

            &__lock {
                z-index: 1;

                svg {
                    width: 80px;
                    fill: @c-grey-icon;
                }
            }
        }

        &__meta {
            display: block;
            padding: 15px;
            height: 90px;
            cursor: pointer;
            overflow: hidden;

            &__title {
                text-align: center;
                font-size: 14px;
                word-wrap: break-word;
            }

            &__try-reupload {
                font-size: 14px;
                display: none;
            }

            .dashboard__proof--tile &:before {
                content: ' ';
                position: absolute;
                bottom: 48px;
                left: 0;
                right: 0;
                height: 50px;
                opacity: 0;
                transition: opacity .2s @easing;

                html:not(.is-safari) & {
                    background: linear-gradient(to top, @c-white, transparent);
                }
            }

            .dashboard__proof--tile:hover &:before {
                opacity: 1;
            }
        }

        &__favoriteStar {
            width: 17px;
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            z-index: 2;
            opacity: 0;
            transition: opacity .4s @easing;

            --outline-color: @c-txt-m;
            --inside-color: transparent;

            &:hover {
                --outline-color: @c-grey-xxd;
            }

            &--active {
                @active-color: #f6a500;

                opacity: 1;
                --outline-color: @active-color;
                --inside-color: @active-color;

                &:hover {
                    --outline-color: darken(@active-color, 5%);
                    --inside-color: darken(@active-color, 5%);
                }
            }

            svg {
                path {
                    fill: var(--outline-color);
                }
                polygon {
                    fill: var(--inside-color);
                }
            }
        }

        &:hover &__favoriteStar {
            opacity: 1;
        }

        &__upload-options {
            display: none;
        }

        &__footer {
            position: relative;
            display: block;
            height: 45px;
            padding: 15px 18px;
            text-align: center;
            overflow: hidden;

            svg {
                height: 18px;

                g > *,
                rect,
                path {
                    fill: @c-txt-m;
                    transition: fill .2s @easing;
                }
            }

            &__status {
                font-size: 10px;
                text-transform: uppercase;
            }

            &__status,
            &__options {
                transition: transform .2s @easing,
                            opacity .2s @easing;
            }

            &__info,
            &__download {
                position: absolute;
                right: 0px;
                bottom: 12px;
                padding: 0 13px;
                z-index: 2;

                svg {
                    height: 16px;
                }

                @media (min-width: @break-m) {
                    display: none;
                }

                &:hover {
                    svg g > * {
                        fill: @c-grey-xxd;
                    }
                }
            }

            &__download {
                right: 18px;
                bottom: 10px;
            }

            .app__btn {
                transform: translateY(-20px);
            }

            &__options {
                position: absolute;
                display: none;
                bottom: 12px;
                left: 10px;
                right: 10px;
                text-align: center;
                list-style: none;
                padding: 0;
                margin: 0;
                font-size: 0;

                .dashboard--list & {
                    text-align: right;

                    .info,
                    .manage {
                        float: right;
                    }
                }

                // info, print, download, locked, manage

                li {
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                    height: 18px;
                    width: 36px;
                    text-align: center;

                    &:hover {
                        svg g > *,
                        svg rect,
                        svg path {
                            fill: @c-grey-xxd;
                        }

                        &.locked {
                            svg g > *,
                            svg path,
                            svg rect {
                                fill: @c-txt-m;
                            }
                        }
                    }

                    > span {
                        font-size: 10px;
                        text-transform: uppercase;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transition: transform .2s @easing,
                                    opacity .2s @easing;
                        transform: translateY(20px) translateX(-50%);
                        opacity: 0;
                        white-space: nowrap;
                    }

                    &:hover > span {
                        transform: translateY(5px) translateX(-50%);
                        opacity: 1;
                    }

                    &.download .download__progress {
                        position: absolute;
                        bottom: 20px;
                        left: 0;
                        right: 0;
                        font-size: 10px;
                        text-transform: uppercase;
                        text-align: center;
                        color: @c-txt-m;
                    }
                }

                .print svg,
                .locked svg {
                    height: 16px;
                }

                .info {
                    margin-top: -2px;
                }

                .manage svg {
                    height: 26px;
                    margin-top: -3px;
                }

                .dashboard__proof--downloading & .download > span {
                    transform: translateY(5px) translateX(-50%);
                    opacity: 1;
                }

                .locked {
                    cursor: default;
                }
            }
        }

        @media (min-width: @break-m) {
            &__footer__options {
                display: block;
            }

            &:not(:hover):not(&--downloading)&:not(&--list) &__footer__options,
            &--tile:hover &__footer__status,
            &--tile&--downloading &__footer__status {
                transform: translateY(40px);
                opacity: 0;
            }
        }

        &--list {
            display: block;
            width: 100%;
            height: @list-height;
            margin-left: auto;
            margin-right: auto;
            max-width: 900px;
            padding-left: @list-thumb-width;

            @media (max-width: @break-m) {
                height: @list-height-mobile;
                padding-left: @list-thumb-width-mobile;
            }
        }

        &--list&--overdue:before {
            width: 14px;
            height: 14px;
            top: -7px;
            left: @list-thumb-width - 7;

            @media (max-width: @break-m) {
                left: @list-thumb-width-mobile - 7;
            }
        }

        &--list &__thumb {
            position: absolute;
            top: 0;
            left: 0;
            width: @list-thumb-width;
            height: 100%;

            @media (max-width: @break-m) {
                width: @list-thumb-width-mobile;
            }
        }

        &--list &--owned {
            width: ~'calc(100% - 88px)';

            div {
                width: 22px;
                height: 22px;
                top: -10px;
                font-size: 13px;
            }

            span {
                position: relative;
                top: -2px;
            }

        }

        &--list &__upload-options {
            display: block;
            position: absolute;
            bottom: 0;
            padding: 0 20px 5px;
            transition: opacity .2s @easing,
                        transform .2s @easing;

            @media (min-width: @break-m) {
                opacity: 0;
                transform: translateY(60px);
            }
        }

        &--list&--upload-shown &__upload-options {
            transform: translateY(0);
            opacity: 1;
            z-index: 2;
        }

        &--list&--upload-shown &__footer__status--list {
            display: none;
        }

        &--list&--file-error&--upload-shown &__footer__status,
        &--list&--brief-upload-proof&--upload-shown &__footer__status {
            display: none;
        }

        &--list&--file-error,
        &--list&--brief-upload-proof {
            @media (max-width: @break-m) {
                .dashboard__proof__footer__status {
                    display: none !important;
                }
            }
        }

        &--list &__preview {
            &, &__image {
                width: @list-thumb-width;
                height: 100%;
                display: flex;
                align-items: center;

                @media (max-width: @break-m) {
                    width: @list-thumb-width-mobile;
                }
            }

            &__image img {
                max-width: @list-thumb-width;
                max-height: @list-thumb-height;

                @media (max-width: @break-m) {
                    max-width: @list-thumb-width-mobile;
                    max-height: @list-thumb-height-mobile;
                }
            }

            &__lock {
                width: @list-thumb-width;

                svg {
                    width: 30px;
                }

                @media (max-width: @break-m) {
                    width: @list-thumb-width-mobile;
                }
            }
        }

        &--list &__meta {
            height: auto;
            padding: 10px 20px 0;

            &__try-reupload {
                display: block;
                position: absolute;
                right: 100px;
                bottom: 7px;
                z-index: 2;
                color: @c-green;
            }
        }

        &--list &__footer {
            padding: 0 20px 10px;

            .dashboard__proof__footer__status {
                padding-top: 18px;
                display: inline-block;

                @media (max-width: @break-m) {
                    padding-top: 12px;
                }
            }

            .dashboard__proof__footer__options {
                height: 0;
                bottom: 27px;
                z-index: 2;
                left: inherit;

                svg {
                    height: 16px;
                }

                .print svg {
                    height: 14px;
                }

                .locked svg {
                    height: 15px;
                }

                .info {
                    margin-top: -1px;
                }

                .manage svg {
                    height: 26px;
                    margin-top: -3px;
                }

                .delete svg {
                    height: 18px;
                    margin-top: -3px;

                    @media (max-width: @break-m) {
                        height: 15px;
                        margin-top: 0;
                    }
                }
            }
        }

        &--list &__meta__title,
        &--list &__footer {
            text-align: left;
        }

        &--list &__meta__title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (max-width: @break-m) {
                font-size: 12px;
            }
        }

        &--list &__preview__overlay--uploader {
            // TODO
        }

        &--list&--awaiting-new-version,
        &--list&--file-error,
        &--list&--brief-upload-proof {
            .dashboard__proof__thumb,
            .dashboard__proof__preview {
                width: 100%;
                height: @list-height;

                @media (max-width: @break-m) {
                    height: @list-height-mobile;
                }
            }

            .dashboard__proof__preview:after {
                width: @list-thumb-width;

                @media (max-width: @break-m) {
                    width: @list-thumb-width-mobile;
                }
            }

            .dashboard__proof__preview__overlay--uploader {
                width: 100%;
                height: @list-height;

                .file-select {
                    width: 100%;
                    height: @list-height - 2;
                    z-index: 1;

                    &__content {
                        width: @list-thumb-width;
                    }

                    @media (max-width: @break-m) {
                        height: @list-height-mobile - 2;

                        &__content {
                            width: @list-thumb-width-mobile;
                            padding-top: 4px;
                        }
                    }
                }

                .highlight {
                    border: 1px solid @c-grey;

                    .file-select {
                        z-index: 3;
                    }
                }
            }
        }

        &--list &__preview__overlay--comments {
            font-size: 30px;
            line-height: 54px;
            height: 100%;

            &__label {
                margin-top: 0;
            }

            @media (max-width: @break-m) {
                line-height: 46px;
                font-size: 26px;
            }
        }

        &--list &__preview__overlay .dashboard__proof__progress {
            transform: translateY(22px);
            width: @list-thumb-width - 10;

            @media (max-width: @break-m) {
                width: @list-thumb-width-mobile - 10;
            }
        }

        &--list &__try-reupload {
            display: none;
        }

        &--tile &__meta {
            max-height: 85px;
            margin-bottom: 5px;
        }

        &__meta {
            @media (max-width: @break-0) {
                max-height: 95px !important;
            }
        }

        &--brief {
            .dashboard__proof__preview {
                &:after {
                    background-color: rgba(129, 204, 241, .65);
                }
            }
        }

    }

    @media (max-width: @break-m) {
        .db-search {
            #search, ::-webkit-input-placeholder, label, input {
                font-size: 20px;
            }
        }
        #slider span {
            margin-right: 8px;
        }
    }

    &__search__close {
        display: flex;
        justify-content: center;
        @size: 46px; // same as .DropdownOption
        position: absolute;
        color: @c-txt-m;
        font-size: 1rem;
        right: 0;
        width: @size;
        height: @size;
        line-height: @size - 1;

        &:hover {
            color: @c-txt;
        }
    }

    .tabs__tab {
        margin-top: 40px;
        padding: 0 10% 60px;
        text-align: center;

        .tab-spinner {
            position: absolute;
            left: 50%;
            margin-top: 85px;
            margin-left: -30px;
        }
    }

    .search-icon {
        float: left;

        svg {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 13px;
            z-index: 2;
            fill: @c-grey-icon;
        }
    }

    &__help-message {
        strong {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            color: @c-txt-k;
        }
    }

    &__examples {
        position: relative;
        display: inline-block;

        .dashboard--list & {
            display: block;
            margin-top: 40px;
            padding-top: 5px;

            &__label {
                left: 0;
                right: 0;
            }
        }

        .dashboard__proof {
            pointer-events: none;
        }

        &__label {
            position: absolute;
            top: -20px;
            left: 20px;
            right: 20px;
            line-height: 21px;
            text-align: center;
            z-index: 1;

            &:before {
                content: ' ';
                position: absolute;
                top: 10px;
                border-bottom: 1px solid @c-green;
                left: 0;
                right: 0;
                z-index: -1;
            }

            span {
                background-color: @c-body;
                color: @c-green;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: bold;
                padding: 0 10px;
            }
        }
    }

    &__lpu {
        position: relative;
        display: block;
        background-color: @c-white;
        box-shadow: 0 3px 0 0 rgba(0, 0, 0, .025);
        height: @list-height;
        padding-left: @list-thumb-width;
        text-align: left;
        cursor: pointer;
        overflow: hidden;
        max-width: 900px;
        margin: 0 auto;

        &--reupload {
            background-color: transparent;
            z-index: 1;

            .dashboard__lpu {
                &__image {
                    background-color: rgba(19, 139, 59, 0.25);
                    width: 88px;
                    height: 70px;
                }

                &__hint {
                    display: none;
                    top: 50px;

                    &__muted {
                        display: none;
                        display: inline-block;
                        padding-left: 10px;
                        margin-top: 3px;

                        @media (max-width: @break-m) {
                            display: none;
                        }
                    }
                }
            }

            &.dashboard__lpu--show-options {
                .dashboard__lpu__hint {
                    display: none;
                }
            }

            &__error-grey,
            &__blue {
                .dashboard__lpu__hint {
                    visibility: hidden;
                }
            }

            &__error-grey .dashboard__lpu__image {
                background-color: rgba(127, 140, 141, .25);
            }

            &__blue .dashboard__lpu__image {
                background-color: rgba(129, 204, 241, .65);
            }

            &.dashboard__lpu--show-options {
                .dashboard__lpu__options {
                    z-index: 2;
                    background-color: @c-white;
                }
            }
        }

        &__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            .file-select {
                width: 100%;
            }

            &.highlight {
                border: 1px solid @c-grey;

                .file-select {
                    z-index: 2;
                }
            }

            img {
                width: @list-thumb-width;
                height: @list-thumb-height;
            }
        }

        &__hint,
        &__options {
            transition: margin-top .2s @easing,
                        opacity .2s @easing,
                        transform .2s @easing;
        }

        &__hint {
            padding-left: 20px;
            font-size: 14px;
            color: @c-green;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;

            @media (max-width: @break-m) {
                font-size: 12px;
                padding-left: 15px;
            }

            &__muted {
                font-size: 10px;
                text-transform: uppercase;
                color: @c-grey-xxdd;
                margin-top: 6px;

                @media (max-width: @break-m) {
                    display: none;
                }
            }
        }

        &__options {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            margin-top: 60px;
            margin-left: 20px;

            @media (max-width: @break-m) {
                margin-left: 15px;
            }
        }

        &--show-options {
            .dashboard__lpu__options {
                margin-top: 20px;
                opacity: 1;

                @media (max-width: @break-m) {
                    margin-top: 15px;
                }
            }

            .dashboard__lpu__hint {
                @media (max-width: @break-m) {
                    transform: translateY(-20px);
                }

                &__muted {
                    visibility: hidden;
                }
            }
        }
    }

    &__lpu__options,
    &__proof__upload-options {
        a {
            display: inline-block;
            margin-right: 20px;
            transition: transform .2s @easing;

            svg {
                height: 18px;
                width: 18px;
            }

            &:hover {
                transform: scale(1.2);
            }

            @media (max-width: @break-m) {
                margin-right: 15px;
            }
        }
    }

    .navigation__mobile {
        @media (min-width: @break-m) {
            display: none;
        }

        .DropdownOption--highlighted .dashboard__tab__proof-count {
            color: @c-danger;
        }

        .Dropdown,
        .Dropdown__options {
            width: 250px;
        }

        .dashboard {
            &__search__close {
                right: 0;
            }

            &__tab__label {
                display: flex;
                align-items: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .ManageTab__workflowChooser__dropdown {
      .Dropdown__options {
        max-height: 50vh;
      }
    }

    &.proof-dashboard,
    &.domain-admin-dashboard {
        padding-left: 0;
        padding-right: 0;

        .dashboard__search {
            padding-left: 20px;
            padding-right: 20px;

            @media (min-width: @break-m) {
                margin-left: 250px;

                .db-search {
                    margin-bottom: 30px;
                }
            }

            .db-search {
                margin-bottom: 0;
            }
        }

        .tab-description {
            font-size: 16px;
            color: #555;
            margin-top: @gap;
            background-color: #d6d6d6;
            padding: 25px;
            border-radius: 6px;
            text-align: center;
            font-weight: 500;
        }

        .tabs {
            &__tab {
                padding-left: 5%;
                padding-right: 5%;
                margin-top: 20px;
                z-index: 1;

                @media (min-width: @break-m) {
                    margin-top: 0;

                    .dashboard__tiles {
                        margin-left: 250px;
                        margin-top: 0;
                    }
                }
            }
        }
    }

    &__show-more {
        @media (min-width: @break-m) {
            margin-left: 250px;
        }
    }

    &__drag-hover-drop {
        border: 1px solid @c-grey;
    }
}
