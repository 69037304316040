/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__toolbar {
    position: relative;
    height: 60px;

    &__tool {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); // overridden (but here as a fallback)
        opacity: 0;
        transition: transform .25s ease-in-out,
                    opacity .25s ease-in-out;

        &--visible {
            opacity: 1;
            z-index: 1;
        }

        &:not(&--visible) {
            pointer-events: none;
        }
    }
}
