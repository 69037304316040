/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Input {
    display: inline-block;
    height: 46px;
    padding: 0;
    margin: 0;
    border: 0;
    border-bottom: 1px solid @c-border;
    background-color: transparent;
    border-radius: 0;
    font-size: 18px;
    color: #444;
    outline: none;
    max-width: 100%;

    html.is-dark-mode & {
        border-bottom-color: #464646;
        color: @c-grey-xl;
    }

    &:focus {
        border-bottom-color: #a8a8ad;
    }

    &:not(&--compact):read-only {
        border-bottom: none;
    }

    &--block {
        display: block !important;
        width: 100% !important;
    }

    &--compact {
        display: block !important;
        width: 100% !important;
    }

    &--unified {
        display: block !important;
        width: 100% !important;
        border-bottom-width: 0 !important;
        background-color: #efefef !important;
        border-radius: 4px !important;
        padding-left: 14px !important;
        padding-right: 14px !important;

        &:focus {
            background-color: darken(#efefef, 4%) !important;
        }

        html.is-dark-mode & {
            background-color: #2d2d2d !important;
        }
    }
}
