/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.DeviceWrapper {
    position: relative;

    &__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }

    &__device {
        position: relative;
    }

    &__background,
    &__layer {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    &__layer {
        overflow: hidden;
    }

    &__background {
        outline: 2px solid #dcdcde;
    }

    &__scale {
        top: ~"calc(100% - 56px)";
        left: 50%;
        text-transform: uppercase;
        pointer-events: none;
    }
}