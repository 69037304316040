/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Spinner {
    display: inline-block;
    position: relative;

    &--center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    .spinner {
        transition: none !important;
    }
}

.InlineSpinner {
    position: relative;

    &__spacer {
        display: inline-block;
    }

    .Spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
}
