/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__quote {
    display: block;
    background-color: @c-yellow;
    padding: 20px;
    font-size: 14px;
    color: @c-grey-xxdd;
    max-width: 560px;
    text-align: left;
    margin: 10px auto 20px;

    pre {
        padding: 0;
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        white-space: pre-wrap;
    }
}
