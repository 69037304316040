/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.EmailBrandingSettings {
    margin: 40px 0;

    &__note {
        color: @c-txt-k;
        font-size: 10px;
        margin-top: 10px;
        text-transform: uppercase;
    }
}
