/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__comments-page {
    &__heading {
        text-align: center;
        font-size: 0;
        padding: 5px;
        border-radius: 6px;
        transition: background-color .2s @easing;
        display: flex;
        justify-content: center;

        @media (max-width: @break-m) {
            margin-top: 30px;
        }

        &, // Default
        &--solid {
            background-color: #fff;

            @media (max-width: @break-m) {
                position: relative;
            }
        }

        &--opaque {
            background-color: rgba(255, 255, 255, .6);

            &:hover {
                background-color: #fff;
            }
        }

        .app__comments & {
            cursor: pointer;
        }

        &--current-page {
            background-color: #f8f8f8 !important;
            cursor: default !important;
            // position: sticky;
            // top: 0;
            position: relative;
            z-index: 10;
            box-shadow: 0 5px 20px rgba(187, 187, 187, 0.5);
        }

        &__text {
            display: inline-block;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
            margin: 0 10px;
            color: #6a6a6a;
            line-height: 30px;
            white-space: nowrap;

            @media (max-width: @break-m) {
                font-size: 12px;
                line-height: 28px;
            }

            &__private {
                display: inline;
                background-color: #ffe6d3;
                color: #cb671e;
                border-radius: 100px;
                padding: 2px 8px;
                
                &:not(:first-child) {
                    margin-left: 4px;     
                }
            }

            &--name {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            &.active,
            strong {
                color: #444;
            }

            &:not(:first-of-type) {
                &:before {
                    position: absolute;
                    margin-left: -12.5px;
                    content: '\00B7';
                    cursor: default; /* Fixes a graphical inconsistency */
                }
            }
        }

        &__close {
            position: relative;
            transform: translateX(10px);
            opacity: 0;
            transition: transform .2s @easing,
                        opacity .2s @easing;

            &:not(:first-of-type) {
                &:before {
                    content: none;
                }
            }
        }

        &__avatar {
            position: absolute;
        }

        &:not(:hover) &__reveal {
            max-width: 0;
            opacity: 0;
        }

        &__reveal {
            display: inline-block;
            max-width: 50px;
            white-space: nowrap;
            opacity: 1;
            transition: all .4s @easing;
            margin-left: -4px;
            margin-right: 0;
        }

        &__pages {
            @media (max-width: @break-m) {
                margin-top: 0;
            }
        }
    }
    @media (max-width: @break-m) {
        &__header {
            width: 100%;

            h3 {
                font-size: 22px;
            }

            .app__close-btn {
                top: 22px;
                right: 20px;

                svg {
                    width: 25px;
                    height: 25px;
                    fill: @c-txt-k;
                }
            }
        }
    }
    @media (min-width: @break-m) {
        &__header {
            display: none;
        }
    }

    &__filters {
        padding: 5px 0;

        &:hover {
            .app__comments-page__heading__close {
                opacity: 1;
                transform: translateX(30px);
            }
        }
    }

    &__comments[ng-transclude] {
        padding-top: 20px;
    }
}
