/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Tag {
    @height: 26px;

    display: inline-block;
    border-radius: @height;
    background-color: #737373;
    color: #fff;
    font-size: 12px;
    line-height: @height;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;

    &--imported {
        display: flex;
        border: 1px solid #9c9c9c;
        border-radius: 1000px;
        padding: 3px;
        margin: -3px;
    }
}
