/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.UrlImport {
    &__validation {
        margin-top: 20px;
        margin-bottom: 20px;

        &__error {
            display: flex;

            a {
                text-transform: uppercase;
                font-size: 10px;
                padding-left: 20px;
            }
        }

        &__icon,
        &__text {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__icon {
            &__circle {
                background-color: @c-danger;
                width: 30px;
                height: 30px;
                border-radius: 30px;
                display: inline-block;
                padding: 3px;

                svg {
                    width: 24px;
                    height: 24px;
                    fill: #fff;
                }

                @media (max-width: @break-m) {
                    width: 24px;
                    height: 24px;
                    padding: 2px;

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        &__text {
            padding-left: 10px;
        }
    }

    &__input {
        margin-bottom: 40px;

        input {
            width: 100%;
        }

        .Input {
            padding-right: 40px;
        }
    }

    &__basic-auth {
        background-color: rgba(255, 255, 255, .9);
        padding: 20px 20px 1px;
        border-radius: 6px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, .05);

        .PageSubheading {
            margin-top: 0;
            margin-bottom: 20px;
        }

        .app__on-off-toggle__options {
            margin-right: 0;
            margin-left: 20px;
        }
    }

    &__more-icon {
        fill: #333;
    }

    &__more-button {
        position: absolute;
        top: 0;
        right: 0;
        padding-left: 8px;
        padding-right: 8px;

        @media (max-width: @break-m) {
            top: 16px;
        }
    }
}
