/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__btn-group {
    @btn-spacing: 10px;

    display: block;
    margin: 0 -@btn-spacing;
    font-size: 0;

    .clearfix();

    .app__btn {
        margin: 0 @btn-spacing;
    }

    &--left {
        .app__btn {
            float: left;
        }
    }

    &--center {
        text-align: center;

        .app__btn {
            float: none;
        }

        .show-more {
            margin-bottom: 10px;
        }
    }

    &--right {
        .app__btn {
            float: right;
        }
    }

    &__settings {
        padding: 20px;
        display: inline-flex;
        cursor: pointer;

        svg {
            width: 30px;
            height: 30px;
            fill: @c-grey-icon;

            @media (max-width: @break-m) {
              width: 24px;
              height: 24px;
            }
        }

        &:hover {
            svg {
                fill: @c-grey-icon-dark;
            }
        }
    }
}
