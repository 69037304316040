/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/*

	.LESS include - Loading

*/	

.loading							{ 
	.fill; .fix(0,0); z-index: 4; display: none; //pointer-events: none;
	background: fade( @c-body, 90% );
	&.active						{ .block; }
}
.load-anim							{
	
	@s : 30px;
	@t : 4px;
	
	@anim-time: 2500ms;
	
	.block; .dim(@s,@s); .abs(@t: 50%, @l: 50%); margin: -@s/2 0 0 -@s/2;
	
	&:before								{
		content: ' ';
		position: absolute;
		top: 50%;
		left: 50%;
		width: @s * 2;
		height: @s * 2;
		margin-top: -@s;
		margin-left: -@s;

		border-radius: 50%;
		border: 1px solid rgba(0, 0, 0, .1);
		border-top-color: rgba(0, 0, 0, .5);
		animation: spinner linear infinite;
		border-width: 4px;
		border-color: rgba(0, 0, 0, .1);
		border-top-color: #138b3b;
		animation-duration: 600ms;
	}
}
