/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* Header & navigation ==========================================================================*/
header {
    color: @c-txt-m;

    transition: transform .2s ease-in-out, opacity .2s ease-in-out;

    @media (min-width: @break-m) {
        html.feature-no-header &,
        html.hide-header & {
            transform: translateY(-100%);
            opacity: 0;
        }
    }

    .main-nav,
    .login-nav {
        a {
            .transition(250ms);

            display: inline-block;
            line-height: @head-h;
        }
    }

    .main-nav,
    .login-nav,
    .marketing-footer {
        .cen;

        background-color: #fff;

        a {
            padding: 0 @gap*2;

            &.selected {
                color: @c-txt;
            }
        }

        a,
        a:active,
        a:visited {
            .upper;
            .in-block;
            .bold;

            color: @c-txt-m;
            margin: 0 0 0 -4px;
            font-size: 12px;

            &:hover,
            &:active {
                color: @c-green;
            }

            &.selected {
                color: @c-txt;
            }
        }

        a.active {
            color: @c-txt-d;
        }
    }

    @media all and (max-width: @break-3) {
        .main-nav a {
            padding: 0 @gap;
        }
    }

    @media all and (max-width: @break-4) {
        .main-nav a {
            padding: 0 @gap / 2;
        }
    }

    @media all and (max-width: @break-m) {
        .main-nav,
        .marketing-footer {
            .fix(@t: 0, @l: 0);
            .transition(500ms);
            .transform(translateY(-100%));

            background: @c-green;
            width: 100%;
            height: 100%;
            padding-top: @head-h;

            a {
                &,
                &:focus {
                    display: block !important;
                    line-height: 1;
                    padding: @gap;
                    color: @c-yellow !important;
                    font-size: 18px !important;
                }

                &:hover,
                &:active {
                    background: rgba(255, 255, 255, 0.5);
                    color: @c-green !important;
                }

                &.selected {
                    .outline;

                    color: #fff;
                }
            }
        }

        .login-nav {
            display: none;
        }

        .menu-btn {
            .block;
        }
    }

    .login-nav {
        .abs(@t:0px, @r:@gap);

        a {
            padding-left: 0;
            padding-right: 0;

            &:not(:first-child) {
                padding-left: 10px;
            }

            &:not(:last-child) {
                padding-right: 10px;
            }
        }
    }

    .menu-btn						{
        @w 		: 44px;
        @h 		: 60px;
        @bh 	: 2px;

        .abs(@t:50%, @r:@gap); margin-top: -@h/2 !important; .dim(@w,@h); //.outline;
        z-index: -1; // Place behind the authenticated user's avatar
        //	border: @bh solid @c-grey;

        .bar 						{ .block; width: @w - @bh * 4; height: @bh; background: @c-grey; }

        span 						{
            .bar; .rel; top: 50%; margin-top: -@bh/2; left: @bh*2;
            &:before 				{ content:''; .bar; .abs(@t:-@gap/2, @l:0); .transition( 500ms ); }
            &:after 				{ content:''; .bar; .abs(@t:@gap/2, @l:0); .transition( 500ms ); }
        }

        &:hover,
        &:active					{
            border-color: @c-green;
            span,
            span:before,
            span:after 				{ background: @c-green; }
        }

        @media (min-width: @break-m) {
            display: none;
        }
        @media (max-width: @break-m) {
            transform: scale(.67, .67);
        }
    }

    @media (max-width: @break-m) {
        &.show-mob-nav						{
            .logo 							{
                opacity: 0;
                &.alt-logo					{ opacity: 1; }
            }
            .main-nav                       { .transform( translateY(0) ); }
            .menu-btn						{
                border-color: #fff;

                span 						{ background: @c-green; }
                span:before,
                span:after 					{ background: #fff; }
                span:before 				{ .transform( rotate(45deg) translateY(7px) translateX(7px) ); }
                span:after 					{ .transform( rotate(-45deg) translateY(-7px) translateX(7px) ); }
            }
        }
    }

    &.is-marketing {
        background-color: white;
        border-bottom: none;
    }

}
