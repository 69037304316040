/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__audio-waveform {
    position: relative;

    > div > wave {
        overflow: visible !important;
    }
}