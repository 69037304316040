/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.DomainAdminProfile {
    padding: 40px;

    &__avatar {
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__error {
            max-width: 300px;
        }

        .app__btn {
            margin-top: 20px;
        }

        @media (max-width: @break-m) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    &__info {
        text-align: center;
    }

    &__name input {
        font-size: 18px;
        height: 37px;
        text-align: center;
    }

    &__email {
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    &__access {
        margin-top: 40px;

        &__subheading {
            font-size: 20px;
            color: #555;
            text-align: left;
            margin-bottom: 10px;
        }

        &__switch {
            margin-top: 10px;
        }

        &__remove-user {
            font-size: 14px;
            color: @c-txt-m;
            text-align: left;

            div {
                padding-bottom: 40px;
            }

            &__confirm {
                font-size: 14px;
            }
        }
    }
}
