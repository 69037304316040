/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__tools {
    &--magic-menu ul li {
        height: 60px;
        position: relative;
        display: inline-block;
    }

    &__tools-button {
        outline: none;
        border: 0;
        height: 40px;
        margin: 4px;
        position: absolute;
        left: 9px;
        top: 3px;
        background: transparent;

        svg {
            width: 23px;
            height: 23px;
            font-size: 0;

            path,
            polygon,
            rect {
                fill: @c-grey-icon;
            }
        }

        &__drawing {
            position: relative;
            left: 0;
        }

        &__inverted-pins {
            svg {
                transform: rotate(180deg);
            }
        }

        &--barcode-scanner {
            svg {
                transform: scale(1.3);
            }
        }

        &--zoom-svg {
            top: 4px;

            svg {
                width: 27px;
                height: 23px;
            }

            &__hidden-pins {
                @media (min-width: @break-m) {
                    top: 0;
                }

                svg {
                    height: 19px;
                }
            }
        }

        &--zoom-m-svg {
            svg {
                width: 30px;
                height: 20px;
            }
        }

        &--ruler {
            svg {
                position: relative;
                top: 3px;
                width: 30px;
                height: 30px;
            }

            &--on svg path {
                fill: @c-orange-icon;
            }
        }

        &--grid-lines {
            svg {
                position: relative;
                top: 2px;
                width: 28px;
                height: 28px;
            }

            &--on svg path {
                fill: @c-orange-icon;
            }
        }

        &--preview-file {
            svg {
                position: relative;
                top: 2px;
                width: 26px;
                height: 26px;
            }
        }

        &--pdf-comment-import {
            svg {
                position: relative;
                top: 2px;
                width: 30px;
                height: 30px;
            }
        }

        &__svg {
            font-size: 0;
        }

        &--focus {
            svg {
                width: 30px;
                height: 30px;

                path.focus__background {
                    fill: none;
                }
            }

            @media (min-width: @break-m) {
                svg {
                    position: relative;
                    top: 3px;
                }
            }

            &--on svg path {
                fill: @c-orange-icon;
            }
        }
    }
}
