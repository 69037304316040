/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.AutoDeviceWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}