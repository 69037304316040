/* Copyright (C) PageProof Holdings limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * September 2014
 */

/*

	.LESS include - Master page styles

*/

.master										{
	
	max-width: 1400px; margin: auto;
	
	.style-note								{ 
		font-size: 10px; .upper; background: @c-grey-l; .in-block; padding: @gap/4 @gap/2; .round(@r*2); margin-left: @gap; .weight(300); 
		strong								{ .weight(600); }
	}
	
	
}