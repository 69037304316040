/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__proof-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__textarea {
        position: absolute;
        bottom: 100%;
        right: 0;
        background-color: rgba(0, 0, 0, .2);
        padding: 20px;
        z-index: -1;

        .app__ncomment__content {
            padding-left: 0;

            &__textarea {
                display: block;
                width: 418px;
                max-width: 100%;
                z-index: 1;
            }

            &__textarea.gatekeeper-mobile {
                display: none;
                @media (max-width: 768px){
                    display: block;
                }
            }

            &__textarea.gatekeeper-desktop {
                display: block;
                @media (max-width: 768px){
                    display: none;
                }
            }
        }

        .app__ncomment {
            margin-bottom: 0;
        }

        .app__proof-action__heading--mobile {
            padding-bottom: 12px;
            padding-top: 12px;
        }

        @media (max-width: @break-m) {
            max-width: 100%;
            bottom: 100%;
            padding: 0;
        }
    }

    &__spacer {
        display: block;
        padding: 0 20px;

        @media (max-width: @break-m) {
            display: none;
        }
    }

    &__footer {
        display: flex;
        flex-direction: row;

        @media (max-width: @break-m) {
            width: 100%;
            flex-direction: column;
        }
    }

    &__heading {
        margin-bottom: 0;
        color: @c-txt-k;

        &-subtext {
            font-size: 14px;
            color: @c-danger;
        }

        &--desktop {
            @media (max-width: @break-m) {
                display: none;
            }
        }

        &--mobile {
            position: absolute;
            background-color: @c-body;
            bottom: 100%;
            padding-top: 24px;
            width: 100%;
            text-align: center;
            font-size: 14px;
            color: @c-grey-xxdd;

            @media (min-width: @break-m) {
                display: none;
            }

            .app__proof-action__heading-subtext {
                vertical-align: bottom;
                margin: 4px 0;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        
        @media (max-width: @break-m) {
            margin-right: 0;
        }

        .app__btn--main {
            height: 40px;

            span {
                vertical-align: bottom;
                display: inline-block;
                margin-top: -2px;
            }
        }
    }

    &__form {
        display: flex;

        &__label {
            padding: 10px 20px;
        }

        &__input-container {
            position: relative;
        }

        &__input {
            max-width: 250px;
            width: 100%;

            &__message {
                position: absolute;
                left: 0;
                top: 100%;
                margin-top: 5px;
                font-size: 10px;
                text-transform: uppercase;
                color: @c-danger;
            }
        }

        @media (max-width: @break-m) {
            position: absolute;
            width: 100%;
            top: -70px;
            left: 0;
            padding-left: 20px;
            padding-bottom: 24px;
            background-color: @c-body;

            &__input {
                max-width: none;
            }

            &__input[type="text"] {
                font-size: 18px;
            }

            &__input-container {
                width: 100%;
                margin-right: 20px;
            }
        }

        &--desktop {
            @media (max-width: @break-m) {
                display: none;
            }
        }

        &--mobile {
            @media (min-width: @break-m) {
                display: none;
            }
        }
    }

    &__button {
        margin-left: 10px;

        &:not(:last-of-type) {
            margin-right: 10px;
        }

        &:disabled {
            background-color: @c-green;
            color: #fff;
        }
    }
}
