/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.ScrubberIcons {
    background-color: @c-border;
    display: flex;
    align-items: center;
    flex-grow: 0;
    z-index: 2;
    font-size: 0; // fixes space below all the icons offsetting them

    &__icon {
        display: inline-block;
        width: 30px;
        cursor: pointer;
        z-index: 2;

        @media (max-width: @break-m) {
            width: 25px;
        }

        svg {
            height: 20px;
            width: 20px;
            fill: @c-grey-icon;

            @media (max-width: @break-m) {
                height: 18px;
                width: 18px;
            }
        }

        &:hover:not(.ScrubberIcons__icon--active) svg {
            fill: darken(@c-grey-icon, 20%);
        }

        &--active svg {
            fill: @c-green;
        }

        &--loop {
            &--primary {
                position: absolute;
            }
        }

        &--loop&--active &--loop--pop svg {
            animation: small-pop .7s;
        }

        .Dropdown__options {
            margin-bottom: 18px;
            margin-left: -8px;
        }
    }
}
