.avatar-status-dot {
    border-color: #fff;
}

.proof-info .avatar-status-dot {
    border-color: #f5f5f5;
}

.workflow-step .avatar-status-dot {
    border-color: #fff;
}

.workflow-structure--shared-with .avatar-status-dot {
    border-color: #f5f5f5;
}

.domain-admin-dashboard .dashboard__proof .avatar-status-dot {
    border-color: #e1e5e6;
}

.domain-admin-dashboard .dashboard__proof--is-admin .avatar-status-dot {
    border-color: #138b3b;
}