/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__proof-manage {
    background-color: @c-green;
    color: #fff;

    &__heading {
        font-size: 18px;
        color: @c-white;
        margin: 20px 0 10px;
    }

    &__tools {
        flex-shrink: 0;
        line-height: 0;
        position: relative;
        display: flex;
        gap: 18px;
        height: 50px;
        align-items: center;
        justify-content: end;

        @media (max-width: @break-m) {
            gap: 10px;
            height: 34px;
        }
    }

    .head {
        display: flex;
        min-height: 100px;
        padding: 25px 40px;

        .app__proof-manage__head {
            flex-grow: 2;
            padding-right: 20px;
            font-size: 28px;
            line-height: 28px;
            min-width: 0;

            &__sub {
                margin-top: 8px;
                font-size: 14px;
                line-height: 14px;
                opacity: .7;
                display: flex;
                gap: 6px;

                &__title {
                    min-width: 0;
                }

                &__status{
                    white-space: nowrap;
                }
            }
        }

        @media (max-width: @break-m) {
            min-height: 67px;
            padding: 17px 20px 16px;
            margin-bottom: 1px;

            h2 {
                line-height: 67px;
                font-size: 22px;
            }

            .app__proof-manage__head {
                font-size: 22px;
                line-height: 22px;

                &__sub {
                    margin-top: 0;
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 500;
                }
            }
        }
    }
    &__content {
        padding: 40px;
        overflow-x: hidden;

        @media (max-width: @break-m) {
            padding: 0 20px;
        }
    }
    &__switch-option {
        display: inline-block;
        padding-left: 20px;
    }

    &__divider {
        margin: 20px 0;
        height: 2px;
        background-color: rgba(255, 255, 255, 0.2);
    }

    &__label {
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        padding-right: 10px;
        margin-top: 15px;
        float: left;
        margin-bottom: 15px;
    }

    &__settings {
        background-color: transparent;
        border: transparent;
        outline: none;
        width: 40px;
        height: 30px;
        padding: 0;

        svg {
            fill: @c-txt-k;
            height: 30px;
        }

        &:hover svg {
            fill: #999;
        }

        @media (max-width: @break-m) {
            right: 55px;
            top: 37px;

            svg {
                height: 24px;
            }
        }
    }

    &__info {
        background-color: transparent;
        border: transparent;
        outline: none;
        width: 40px;
        height: 30px;
        margin-bottom: 3px;

        svg {
            fill: @c-white;
            height: 24px;
        }

        &:hover svg {
            opacity: .6;
        }

        @media (max-width: @break-m) {
            height: 22px;

            svg {
                height: 22px;
            }
        }
    }

    &__options {
        a {
            display: block;
            color: #fff;
            font-size: 12px;
            padding: 20px;
            position: relative;
            font-weight: bold;
            text-transform: uppercase;
            height: 57px;

            &:hover {
                background: rgba(255, 255, 255, 0.05);

                .app__proof-manage__options__tip {
                    opacity: 1;
                    transform: translateX(0);
                }

                .app__proof-manage__options__editors-tip {
                    transform: translateY(0);
                    opacity: 0;
                }
            }

            #copy {
                width: 18px;
                height: 18px;
                margin-right: 5px;
                svg { 
                    opacity: 0.7;
                    transform: none;  
                }
            }

            > span:last-of-type {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -5px;
                left: auto;
                bottom: auto;
                width: 35px;
                height: 35px;

                svg {
                    fill: #fff;
                    transform: rotate(270deg);
                }
            }
        }

        &__tip {
            display: inline-block;
            font-weight: normal;
            text-transform: none;
            margin-left: 20px;
            opacity: 0;
            transform: translateX(130px);
            transition: transform 500ms @easing,
                        opacity 500ms @easing;

            @media (max-width: @break-3) {
                display: none;
            }
        }

        &__editors {
            display: block;

            &-tip {
                position: absolute;
                top: 50%;
                right: -5px;
                left: auto;
                bottom: auto;
                height: 70px;
                margin-right: 40px;
                padding: 0 10px;
                opacity: 1;
                max-width: 300px;
                overflow: hidden;
                transform: translateY(-50%);
                transition: transform 500ms @easing,
                            opacity 500ms @easing;
            }
        }
    }

    &__confirm {
        position: absolute;
        top: 100px;
        text-align: center;
        color: #fff;
        background-color: #138b3b;
        width: 100%;
        padding: 40px;
        right: -100%;
        height: ~"calc(100% - 100px)";
        overflow: auto;
        transition: left .2s @easing,
                    right .2s @easing;

        &-box {
            background-color: #fff;
            padding: 20px;
            border-radius: 4px;
            margin-top: 40px;

            p {
                color: @c-txt-k;
                margin-bottom: 40px;
            }

            a {
                padding: 8px 40px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;

                &:hover {
                    color: #0d5e28;
                }

                @media (max-width: @break-m) {
                    display: inline-block;
                    margin-bottom: 10px;
                }
            }

            h3 {
                font-weight: 100;
                font-size: 24px;
                color: @c-txt-k;
                @media (max-width: @break-m) {
                  font-size: 20px;
                }
            }

            &__button {
                position: relative;
                height: 40px;

                .spinner {
                    transform: scale(0.66);
                }
            }

            &__message > p {
                margin-bottom: 0;
            }

            &__returnTodos {
                p {
                    &:first-of-type {
                        margin-bottom: 0;
                    }
                }
                a {
                    line-height: 40px;
                }

                &__back {
                    position: relative;
                    top: 15px;
                }
            }

            &__options {
                margin-top: 40px !important;
        
                @media (max-width: @break-3) {
                    .app__btn {
                        display: block;
                        margin: 0 auto 10px auto !important;
                    }
                }
            }
        }

        &-show {
            right: 0;
        }

        @media (max-width: @break-m) {
            top: 67px;
            height: ~"calc(100% - 67px)";
        }
    }
    &:not(.active) {
        visibility: hidden;
    }

    .app__on-off-toggle {
        display: inline-block;

        &__options {
            margin-right: 10px;
        }

        &__message {
            width: 250px;
            display: inline-block;
        }
    }

    &__message-box {
        padding: 20px;
        background: #fffde9;
        border-radius: 2px;
        text-align: left;
        margin-bottom: 20px;

        p {
            margin-bottom: 20px;
        }

        textarea {
            margin-top: 0px;
            background: #fff;
            border: 1px solid #dcdcde;
            padding: 20px;
            font-size: 14px;
        }
    }
}
