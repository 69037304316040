/* Copyright (C) PageProof Holdings limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * September 2014
 */

/*

	.LESS include - Media Queries

*/

@break-0		: 1600px;
@break-half		: 1500px;
@break-1		: 1400px;
@break-2		: 1200px;
@break-3		: 1050px;
@break-4		: 900px;
@break-m		: 750px;
@break-6		: 600px;

.render-msg()					{
	.block; background: @c-black; color: #fff; position: fixed; bottom: 0; left: 0; .round(@r); padding: 3px 5px; z-index: 999; pointer-events: none;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 12px;
	//display: none;
}

html.not-production body:after {
	.render-msg;
	content: 'n/a (> @{break-0})';
	background: red;
	color: white;
}

@media all and ( max-width: @break-0 ) 	{
	
	html.not-production body:after							{ content: '0 (< @{break-0})'; background: yellow; color: black; }
	
}
@media all and ( max-width: @break-1 ) 	{

	html.not-production body:after							{ content: '1 (< @{break-1})'; background: pink; color: black; }
	
}
@media all and ( max-width: @break-2 ) 	{
	
	html.not-production body:after							{ content: '2 (< @{break-2})'; background: green; color: white;	}
	
}
@media all and ( max-width: @break-3 ) 	{
	
	html.not-production body:after							{ content: '3 (< @{break-3})'; background: purple; color: white;	}
	
}
@media all and ( max-width: @break-4 ) 	{
	
	html.not-production body:after							{ content: '4 (< @{break-4})'; background: orange; color: black; }
	
}
@media all and ( max-width: @break-m ) 	{
	
	html.not-production body:after							{ content: 'm (< @{break-m})'; background: blue; color: white; }
	
}

.screen-m {
	display: none;

	@media (max-width: @break-m) {
		display: block;
	}
}
