/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/*

	.LESS include - Workflow

*/
.o-workflow-section {

	max-width: 800px;
	margin: @gap*2 auto;
	text-align: center;

	.c-workflow-tile {
		width: 200px;
		height: 190px;
		margin: 0 auto;
		background-color: @c-grey-tile;

    html.is-dark-mode & {
      background-color: rgba(127, 140, 141, .25);
    }
        &__favoriteStar {
            opacity: 1;
        }

		img {
            margin-top: 15px;
			max-width: 200px;
			max-height: 130px;
		}

		&__footer {
			position: static;
			padding: 0;
		}
	}
}

.c-workflow-title {
    padding: 0 @gap*2 @gap*2;

    @media (max-width: @break-m) {
        input {
            font-size: 18px;
        }
    }
}

.c-on-off-toggle {
	.app__on-off-toggle {
		float: right;
		margin-right: 5px;
	}
}

.c-email-input {
    &__share {
        width: 70%;
        padding: 0 0 0 10%;

        input {
            font-size: 1em;
        }

        @media (max-width: @break-m) {
            width: 100%;
            max-width: 336px;
            padding-left: 0;

            input::placeholder {
                font-size: 16px;
            }
        }
    }
}

.c-proofer-email {
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: lowercase;
}

.c-can-edit-status {
    width: 130px;
    text-align: center;
    float: right;
    line-height: 30px;
    padding: 10px;
    text-transform: uppercase;
    font-size: 12px;
    color: @c-txt-k;
    font-weight: bold;
}

.c-shared-section__header {
    max-height: 30px;
    font-size: 1em;
    color: @c-txt-k;
    max-width: 600px;
    margin: 0 auto 40px;

    @media (max-width: @break-m) {
        font-size: 14px;
        margin-bottom: 25px;
    }
}
// step styles are shared between WF steps, and the 'share' module in WF Management
.step-styles						{
	background: rgba(255, 255, 255, 1);
  	.round(@r*2);
  	border: 1px solid @c-txt-m;
  	padding: @gap/2 25px 10px;
  	.rel;
	&.droppable					{ border-color: @c-grey-xd; }
	fieldset					{
		 padding-top: @gap*2;
	  	//display: none;
		.msg-error				{ .lft; width: 140px; }
	}
	&.add-proofer,
	&.add-share					{
		fieldset				{ .block; }
		.btn-add				{
			&:before 			{ content:'\2013'; }
			&:hover,
			&:active			{
				color: @c-danger;
				border-color: @c-danger;
			}
		}
	}

	.ava 					{
		display: block;
		margin: 0 0 @gap; cursor: move;
	}
	.btn-add					{
		margin: 0 @gap;
		&:before 				{ content:'+'; }
	}

}

.admin-workflows-screen {
  margin: 0 0 40px;

  .workflow-owner-title {
	text-align: center;
	margin: 0 0 30px;
	color: @c-txt-m;
    display: flex;
    justify-content: center;
    align-items: center;

    .workflow-owner-text {
        padding-right: 0.5rem;
    }

	@media (max-width: @break-m) {
		font-size: 18px;
		margin-bottom: 10px;
	}
  }

  .admin-proofers {
	padding: 0px 20px;
	position: relative;
	text-align: center;
	border: none;

	.btn-add-admin {
	  //width: 40px;
	  //height: 40px;
	  font-size: 20px;
	  margin: 10px 20px 10px 20px;
	  padding: 0 0 0 0;
		@media (max-width: @break-m) {
			height: 40px;
			width: 40px;
			line-height: 33px;
			transform: translateY(10px);
		}
	}

	.btn-add-admin.btn-open:hover {
	  color: @c-danger;
	  border-color: @c-danger;
	}


	.avatar {
	  margin: 10px 20px 25px 20px;
		@media (max-width: @break-m) {
			margin: 10px;
		}
	}

	fieldset {
	  max-width: 320px;
	}

	.btn-add-admin.btn-open:before {
	  content: '\2013';
	}

	.btn-add-admin:before {
	  content: '+';
	  font-size: 40px;
	  @media (max-width: @break-m) {
		  font-size: 30px;
	  }

	}

  }

}

.admin-wrapper {

  margin-bottom: 19px;
  margin-top: 20px;
  text-align: center;

  border-top: 2px solid @c-border;

  padding-top: 40px;

  h3 {
	text-align: center;
  }

  p {
	font-size: 10px;
	text-transform: uppercase;
	margin: 0 0 10px;
  }

  .admin-proofers {

	border-radius: 2px;
	padding: 0px 20px 10px;
	position: relative;
	text-align: center;

	.admin-proofers-section {
	  margin-bottom: 20px;
	}

	.btn-add-admin {
	  padding: 0 0 0 0;
	  font-size: 20px;
	  margin: 10px 10px 10px 10px;
	}

	.avatar {
	  margin: 10px 10px 25px 10px;
	}

	fieldset {
	  max-width: 320px;
		@media (max-width: @break-m) {
			max-width: 100% !important;
		}
	}

	.btn-add-admin.btn-open:hover {
	  color: @c-danger;
	  border-color: @c-danger;
	}

	.btn-add-admin.btn-open:before {
	  content: '\2013';
	}

	.btn-add-admin:before {
	  content: '+';
	  font-size: 40px;
	}

  }

}

.admin-wrapper-read-only {

  margin-bottom: 19px;
  margin-top: 20px;
  text-align: center;

  border-bottom:none;
  border-top: 2px solid @c-border;

  padding-top: 40px;

  h3 {
	text-align: center;
	margin-bottom: -2px;
  }

  p {
	font-size: 10px;
	text-transform: uppercase;
	margin: 0 0 10px;
  }

  .admin-proofers {

	border-radius: 2px;
	padding: 20px 20px 10px;
	position: relative;
	text-align: center;

	.admin-proofers-section {
	  margin-bottom: 20px;
	}

	.avatar {
	  margin: 11px 10px 25px 10px;
	}

  }

}


.co-owner-wrapper {

  text-align: center;

  p {
	font-size: 10px;
	text-transform: uppercase;
	margin: 0 0 15px;
  }

  .co-owners {

	padding: 20px 20px 10px;
	position: relative;

	text-align: center;
	margin-top: -15px;

	.admin-proofers-section {
	  margin-bottom: 20px;
	}

    .btn-add-admin {
        padding: 0 0 0 0;
        font-size: 20px;
        margin: 0 10px 25px 10px;

        @media (max-width: @break-m) {
            margin-top: 10px;
            margin-left: 8px;
            height: 40px;
            width: 40px;
            line-height: 33px;

            &:before {
                font-size: 30px !important;
            }
        }
    }

    .avatar {
        margin: 0 10px 40px 10px;

        @media (max-width: @break-m) {
            margin: 0;
        }
    }

    fieldset {
        max-width: 320px;
        margin-top: 40px;

        @media (max-width: @break-m) {
            margin-top: 0;
        }
    }

	.btn-add-admin.btn-open:hover {
	  color: @c-danger;
	  border-color: @c-danger;
	}

	.btn-add-admin.btn-open:before {
	  content: '\2013';
	}

	.btn-add-admin:before {
	  content: '+';
	  font-size: 40px;
    }

    @media (max-width: @break-m) {
        padding: 15px 20px 5px;
    }
  }
}

.workflow-structure {
    max-width: 800px;
    margin: auto;
    .cen;
    padding: @gap*2;
    .rel;

    &--shared-with {
        @media (max-width: @break-m) {
            padding: 0;
        }
    }

    &--shared-with__team {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__bubble {
            float: right;
            margin-left: 10px;
        }
    }

    .step-inner {
        width: 100%;
        padding: 20px;
        text-align: left;

        @media (max-width: @break-m) {
            padding: 20px 0;
        }

        &.cover {
            opacity: 0.3;
        }

        .proofer-email-field {
            max-width: 358px;
            margin-left: 64px;
            font-size: 16px;

            @media (max-width: @break-6) {
                margin-left: 0;
            }

            &.proof-pane {
                width: 220px;
                margin-left: 37px;
            }

        }
    }

    &--readonly .workflow-user {
        .proofer-role__text {
            right: 40px;
        }

        .proofer-role__share {
            right: 0;
        }
    }

    .proofer-avatar {
        width: 50px;
        height: 50px;
        float: left;
        padding-top: 4px;

        .avatar {
            @media (max-width: @break-m) {
                transform: scale(1);
            }
        }

        .ava {
            margin-top: 10px;
            margin-left: -22px;
        }

        &.read-only {
            .avatar {
                cursor: auto !important;
            }
        }

        .lock {
            width: 10px;
            height: 15px;
            margin-top: -57px;
            margin-left: -32px;
            opacity: 1;
            background-image: url("../../img/interface/padlock-grey.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

            &.extra-margin {
                margin-top: 27px;
            }

        }

    }

    .proofer-email {
        width: 100%;
        float: left;
        padding-top: 12px;
        padding-right: 10px;
        padding-left: 14px;
        margin-right: 100px;
        height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;

        &--sharedWith {
          width: 50%;
        }

        &--manageWorkflow {
            margin-right: 180px !important;

            @media(max-width: @break-6) {
                margin-right: 110px !important;    
            }
        }

        &.proof-pane {
            padding-top: 11px;
        }

        @media (min-width: @break-m) and (max-width: @break-3) {
            margin-right: 80px;
          }
        
        @media(max-width: @break-6) {
            margin-right: 80px;    
        }
    }

    .delete-proofer {
        max-height: 0;
        visibility: hidden;
        transition: max-height 0.2s ease-out;
        overflow: hidden;

        padding-left: 20px;
        padding-right: 20px;
        padding-top: 0px !important;
        margin-left: auto;
        margin-right: auto;
        margin-top: 12px;

        background-color: white;

        max-width: 400px;
        width: 100%;
        z-index: 100;

        .form-actions {
            padding-top: 0px;
            margin-bottom: 20px;
        }

        p {
            margin-top: 20px;
            color: @c-txt-m;
            font-size: 14px;
            line-height: 1.7;
        }

        position: relative;

        border-top: 1px solid @c-border;
        border-left: 1px solid @c-border;
        border-right: 1px solid @c-border;
        box-shadow: inset 0 -1px 0 @c-border;

        &.proof-pane {
            margin-bottom: 20px;
            max-width: 400px !important;
        }
    }

    .delete-proofer.open {
        max-height: 500px;
        visibility: visible;
        transition: max-height 0.2s ease-in;
    }

    .proofer-add {
        width: 70%;
        float: left;
        height: 55px;
        padding-left: 65px;
    }

    .msg-error {
        color: @c-danger;
        display: none;
        &:before {
            background: @c-danger;
        }
        font-size: 10px;
        .upper;
        transform: translateY(-50%);
        padding: 0 0 0 @gap;
        width: 300px;
        position: relative;
    }

    .wf-step {
        .step-styles;
        .avatar {
            // All other avatars scale to .67 on mobile.
            transform: scale(1);
        }

        @media (min-width: @break-m) and (max-width: 1020px) {
            padding: 15px;
        }

        @media (max-width: 330px) {
            padding: 10px 15px 5px !important;
        }

        .email-error {
            color: @c-txt-d;
        }

        &.highlight {
            border: 1px solid @c-danger !important;
            z-index: 1;
        }
    }
    h1 + div > .wf-step {
        margin-top: @gap*2;
    }
    .new-wf-step {
        background: @c-grey-ll;
        padding-bottom: @gap;
        cursor: pointer;
        border: none;
        &,
        .btn-add-new-step {
            transition: border-color .25s @easing,
            color .25s @easing;
        }
        .btn-add-new-step {
            border: 1px solid @c-txt-k;
            margin: 0 20px 0 90px;
            &:hover {
                border-color: @c-green;
            }

            @media (max-width: @break-m) {
                    margin: 0 10px 0 80px;
            }
        }
        &:hover,
        &.droppable {
            border-color: @c-green;

            .btn-add-new-step {
                color: @c-green;
            }
        }
        p {
            margin: 0;
            color: @c-txt-m;
            display: inline-block;
            text-transform: uppercase;
            font-size: 12px;
            vertical-align: super;
        }
    }

    .fake-wf-step {
        display: none;
    }
    .final-wf-step, .final-wf-step-current, .final-wf-step-complete {
        border: none;
        background: none;
        padding: 20px 20px 10px;
        margin-bottom: 15px;

        //.btn-add					{ display: none; }
        .btn-add {
            .dim(100, 100);
        }
        .wf-step-proofers {
            padding: 0;
            .avatar {
                margin: 0;
                .name {
                    .transform(translateX(-50%) translateY(0));
                    opacity: 1;
                }
            }
        }
        .avatar {
            cursor: default;
        }
        &.droppable {
            &:after {
                opacity: 1;
            }
        }
    }
    .wf-step-title {
        max-width: 600px;
        margin: auto;
        .cen;
        @media (max-width: @break-m) {
            font-size: 18px;
        }
    }
    .wf-step-proofers {
        padding: @gap*2 0 0 0;
        .placeholder {
            .dim(60, 60);
            .in-block;
            margin: 0 @gap;
            vertical-align: top;
            .transition(250ms);
            &.hide {
                width: 0px;
                margin: 0;
            }
        }

        .avatar {
            &:hover,
            &:active {
                .approver-label {
                    opacity: 0;
                }
            }
        }
    }

    &.doing-drag {
        .avatar {
            pointer-events: none;
        }
    }
    .dragging-avatar {
        .abs(@t:0, @l:0);
        margin: -30px 0 0 -30px;
        pointer-events: none;
    }

    .show-more {
        border-radius: 20px;
        height: 20px;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;

        svg {
            height: 20px;
            width: 20px;
        }

        &--reviewer {
            position: absolute;
            right: 0;
            top: 13px;
        }

        &--workflow-details {
            margin-left: 10px;
            transform: translateY(6px); // TODO
        }

        &--reviewer,
        &--workflow-details {
            background-color: @c-grey-bg;

            &:hover {
                background-color: @c-grey-ld;
            }

            svg {
                fill: rgba(0, 0, 0, .5);
            }
        }
    }

    .workflow-slide-reveal {
        position: relative;
        font-size: 12px;
        border-radius: 4px;
        background-color: @c-grey-bg;
        color: @c-grey-xxd;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
        display: flex;

        &-arrow {
            position: absolute;
            right: 50px;
            opacity: 1;
            content: ' ';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 10px 7px;
            border-color: transparent transparent @c-grey-bg transparent;
            transition: opacity .2s ease-in-out;

            &:not(.workflow-slide-reveal-arrow--show) {
                opacity: 0;
            }
        }

        &__title {
            padding: 10px;
            font-size: 14px;
            font-weight: bold;

            @media (max-width: @break-6) {
                height: 48px;
                padding-bottom: 0;
                font-size: 12px;
            }
        }

        &__divider {
            width: 1px;
            margin-top: 10px;
            margin-bottom: 10px;
            background-color: @c-txt-l;
        }

        &__role {
            padding: 10px;

            .workflow-slide-reveal__header {
                padding: 0;
            }

            &:hover {
                background-color: @c-txt-l;
                color: @c-txt-d;

                .proofer-role__text--highlight {
                    color: @c-warning-d;
                }
            }
        }

        &__description {
            cursor: pointer;

            @media (max-width: @break-m) {
                display: none;
            }
        }

        &__header {
            text-transform: uppercase;
            font-weight: bold;
            cursor: pointer;
            padding: 10px;

            &:hover {
                background-color: @c-txt-l;
                color: @c-txt-d;
            }

            &--red {
                color: @c-danger;

                &:hover {
                    color: @c-danger-d;
                }
            }

            &--green {
                color: @c-green;

                &:hover {
                    color: @c-green-d;
                }
            }

            &--orange {
                color: @c-warning;

                &:hover {
                    color: @c-warning-d;
                }
            }

            @media (max-width: @break-m) {
                font-size: 10px;
            }
        }

        &__column {
            padding: 5px 10px;

            @media (max-width: @break-m) {
                width: 50%;
                padding-left: 5px;
                padding-right: 5px;
            }

            &--left {
                width: 70%;
            }

            &--right {
                width: 30%;
            }
        }

        &__confirm-remove {
            padding: 10px;

            &--mobile {
                @media (min-width: @break-m) {
                    display: none;
                }
            }

            &:not(.workflow-slide-reveal__details__confirm-remove--mobile) {
                @media (max-width: @break-m) {
                    display: none;
                }
            }
        }
    }

    @media (max-width: @break-m) {
        h1 {
            font-size: 30px;
            margin: 0;
        }
    }
}

.touch .workflow-structure .wf-step-proofers .placeholder { .transition(0); display: none; }

// disabled editing on shared workflows
.tc-shared .workflow-structure		{

	input 							{ border: none; pointer-events: none; }
	.wf-step 						{
		.btn-add					{ display: none; }
		.wf-step-title				{ border: none; margin-top: -3px; }
		.wf-step-title + .wf-step-proofers { padding-top : @gap/2; }
		.avatar 					{
			cursor: default;
			.avatar-options			{ display: none; }
		}
	}
	.new-wf-step					{ display: none; }
}

.workflow-create {
	.workflow-thumb__inner {
		font-size: 75px;
		color: @c-txt-m;
	}

	&:hover {
		.workflow-meta h4,
		.workflow-thumb__inner {
			color: @c-green;
		}
	}
}

.workflow-step-user-repeat {
	transition: opacity .2s @easing, transform .5s @easing;

	.clearfix;

	&.ng-enter {
		opacity: 0;
		transform: scale(0);
	}

	&.ng-leave,
	&.ng-enter-active {
		opacity: 1;
		transform: scale(1);
	}

	&.ng-leave-active {
		opacity: 0;
		transform: scale(0);
	}
}

.workflow-step {
    @transition-duration: .2s;
    position: relative;

	section {
		transition: margin-top @transition-duration @easing,
					border-radius @transition-duration @easing,
					border @transition-duration @easing,
					color @transition-duration @easing !important;

		&:before,
		&:after {
			transition: opacity @transition-duration @easing;
			z-index: -1;
		}

		&.app__drag-drop__onto {
			z-index: 1;
		}
	}

	&--hide-arrow {
		section {
			&:before,
			&:after {
				opacity: 0;
			}
		}
	}

	&--is-current-step-group {
		section {
			border-width: 2px !important;
			border-color: @c-green !important;
			border-radius: 4px !important;
		}
	}

	&--add {
		section {
			&:hover {
				border-color: @c-border;
			}
		}
    }

    &--has-tooltip {
        .workflow-step__arrow {
            display: none;
        }
        .workflow-step__decision {
            display: none;
        }
    }

    &__arrow {
        display: flex;
        justify-content: center;

        svg {
            width: 20px;
            height: 20px;
            margin-top: 14px;
            margin-bottom: 10px;

            polygon {
                fill: @c-txt-k;
            }
        }
    }

    &__decision {
        height: 50px;
        width: 50px;
        margin: 20px auto 0;
        font-size: 28px;
        line-height: 50px;
        color: @c-warning;
        background-color: @c-white;
        border-radius: 100%;
        border: 1px solid @c-border;
        cursor: default;

        svg {
            width: 21px;
            margin: 0 0 2px 0;

            @media (max-width: @break-m) {
                margin-bottom: 1px;
                width: 18px;
            }

            .cls-1 {
                fill: @c-green;
            }
        }

        @media (max-width: @break-m) {
            height: 40px;
            width: 40px;
            font-size: 20px;
            line-height: 40px;
        }
    }

	&--does-fall-through {
		& + .workflow-step {
			&--add:not(.workflow-step--is-current-step-group) {
				section {
					border-top-color: transparent !important;
				}
			}
		}

		& + .workflow-step--is-current-step-group {
			section {
				margin-top: -2px !important;
			}
		}
	}

    .workflow-user {
        display: flex;
        position: relative;
    }

    .proofer-role {
        margin-top: 17px;

        &__text {
            color: @c-txt-k;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            padding-bottom: 5px;
            width: 80px;
            position: absolute;
            right: 100px;

            &--highlight {
                color: @c-warning;
            }

            &--read-only {
                right: 50px;
            }

            @media (max-width: @break-2) {
              max-width: 190px;
            }

            @media (max-width: @break-6) {
              display: none;
            }
        }

        &__circle {
          display: inline;
          position: absolute;
          font-size: 12px;
          font-weight: bold;
          right: 60px;
          top: 15px;

          &--manageWorkflow {
            right: 100px;
          }

          @media (min-width: @break-6) {
            display: none;
          }
        }

        &__share {
            width: 35px;
            position: absolute;
            right: 50px;
            top: 6px;
            cursor: pointer;

            &__button {
                position: relative;
                height: 35px;
                width: 35px;
            }

            @keyframes small-pop {
                0% {
                    opacity: 0;
                    transform: scale(1);
                }
                40% {
                    opacity: .8;
                }
                100% {
                    opacity: 0;
                    transform: scale(2);
                }
            }

            .pop {
                pointer-events: none;
            }

            svg {
                fill: @c-grey-d;
                position: absolute;
                left: 0;
                top: 0;
                width: 35px;
                height: 35px;
            }

            &--on.proofer-role__share--animate .pop svg {
                animation: small-pop .7s;
            }

            &--on svg {
                fill: @c-green;
            }

            &--disabled {
                cursor: default;
            }

            @media (max-width: 400px) {
                right: 30px;
            }
        }

        &--read-only {
          cursor: default;
          right: 0;
        }
    }

    &__right {
        position: absolute;
        left: 100%;
        min-width: 120px;

        p {
            text-align: left;
            padding: 10px 15px;
            margin: 0;
        }
    }

    &__actions {
        position: relative;
        display: flex;
        justify-content: space-around;
        width: 80px;
        padding: 10px;

        &__delete-wrap,
        &__delete-wrap-margin {
            display: flex;

            &__delete {
                fill: @c-grey-icon;
                cursor: pointer;
                align-self: center;

                &:hover {
                    fill: @c-grey-xxd;
                }

                svg {
                    @media (max-width: @break-m) {
                        height: 16px;
                    }
                }

                &--info-pane {
                    position: absolute;
                    right: -29px;
                    top: 30px;
                    z-index: 1;
    
                    svg {
                        @media (max-width: @break-m) {
                            height: 20px;
                        }
                    }
                }
            }

            @media (max-width: @break-m) {
                margin-top: 3px;
                display: block;
            }
        }

        &__delete-wrap-first {
            display: block;
        }

        &__delete-wrap-margin {
            margin-top: 20px;

            @media (max-width: @break-m) {
                margin-top: 6px;
                display: block;
             }
        }

        &__number {
            font-size: 18px;
            line-height: 18px;
            color: @c-grey-icon;
        }

        &__move-step {
            cursor: pointer;

            svg {
                width: 20px;

                polygon {
                    fill: @c-grey-ld;
                }
            }

            &:hover svg polygon {
                fill: darken(@c-grey-ld, 5%);
            }

            &--up svg {
                transform: rotate(180deg);
            }

            &--hide svg {
                display: none;
            }
        }

        @media (max-width: @break-m) {
           display: block;
           right: -60px;
        }
    }
}

.workflow-tab {
    &__back-btn {
        margin-bottom: 40px;
    }
}
