/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/*
   Z Indexes
    - 449 SIDE (background)
    - 450 SIDE (line)
    - 451 (n/a)
    - 452 SHADOW
    - 453 HANDLE
    - 454 ICON
    - 455 TOOLTIP
    - 549 SIDE (background) (active)
    - 550 SIDE (line) (active)
    - 551 (n/a)
    - 552 SHADOW (active)
    - 553 HANDLE (active)
    - 554 ICON (active)
    - 555 TOOLTIP (active)
 */

@z-side-background: 449;
@z-side-line: 450;
@z-tooltip: 455;
@z-shadow: 452;
@z-handle: 453;
@z-icon: 454;

@z-active-side-background: 100 + @z-side-background;
@z-active-side-line: 100 + @z-side-line;
@z-active-tooltip: 100 + @z-tooltip;
@z-active-shadow: 100 + @z-shadow;
@z-active-handle: 100 + @z-handle;
@z-active-icon: 100 + @z-icon;

&__pin {
    @selected-duration: 2s;
    @pin-transform: translateX(-50%) translateY(-50%);

    &__icon,
    &__shadow,
    &__handle,
    &__side {
        position: absolute;
    }

    &__icon {
        position: absolute;
        display: block;
        width: 14px;
        height: 14px;
        border: 3px solid;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, .1);
        border-radius: 100%;
        margin: -7px 0 0 -7px;
        transition: width .25s @easing,
                    height .25s @easing,
                    margin .25s @easing,
                    background-color .25s @easing;
        z-index: @z-icon;

        .app__pin--selected & {
            width: 20px;
            height: 20px;
            margin: -10px 0 0 -10px;

            @keyframes pinSelected {
                0%   { transform: scale(1) }
                25%  { transform: scale(2) }
                50%  { transform: scale(1) }
                75%  { transform: scale(2) }
                100% { transform: scale(1) }
            }

            animation-iteration-count: 1;
            animation-duration: @selected-duration;
            animation-name: pinSelected;
            animation-fill-mode: forwards;
        }

        .app__pin--can-select & {
            cursor: pointer;
        }

        .app__pin--can-update & {
            cursor: move;
        }

        .app__pin--active &,
        .app__pin--selected:hover & {
            z-index: @z-active-icon;
        }
    }

    &__shadow {
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        background-color: rgba(0, 0, 0, .25);
        margin-top: -20px;
        margin-left: -20px;
        transform: scale(0);
        border-radius: 100%;
        z-index: @z-shadow;

        .app__pin--selected & {
            @keyframes pinSelectedShadow {
                0%   { transform: scale(0) }
                50%  { transform: scale(1.25) }
                100% { transform: scale(0) }
            }

            animation-iteration-count: 1;
            animation-duration: @selected-duration;
            animation-name: pinSelectedShadow;
            animation-fill-mode: forwards;
        }

        .app__pin--active &,
        .app__pin--selected:hover & {
            z-index: @z-active-shadow;
        }
    }

    &__side {
        &:before,
        &:after {
            content: ' ';
            position: absolute;
        }

        &:before {
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            z-index: @z-side-background;
        }

        &:after {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: @z-side-line;
        }

        &--top,
        &--bottom {
            height: 4px;
        }

        &--left,
        &--right {
            width: 4px;
        }

        .app__pin--active &,
        .app__pin--selected:hover & {
            &:before {
                z-index: @z-active-side-background;
            }
            &:after {
                z-index: @z-active-side-line;
            }
        }
    }

    &__handle {
        position: absolute;
        display: none;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        border: 2px solid;
        transform: translateX(-50%) translateY(-50%);
        z-index: @z-handle;

        .app__pin--active &,
        .app__pin--selected:hover & {
            z-index: @z-active-handle;
        }

        .app__pin--selected.app__pin--can-update & {
            display: block !important;
        }
    }

    &__tooltip {
        position: absolute;
        background: @c-white;
        padding: 10px;
        border-radius: 2px;
        text-align: left;
        margin: 20px 0 0 -16px;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, .1);
        transition: transform .25s @easing,
                    opacity .25s @easing;
        transform-origin: 16px -10px;
        pointer-events: none;
        max-width: 300px;
        z-index: @z-tooltip;

        .app__pin--selected & {
            background-color: @c-yellow;
        }

        .app__pin:not(.app__pin--hover) &,
        .app__pin.app__pin--moving & {
            transform: scale(0);
            opacity: 0;
        }

        .app__pin--active &,
        .app__pin--selected:hover & {
            z-index: @z-active-tooltip;
        }

        &:before {
            content: 'Z';
            font-family: 'ArrrowsRegular';
            font-weight: normal;
            display: block;
            position: absolute;
            top: -17px;
            left: 0;
            bottom: auto;
            right: auto;
            line-height: 1;
            width: 32px;
            text-align: center;
            color: #fffde9;
            font-size: 30px;
        }

        > h1,
        > p {
            display: block;
            margin: 0;
            font-size: 12px;
            line-height: 1.4;
        }

        > h1 {
            font-weight: bold;
        }

        > p {
            color: #999;
        }

        @media (max-width: @break-m) {
            display: none;
        }
    }

    .avatar {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    &__overlay {
        position: absolute;
        opacity: .25;
        //pointer-events: none;
    }

    &__cursor-handler__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        z-index: 9999;
    }

    // Highlight colors below

    &--highlight {
        &--yellow {
            .app__pin__overlay {
                background-color: @c-yellow;
                opacity: .5;
            }
        }

        &--green {
            .app__pin__overlay {
                background-color: @c-green;
            }
        }
    }

    // Colors below

    &__icon {
        border-color: @c-white;
        background-color: @c-white;
    }

    &__handle {
        background-color: @c-white;
        border-color: @c-white;
    }

    &__side {
        border: 1px solid @c-white;
        background-color: @c-white;
    }
}
