/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/*

	.LESS include - Proof Info

*/

#proof-info,
.info-pane {
	.inDrop( @strength:0.1, @blur:0, @col:0, @x:1, @y:0, @spread:0 );
		.contents {

		@thumb-w: 150px;

		.fill;
		height: ~"calc(100% - 100px);";
		.scrollable;

		h4 {
			font-weight: normal;
			overflow-wrap: break-word;
		}
		h5 {
			font-size: 20px;
			margin: 0 0 @gap/2;
		}
		.wf-alert {
			background: @c-danger;
			padding: @gap;
			color: @c-white;
			.round(@r*2);
			margin: 0 0 @gap*2;
			.rel;

			&:after {
				.bottomArrow;
				.lft;
				color: @c-danger;
				padding-left: @thumb-w / 2 - 12;
			}

			h3 {
				color: @c-white;
				margin: 0;
				.upper;
				.bold;
				font-size: 20px;
			}

			p {
				margin: 0;
			}
		}

		.meta {
			display: flex;
			flex-direction: row;
			padding: 20px 0;

			.app__proof-thumbnail {
				margin: 0;
				text-align: center;
			}

			h5 {
				margin-bottom: @gap;
				font-size: 16px;
			}

			.alert {
				color: @c-danger;
			}

			.info {
				display: flex;
				flex-direction: column;
				padding-left: 30px;
				padding-top: 10px;
				flex-grow: 1;
				font-size: 16px;

				@media (max-width: @break-m) {
					font-size: 12px;
				}

				&__item {
					margin-bottom: 10px;

					&--date {
						fieldset {
							.form-control {
								margin-bottom: 0;

								&__date-time-picker-holder {
									position: absolute;
									width: 25px;
									height: 25px;
									right: 0;
									bottom: 10px;
								}
							}

							textarea {
								font-size: 16px;
								padding: 0 26px 0 0;
								text-align: left;
								height: auto;
								vertical-align: bottom;

								@media (max-width: @break-m) {
									font-size: 12px;
								}
							}

							@media (max-width: @break-m) {
								padding-left: 0;
								padding-right: 0;
							}
						}

					}

					&--pill {
						margin-top: 10px;

						.tag {
							text-align: center;

							@media (max-width: @break-m) {
								font-size: 10px;
							}
						}
					}
				}
			}

			textarea {
				font-size: 18px;
				padding-bottom: @gap/4;
				.cen;
			}

			.dashboard__proof__progress {
				position: absolute;
				bottom: 40px;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.msg-error {
			position: static;
			margin: @gap/2 0 0 0;
			.cen;
			width: 100%;
		}

		.tags {
			padding: 10px 25px;

			.tag {
				margin: 0 @gap/2 - 4 @gap/2 0;
			}

			.auto-tags .tag-output {
				border-bottom: 1px solid @c-border;
				padding: 0;
				min-height: 26px;
				margin-bottom: -@gap;

				input {
					margin: 0;
					font-size: 12px;
					.upper;
					.bold;
					height: 26px;
					.rel;
					top: -5px;
				}
			}
		}

		.message-box {
			position: relative;
			width: 100%;
			color: @c-white;

			fieldset {
				padding: @gap;
				max-width: none;
				.cen;
				background: @c-yellow;
				.round(2);

				textarea {
					margin-top: 0px;
					background: @c-white;
					border: @border;
					padding: @gap;
					font-size: 14px;
					&:focus {
						border-color: darken(@c-border, 20%);
					}
				}
			}

			&-reviewer {
				fieldset {
					padding-right: 5px;

					textarea {
						max-width: 94%;

						@media (max-width: @break-2) {
							max-width: 90%;
						}
					}
				}
			}

			&__heading {
				text-align: left;
				font-size: 14px;
			}

			&__save {
				position: relative;
				width: 24px;
				border: none;
				background: transparent;
				vertical-align: bottom;
				padding: 0;

				svg {
					width: 24px;
					height: 24px;
					fill: #aaa;
				}

				&-active {
					svg {
						fill: @c-green;
					}
				}
			}
		}

		.message-read-only {
			position: relative;
			width: 100%;
			height: auto;
			background-color: @c-yellow;
			margin: auto;
			padding: 20px;
			margin-bottom: 20px;
			color: @c-txt;

			p {
				margin: 0 0 0 0;
				white-space: pre-wrap;

				&:first-of-type {
					padding-bottom: 10px;
				}
			}

		}

		.message-dots {
			position: absolute;
			top: 20px;
			right: 20px;
		}

		.decisionSummaryContainer {
			display: flex;
			padding: 10px;
			justify-content: center;
		}

		.workflowOptionsContainer {
			display: flex;
			padding: 10px;
			justify-content: center;
			align-items: center;
		}

		.workflow {
			padding-bottom: @gap*2;

			h5 {
				.cen;
			}

			&__title {
				margin: 10px 0;
				font-size: 14px;
				color: @c-txt-m;
			}

			&__spinner {
				padding-top: 40px;
				padding-bottom: 40px;
				position: relative;
			}

			.workflow-structure {
				z-index: 10;
				padding: 0;

				.locker {
					width: 10px;
					height: 15px;
					margin: auto;
					margin-top: 9px;
					opacity: 0.5;
					background-image: url("../../img/interface/padlock-grey.png");
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;

					&.extra-margin {
						margin-top: 19px;
					}

					&.status-d {
						margin-top: 19px;
					}
				}

				.locker-fa {
					width: 10px;
					height: 15px;
					margin: auto;
					margin-top: 30px;
					opacity: 0.5;
					background-image: url("../../img/interface/padlock-grey.png");
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;

					&.extra-margin {
						margin-top: 27px;
					}

				}

				.avatar {
					&:hover, &:active {
						.locker {
							opacity: 0;
						}
					}
				}

				.wf-decision-summary {
					margin-bottom: 20px;
					display: inline-block;
				}

				.wf-status {
					.cen;
					.sml-txt;
					color: @c-txt-k;
					margin-bottom: 20px;
					display: inline-block;

					span {
						@s: 6px;
						.circ(@s);
						.in-block;
						margin: 0 @gap/4 0 @gap/2;
						.rel;
						top: -1px;
						&:first-child {
							margin: 0 @gap/4 0 0;
						}
						&.dot-g {
							background: @c-green;
						}
						&.dot-d {
							background: @c-danger;
						}
						&.dot-g-hollow {
							border: 1px solid @c-green;
						}
					}

					div {
						display: inline-block;
					}
				}

				.wf-step {
					.transition(250ms);
					border-color: @c-txt-k;

					&:hover {
						opacity: 1;
					}

					.avatar {
						margin: 0 @gap/2 @gap/2 @gap/2;

						&:hover,
						&:active {
							.approver-label {
								opacity: 0;
							}
						}

					}

					.btn-add {
						.dim(@gap*2, @gap*2);
						padding: 0 0 2px 0;
						font-size: 20px;
						margin: 0 @gap/2 @gap/2 @gap/2;
					}

					fieldset {
						max-width: 320px;
					}

					&.less-margin {
						margin-top: 15px !important;
					}

					&.final-wf-step {
						background-color: @c-white;
						border-radius: 2px;
						border: 1px solid @c-txt-k;
						padding-top: 10px;
						padding-bottom: 10px;
					}

					&.final-wf-step-current {
						background-color: @c-white;
						border-radius: 4px;
						padding-top: 10px;
						padding-bottom: 10px;
						border: 2px solid @c-green;
						.wf-step-proofers {
							padding: 0;
						}
					}

					&.final-wf-step-complete {
						background-color: @c-white;
						border-radius: 2px;
						border: 1px solid @c-txt-k;
						padding-top: 10px;
						padding-bottom: 10px;

						.wf-step-proofers {
							padding: 0;
						}

						&:hover {
							opacity: 1;
						}

					}

					&.proof-step-current {
						border: 2px solid @c-green;
					}

					&.non-editable {
						.ava {
							cursor: default !important;
						}
					}
				}

				.new-wf-step {
					padding: @gap/2;
					p {
						margin: 0;
						color: @c-txt-m;
						font-size: 12px;
					}
				}

				.wf-step-title {
					font-size: 18px;
				}

				.workflow-slide-reveal {
					font-size: 10px;

					&__roles {
						width: 60%;
					}

					&__actions {
						width: 40%;
					}

					&-arrow {
						right: 30px;
					}

					@media (max-width: @break-m) {
						&__roles,
						&__actions {
							width: 50%;
						}
					}

					&__title {
						font-size: 12px;
					}

					&__message {
						padding: 10px;
					}

					&--workflow-details {
						margin-left: 25px;
						margin-right: 25px;
						margin-bottom: 20px;
					}
				}
			}
			&.workflow.non-editable {
				border-bottom: none;

				.ava {
					cursor: default !important;
				}

				.workflow-structure {
					.wf-step-title {
						border: none;
						pointer-events: none;
					}

					.wf-step-proofers {
						padding-top: @gap/2;
					}

					.final-wf-step {
						.wf-step-proofers {
							padding-top: 0;
						}
					}
				}

                .proofer-role {
                    &__text {
                        right: 10px;
                    }

                    &__share {
                        right: -20px;
                    }
                }
            }
        }

        .workflow-user {
            display: flex;
            position: relative;
            width: 100%;

            .proofer-avatar {
                align-self: flex-start;
                width: 40px;
            }
        }

        .step-inner {
            .slide-reveal {
                width: 110%;
                margin-left: -5%;

                @media (min-width: @break-6) and (max-width: @break-m) {
                    width: 105%;
                    margin-left: -2%;
                }
            }
        }

        .step-inner {
            @media (max-width: @break-m) {
                padding-left: 20px;
            }
        }

        .show-more--reviewer {
            right: -20px;
        }

        .proofer-role {
            &__text {
                font-size: 10px;
                right: 50px;
                width: 70px;

                @media (max-width: @break-2) {
                  max-width: 190px;
                }

                @media (min-width: @break-m) and (max-width: @break-3) {
                  display: none;
                }
            }

            &__circle {
              &--readOnly {
                right: 30px;
              }

              @media (min-width: @break-m) and (max-width: @break-3) {
                display: inline;
              }
            }

            &__share {
                right: 15px;
            }

            @media (max-width: @break-2) {
                margin-left: 20px;
            }
        }

        .meta.admin-dashboard{
            margin-bottom: 34px;

            .info {
                display: flex;
                flex-direction: column;

                &__name {
                    text-transform: capitalize;
				}

                &__email {
                    font-size: 24px;
                    text-transform: lowercase;
                }
            }
        }

        .proofer-email-field {
            max-width: 358px;
            width: 100%;
            margin-left: 0;
            font-size: 16px;

            @media (max-width: 370px) {
                margin-left: -10%;
                width: 120%;
            }
        }

        .proofer-email {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 240px;
            height: 45px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 0;
            padding-left: 0;
            font-size: 14px;

            &--readOnly {
              margin-right: 40px;
            }

            &:first-child span,
            .proofer-email-text {
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: lowercase;
            }

            .proofer-status {
                font-size: 10px;
                text-transform: uppercase;

                @media (max-width: @break-m) {
                    display: none;
                }
            }
       }

		.proof-actions {
			.btn, .btn-sub {

				@media (min-width: @break-m) and (max-width: 848px) {
					display: block;
					width: 100%;
					margin: 0 0 10px 0;
					padding: 15px 0;
				}
			}
		}
		.proof-actions {
			margin-top: 20px;

			@t: 200ms;
			//min-height: 400px;
			nav {
				.transition(@t);
				overflow: hidden;

				.btn {

					@media (max-width: 847px) {
						display: block;
						width: 100%;
						margin: 0 0 10px 0;
						padding: 15px 0;
					}
				}
			}
		}
	}

	.proof-info-error-message {
		text-align: center;
		padding: 40px;

		h4 {
			font-size: 24px;
			color: @c-txt-m;
			font-weight: normal;
		}

		p {
			color: @c-txt-m;
		}
	}

	.additional-owners {
		width: 60px;
		height: 60px;
		margin: 0 10px 25px 10px;
		border-radius: 100%;
		font-size: 22px;
		display: inline-block;
		line-height: 60px;
		color: @c-txt-m;
		background-color: @c-grey;
		cursor: pointer;

		@media (max-width: @break-m) {
			height: 40px;
			width: 40px;
			font-size: 16px;
			line-height: 40px;
		}
	}

	.single-owner .name {
		opacity: 1;
		bottom: 0;
		transform: translateX(-50%);
	}

	@media (max-width: @break-m) {
		.head {
			height: 67px;
			padding: 0 20px;

			h2 {
				line-height: 67px;
				font-size: 22px;
			}
		}
	}
}

.app__proof-info__reference {
	fieldset {
		.form-control {
			margin-bottom: 0;

			textarea {
				position: relative;
				height: 30px;
				padding: 0 20px 0 90px;
				font-size: 16px;
				color: @c-txt;
			}

			label {
				position: absolute;
				left: 0;
				width: 80px;
				top: 0;
				color: @c-txt-l;
			}
		}

		@media (max-width: @break-m) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.proof-info {
	&__title,
	&__title textarea,
	&__title input {
		text-align: center;
		font-size: 22px;
		width: 100%;

		@media (max-width: @break-m) {
			font-size: 18px;
		}

		.form-control {
			margin-bottom: 10px;
		}

		&--readonly {
			margin-bottom: 10px;
		}
	}

	&__proof-status {
		text-align: center;
		font-size: 18px;
		margin-bottom: 20px;
		color: @c-green;

		@media (max-width: @break-m) {
			font-size: 14px;
		}
	}

	&__extension {
		text-align: center;

		.sml-txt;
		margin-top: 10px;
	}

	&__meta,
	&__users {
		padding: 30px;
		border-left: @border;
	}

	&__meta {
		position: relative;
		background-color: #ececec; //only 3 places with this colour
	}

	&__dates-button {
		border-radius: 20px;
		height: 20px;
		width: 30px;
		padding-left: 5px;
		padding-right: 5px;
		margin-left: auto;
		margin-right: auto;
		cursor: pointer;
		background-color: @c-grey-ld;

		&:hover {
			background-color: darken(@c-grey-ld, 5%);
		}

		svg {
			height: 20px;
			width: 20px;
			fill: rgba(0, 0, 0, 0.5);
		}
	}

	&__dates-arrow {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 10px);
		opacity: 1;
		content: ' ';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 7px 10px 7px;
		border-color: transparent transparent @c-grey-ld transparent;
		transition: opacity .2s ease-in-out;

		&:not(&--show) {
			opacity: 0;
		}
	}

	&__dates-slide-reveal {
		text-align: center;
		max-width: 250px;
		margin: 20px auto;
		background-color: @c-grey-ld;
		color: @c-grey-xxd;
		padding: 20px;
		border-radius: 4px;
		font-size: 10px;
	}

	&__users {
		background-color: @c-grey-ll;

		&__owners {
			.rel;
			padding: 0 0 @gap;
			margin-bottom: @gap;
			border-bottom: @border;
		}
	}

	&__owners {
		&__add-btns {

			button {
				font-size: 12px;
				padding: 8px 15px;
				margin: 20px 10px 0;
			}
		}
	}

	&__heading {
		text-align: center;
		margin-bottom: 20px;
		font-size: 18px;

		@media (max-width: @break-m) {
			font-size: 14px;
		}
	}

	&__vertical-line {
		width: 1px;
		height: 20px;
		background-color: @c-txt-m;
		margin: 6px auto;
	}

	// TODO - These rules were added because the (+X) owners button alignment was strange, and was different on editable/read-only proof info pane despite having the same classes.
	&:not(&--read-only) .additional-owners {
		transform: translateY(-3px);

		@media (max-width: @break-4) and (min-width: @break-m) {
			transform: translateY(0);
		}
	}

	&--read-only .additional-owners {

		@media (max-width: @break-m) {
			transform: translateY(10px);
		}
	}
}
