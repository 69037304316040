/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.NavigationPanel {
    width: 250px;
    height: 100%;
    float: left;
    border-right: 1px solid @c-border;
    position: fixed;
    left: 0;
    top: 100px;
    z-index: 2;
    // This colour matches the proof info pane (black at 0.05 opacity)
    background-color: #ebebeb;
    user-select: none;

    &__inner {
        position: relative;
        left: 0;
        bottom: 0;
        right: 0;
        overflow-y: auto;
        overflow-x: hidden;
    }

    @media (max-width: @break-m) {
        display: none;
    }

    &--grey {
        // This colour matches the proof info pane (black at 0.05 opacity)
        background-color: #ebebeb;

        .NavigationPanel {
            &__heading {
                color: #555;

                &__close {
                    color: #999;
                }
            }

            &__separator {
                border-bottom-color: @c-border;
            }
        }
    }

    &--green {
        background-color: @c-green;

        .NavigationPanel {
            &__heading {
                color: @c-white;
            }

            &__separator {
                border-bottom-color: rgba(255, 255, 255, 0.2);
            }
        }

        html.is-dark-mode & {
            background-color: @c-green-xd;

            .NavigationPanel__separator {
                border-bottom-color: rgba(255, 255, 255, 0.2);
            }
        }
    }

    &__tabs {
        display: inline-block;
        width: 100%;
        margin-left: 0;
        margin-bottom: 0;
        opacity: 1;
        transition: all .3s ease-in-out;
    }

    &__heading {
        position: relative;
        text-align: left;
        padding: 15px;
        line-height: 30px;

        .ToggleView {
            position: absolute;
            right: 8px;
            display: inline-flex;
            align-items: center;
            font-size: 0;
        }

        .ManageMenu {
            svg {
                width: 25px;
                height: 25px;
            }
        }

        // The css inside of this is trash. It's a hack for the old dashboard to work with translations
        &__close {
            overflow: hidden;
            height: 30px;

            &--text {
                padding-top: 2px;
                cursor: pointer;
                font-size: 10px;
                text-transform: uppercase;
                font-weight: bold;
            }
            
            &--icon {
                cursor: pointer;
                width: 30px;
                margin-right: -4px;
                float: right;
                padding: 8px;
                fill: #757575;

                svg {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }
    }

    &__separator {
        border-bottom: 2px solid;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 20px;
    }

    &__static {
        padding-top: 20px;
    }

    &__arrow {
        position: absolute;
        right: 4px;
        width: 32px;
        height: 32px;
        transform: rotate(0deg);
        transform-origin: 50% 45%;
        transition: transform .3s ease-out;

        svg {
            fill: @c-txt-m;
        }
    }

    &__collection {
        overflow-x: hidden;
        margin-bottom: 10px;

        .NavigationPanel__heading {
            cursor: pointer;
        }

        &__hidden {
            overflow-x: hidden;
            overflow-y: hidden;
            box-shadow: none;
            margin-bottom: 0;

            .NavigationPanel {
                &__separator {
                    display: block;
                }

                &__arrow {
                    transform: rotate(180deg);
                }

                &__tabs,
                &__tabButton,
                &__manageBox,
                &__collection__tabs,
                &__tabs-group{
                    display: none;
                }
            }
        }

        &__tabs {
            font-size: 0;

            &__hidden {
                .NavigationPanel {
                    &__tabs {
                        display: none;
                    }

                    &__tabs-group {
                        display: inline-block;
                        opacity: 1;
                        transition: none;
                    }
                }
            }
        }

        &--green {
            background-color: @c-green;

            .NavigationPanel {
                &__tabButton {
                    color: @c-white;
                    &:hover {
                        color: @c-white;
                        opacity: .9;
                    }
                }

                &__arrow {
                    svg {
                        fill: @c-white;
                    }
                    &:hover svg {
                        fill: @c-white;
                        opacity: .7;
                    }
                }
            }

            html.is-dark-mode & {
                background-color: @c-green-xd;
            }
        }
    }

    &__new-user {
        .NavigationPanel {
            &__arrow {
                transform: rotate(0deg);
            }
        }
    }
}
