/* Copyright (C) PageProof Holdings limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * September 2014
 */

/*

	.LESS include - Common mixins

*/

/* VARS ================================================================================================================= */

@border 		: 1px solid @c-border;
@dBorder 		: 1px dotted @c-border;
.sBorder		{ .inDrop( @strength:0.5, @blur:1, @col:0, @x:0, @y:0, @spread:0 ); }

@gl-times		: '\00d7';
.arrApp			{ content:'h'; .arr; text-transform: none; display: inline-block; .weight(400); }
.xApp			{ content:@gl-times; text-transform: none; display: inline-block; .weight(400); }
.outline		{ background: rgba(0,0,0,0.05); }
.outline2		{ background: rgba(0,0,0,0.1); }
.fill			{ width: 100%; height: 100%; }
.scrollable		{ overflow: auto; -webkit-overflow-scrolling: touch; }

@gap			: 20px;
@r				: 1px;

/* ROUNDING / DROPS / GRADIENTS ========================================================================================= */
.round( @radius: @r )							{ -webkit-border-radius: 0px + @radius; -moz-border-radius: 0px + @radius; border-radius: 0px + @radius; }
.roundSome( @tl: 3, @tr: 3, @bl: 3, @br: 3 )
{
	-webkit-border-top-left-radius				: 0px +  @tl;
	-webkit-border-top-right-radius				: 0px +  @tr;
	-webkit-border-bottom-right-radius			: 0px +  @br;
	-webkit-border-bottom-left-radius			: 0px +  @bl;
	-moz-border-radius-topleft					: 0px +  @tl;
	-moz-border-radius-topright					: 0px +  @tr;
	-moz-border-radius-bottomright				: 0px +  @br;
	-moz-border-radius-bottomleft				: 0px +  @bl;
	border-top-left-radius						: 0px +  @tl;
	border-top-right-radius						: 0px +  @tr;
	border-bottom-right-radius					: 0px +  @br;
	border-bottom-left-radius					: 0px +  @bl;
}

.gradient ( @origin: top, @bgColor: #ff0000, @start: rgba(255,255,255,0.2), @stop: rgba(0,0,0,0.1) )
{
	background-color: @bgColor;
	background-image: -webkit-linear-gradient(@origin, @start, @stop);
	background-image: -moz-linear-gradient(@origin, @start, @stop);
	background-image: -o-linear-gradient(@origin, @start, @stop);
	background-image: -ms-linear-gradient(@origin, @start, @stop);
	background-image: linear-gradient(@origin, @start, @stop);
}

.drop( @strength:0.3, @blur:5, @col:0, @x:0, @y:0, @spread:0 )
{
	box-shadow			:  0px + @x  0px + @y  0px + @blur -1px + @spread rgba(@col,@col,@col, @strength);
	-moz-box-shadow		:  0px + @x  0px + @y  0px + @blur -1px + @spread rgba(@col,@col,@col, @strength);
	-webkit-box-shadow	:  0px + @x  0px + @y  0px + @blur  0px + @spread rgba(@col,@col,@col, @strength);
}
.inDrop( @strength:0.3, @blur:5, @col:0, @x:0, @y:0, @spread:0 )
{
	box-shadow			: inset 0px + @x  0px + @y  0px + @blur @spread rgba(@col,@col,@col, @strength);
	-moz-box-shadow		: inset 0px + @x  0px + @y  0px + @blur @spread rgba(@col,@col,@col, @strength);
	-webkit-box-shadow	: inset 0px + @x  0px + @y  0px + @blur @spread rgba(@col,@col,@col, @strength);
	-ms-box-shadow		: inset 0px + @x  0px + @y  0px + @blur @spread rgba(@col,@col,@col, @strength);
}
.no-drop						{ box-shadow: none; -moz-box-shadow: none; -webkit-box-shadow: none; }

/* CSS TRANSFORMS / TRANSITIONS ========================================================================================= */

@easing: ease-in-out;	// CSS Easing Equation customisation at http://matthewlein.com/ceaser/

.transition( @time )			{
	-webkit-transition			: all @time @easing;
	-moz-transition				: all @time @easing; 
	-ms-transition				: all @time @easing; 
	transition					: all @time @easing;
}
.transform( @params )			{
	-webkit-transform			: @params;
	-moz-transform				: @params;
	-ms-transform				: @params;
	transform					: @params;
}
.delay( @time )					{
	-webkit-transition-delay	: @time;
	-moz-transition-delay		: @time; 
	-ms-transition-delay		: @time; 
	transition-delay			: @time;
}
.origin( @x: 50%, @y: 50% )		{
	-webkit-transform-origin	: @x @y;
	-moz-transform-origin		: @x @y;
	-ms-transform-origin		: @x @y;
	transform-origin			: @x @y;
}
.translate-X( @val ) 				{
	.transform( translateX( @val ));
}

/* ELEMENT DISPLAY & POSITIONING ======================================================================================== */
.block											{ display: block; }
.inline											{ display: inline; }
.in-block										{ display: inline-block; }
.none											{ display: none; }
.dim( @width, @height )							{ width: 0px + @width; height: 0px + @height; }
.dim2x( @width, @height )						{ width: 0px + (@width/2); height: 0px + (@height/2); }
.abs( @t: auto, @l: auto, @b: auto, @r: auto ) 	{ position: absolute; top: @t; left: @l; bottom: @b; right: @r; }
.fix( @t: auto, @l: auto, @b: auto, @r: auto ) 	{ position: fixed; top: @t; left: @l; bottom: @b; right: @r; }
.rel											{ position: relative; }

.absBox( @t: auto, @l: auto, @b: auto, @r: auto, @w, @h ) 	{ 
	
	position: absolute; top: @t; left: @l; bottom: @b; right: @r; 
	margin: -@h/2 0 0 -@w/2; .block; width: @w; height: @h;
	
}

/* TEXT / TYPOGRAPHY ==================================================================================================== */
.bold											{ font-weight: bold; }
.upper											{ text-transform: uppercase; }
.lower											{ text-transform: none; }
.cen											{ text-align: center; }
.lft											{ text-align: left; }
.rgt											{ text-align: right; }
.weight( @weight: 400 )							{ font-weight: @weight; }
.txtEmboss( @strength: 0.2 )					{ text-shadow: rgba(255,255,255,@strength) 0px 1px 0px; }
.txtDeboss( @strength: 0.2 )					{ text-shadow: rgba(0,0,0,@strength) 0px -1px 0px; }
.colText( @cols: 3, @gap: 20, @rule: 1px solid #ccc )
{
	-moz-column-count		: @cols;
	-moz-column-gap			: 0px + @gap;
	-webkit-column-count	: @cols;
	-webkit-column-gap		: 0px + @gap;
	column-count			: @cols;
	column-gap				: 0px + @gap;
	-webkit-column-rule		: @rule;
	-moz-column-rule		: @rule;
	column-rule				: @rule;
}

/* SPRITES ============================================================================================================== */
@spritePath			: '../../img/interface/sprite.png?5';
@spriteGrid			: 60px;
@spritePath2x		: '../../img/interface/@2x/sprite.png?5';
@spriteGrid2x		: 120px;
.sprite( @x: 0, @y: 0, @url: @spritePath ) 
{
	background-image: url(@url);
	background-repeat: no-repeat;
	background-position: -(@x*@spriteGrid) -(@y*@spriteGrid);
	
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
		background-image: url(@spritePath2x);
		background-position: -(@x*@spriteGrid) -(@y*@spriteGrid);
		background-size: 600px 600px;
	}
	
}

/* COLUMNS ============================================================================================================== */
.cols( @per-row: 2, @m: 5%)	{
	
	float: left; width: (100% - (@m * (@per-row - 1))) / @per-row; margin: 0 0 @m @m;
	&:nth-child(@{per-row}n + 1) { margin-left: 0; }
	
}

/* GRID ================================================================================================================= */
.grid-4( @m: @gap )				{
	.block; float: left; width: (100% / 4);
	&:nth-child(4n+1)			{ padding: 0 @m*.75 0 0;  }
	&:nth-child(4n+2)			{ padding: 0 @m*.5 0 @m*.25;  }
	&:nth-child(4n+3)			{ padding: 0 @m*.25 0 @m*.5;  }
	&:nth-child(4n+4)			{ padding: 0 0 0 @m*.75;  }
}
.grid-2( @m: @gap )				{
	.block; float: left; width: (100% / 2);
	&:nth-child(2n+1)			{ padding: 0 @m*.5 0 0;  }
	&:nth-child(2n+2)			{ padding: 0 0 0 @m*.5;  }
}
.grid-3( @m: @gap )				{
	.block; float: left; width: (100% / 3);
	&:nth-child(3n+1)			{ padding: 0 @m*.75 0 0;  }
	&:nth-child(3n+2)			{ padding: 0 @m*.5 0 @m*.5;  }
	&:nth-child(3n+3)			{ padding: 0 0 0 @m*.75;  }
}

.clearfix () {
	&:before,
	&:after {
		display: table;
		content: ' ';
	}
	&:after {
		clear: both;
	}
}

.invisible() {
	width: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	position: absolute;
	top: -100%;
	left: -100%;
	pointer-events: none;
}

.invisible {
	.invisible();
}

/* MISC USEFUL MIXINS =================================================================================================== */
.no-bullets					{ list-style-type: none; margin: 0; }
.circ( @dim )				{
	.block; .dim(@dim, @dim); .round(@dim); overflow: hidden;
}
.greyscale( @v:1 )			{
	
	-webkit-filter: grayscale(@v);
	-moz-filter: grayscale(@v);
	filter: grayscale(@v);
	filter: grayscale(@v);
	-webkit-transform: translateZ(0);

}

.lang-notouch				{ display: none; }
.no-touch					{
	.lang-notouch			{ display: inline; }
	.lang-touch				{ display: none; }
}

.no-select 					{
	-webkit-touch-callout	: none;
	-webkit-user-select		: none;
	-khtml-user-select		: none;
	-moz-user-select		: none;
	-ms-user-select			: none;
	user-select 			: none;
}

.bottomArrow				{ content:'z'; .arr; text-transform: none; font-size: 30px; line-height: 1; width: 100%; .abs(@b: -17px, @l: 0); .cen; }
.topArrow					{ content:'Z'; .arr; text-transform: none; font-size: 30px; line-height: 1; width: 100%; .abs(@t: -19px, @l: 0); .cen; }
.hwAccel					{
	
	-webkit-transform				: translate3d(0, 0, 0);
	-moz-transform					: translate3d(0, 0, 0);
	-ms-transform					: translate3d(0, 0, 0);
	transform						: translate3d(0, 0, 0);
	
	-webkit-backface-visibility 	: hidden;
	-moz-backface-visibility 		: hidden;
	backface-visibility 			: hidden;
	
}

