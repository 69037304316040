/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
@icon-size: 60px;

&__tools {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    z-index: 500;
    user-select: none;
    min-height: 180px;

    &-right {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .app__tool--open > .app__tool__icon,
        .app__tool--open > .app__tool__label {
            transform: translateX(-150%);
            display: none;
        }

        .app__tool--open > .app__tool__options {
            transform: translateX(-30px);
        }
    }

    .app__tool--open {
        .app__tool__label {
            opacity: 0;
        }
    }

    @media (max-width: @break-m) {
        display: none;
    }

    &--mobile {
        min-height: 42px;
        height: 42px;
        display: inline-flex;
        position: relative;
        left: 0;
        transform: unset;

        @media (min-width: @break-m) {
            display: none;
        }

        &__pen {
            margin: 0 5px;
        }

        .app__tool {
            margin: 0 5px;
            width: 40px;
            height: 40px;
            position: relative;

            &__icon {
                margin: -9px 0 0 -11px;
                transform: scale(0.7);

                &:active {
                    transform: scale(0.75);
                }
            }

            &__options {
                top: -68px;
                left: 0px;
                transform: scale(0.7) translateX(-25%);
            }

            &--open .app__tool__icon {
                transform: translateX(0) scale(0.75);
            }

            &.app__tools--magic-menu {
                ul {
                    li {
                        div {
                            min-width: 54px;
                        }
                    }
                }

                &:not(.app__tool--open) .app__tool__options {
                    transform: translateX(-400px) scale(0.7);
                }
            }

            &__option  {
                button {
                    line-height: 45px;
                    background: transparent;
                    border: none;
                    outline: none;
                }

                .rotate svg {
                    transform: unset;
                }

                .zoom-in,
                .zoom-out,
                .rotate,
                .marquee-zoom,
                .glasses,
                .text-selection {
                    margin-top: -4px;
                }
            }

            &__spinner {
                transform: scale(0.7) translateY(-10px);

                .spinner__outer {
                    position: absolute;
                    left: 50%;
                    top: 8px;
                    transform: translateX(-50%);
                }
            }
        }

        svg {
            fill: @c-txt-k;

            g {
                fill: @c-txt-k;
            }
        }
    }
}

&__tool {
    position: relative;
    margin: 20px 0;
    transform: translateZ(0);

    & ul li {
        display: inline-block;
    }

    &__icon {
        display: block;
        width: @icon-size;
        height: @icon-size;
        box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, .2);
        border-radius: @icon-size / 2;
        background-color: #fff;
        cursor: pointer;
        transition: transform .1s @easing,
                    background-color .25s @easing,
                    background-position .25s @easing,
                    box-shadow .25s @easing;

        &:active {
            transform: scale(0.9);
        }

        .app__tool:hover & {
            background-color: #e1e5e6;
            box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, .4);
        }

        .app__tool--open & {
            transform: translateX(-150%);
        }

        // Custom icons

        .app__tool--pin & {
            .sprite(1, 0);
        }

        .app__tool--selected.app__tool--pin & {
            .sprite(1, 1);
        }

        .app__tool--text & {
            .sprite(9, 4);
        }

        .app__tool--selected.app__tool--text & {
            .sprite(9, 5);
        }

        .app__tool--draw & {
            .sprite(8, 4);
        }

        .app__tool--selected.app__tool--draw & {
            .sprite(8, 5);
        }

        .app__tool--zoom & {
            .sprite(2, 0);
        }

        .app__tool--magic & {
            .sprite(4, 2);
        }

        .app__tool--page-grid & {
            background-image: url(/img/content/proof/icons/pages.png);
            background-repeat: no-repeat;
            background-size: 25px 25px;
            background-position: 50% 50%;

            @media (min-width: @break-m) {
                display: none;
            }
        }

        .app__tool--pen-crossed & {
            background-image: url(/img/icons/red-pen-unavailable.svg);
            background-repeat: no-repeat;
            background-size: 25px 25px;
            background-position: 50% 50%;
            opacity: .3;

            html.is-dark-mode & {
              background-image: url(/img/icons/red-pen-unavailable-dark-mode.svg);
            }
        }

        .app__tool--selected.app__tool--zoom & {
            .sprite(2, 1);
        }

        .app__tool--info & {
            .sprite(3, 0);
        }

        .app__tool--play & {
            // play icon
            .sprite(9, 2)
        }

        .app__tool--normal & {
            .sprite(5, 1)
        }

        .app__tool--slow & {
            .sprite(6, 3)
        }

        .app__tool--fast & {
            .sprite(6, 2)
        }

        .app__tool--selected.app__tool--play & {
            // pause icon
            .sprite(9, 3)
        }

        .app__tool--selected.app__tool--info & {
            .sprite(3, 1);
        }

        .app__tool--fullscreen & {
            .sprite(8, 3);
        }

        .app__tool--selected.app__tool--fullscreen & {
            .sprite(7, 1);
        }

        .app__tool--link & {
            .sprite(5, 2);
        }

        .app__tool--selected.app__tool--link & {
            .sprite(5, 3);
        }

        .app__tool--settings & {
            .sprite(5, 4);
        }
    }

    &--disabled {
        .app__tool__icon {
            cursor: inherit;
        }
    }

    &--play {
        @media (min-width: @break-m) {
            display: none;
        }
    }

    &--mobile {
        @media (min-width: (@break-half + 1)) {
            display: none;
        }
    }

    &__label {
        position: absolute;
        top: 0px;
        line-height: @icon-size;
        left: @icon-size + 15px;
        font-size: 10px;
        text-transform: uppercase;
        color: #999999;
        white-space: nowrap;
        pointer-events: none;
        transition: transform .25s @easing,
                    color .25s @easing,
                    opacity .25s @easing;

        .app__tool--hideLabel &,
        .app__tool:not(:hover) & {
            transform: translateX(50px);
            opacity: 0;
        }

        .app__tool--selected & {
            color: @c-danger;
        }

        &--grey {
            color: #999 !important;
        }

        @media (max-width: @break-m) {
            display: none;
        }

        &--dark {
            top: -15px;
            text-transform: inherit;
        }
    }

    &__option {
        display: inline-block;
        min-width: @icon-size;
        line-height: @icon-size;
        padding: 0 12.5px;
        margin: 0 4px;

        font-size: 14px;
        color: @c-txt-k;
        text-align: center;
        cursor: pointer;

        &:hover {
            color: @c-txt;
        }

        &--selected {
            font-weight: bold;
            color: @c-txt;
        }
    }

    &__options {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border-radius: @icon-size / 2;
        background-color: #fff;
        box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, .2);
        list-style: none;
        padding: 0 10px;
        margin: 0;
        font-size: 0;
        white-space: nowrap;
        transition: transform .25s @easing;

        .clearfix;

        .app__tool:not(.app__tool--open) & {
            transform: translateX(-150%);
        }

        .app__tool-right:not(.app__tool--open) & {
            display: none;
        }
    }

    .pen-tools {
        display: flex;

        &.primary-pen-tool-draw,
        &.primary-pen-tool-text {
            flex-direction: row-reverse;

            .pen-draw-icon svg,
            .pen-text-selection-icon svg {
                margin-left: 0;
                margin-right: -10px;
            }
        }
    }

    &__option {
        .zoom-level & {
            cursor: default;
        }

        .zoom-in,
        .zoom-out,
        .nudge-zoom-in,
        .nudge-zoom-out {
            display: inline-block;
            width: 26px;
            height: 26px;
            line-height: 19px;
            border: 2px solid @c-txt-k;
            text-align: center;
            border-radius: 100%;
            font-size: 24px;
            transform: translateY(2px);
            transition: background-color .25s @easing,
                        color .25s @easing,
                        border-color .25s @easing;

            &:hover {
                background-color: @c-txt;
                color: #fff;
                border-color: @c-txt;

                html.is-dark-mode & {
                  background-color: transparent;
                  color: @c-txt;
                }
            }
        }

        .nudge-zoom-in,
        .nudge-zoom-out {
            width: 13px;
            height: 13px;
            line-height: 8px;
            font-size: 12px;
            transform: translateY(-1px);
        }

        .nudge-zoom-in-container &,
        .nudge-zoom-out-container & {
            min-width: 13px;
            padding: 0;
        }

        .nudge-zoom-in-container & {
            margin: 0 -8px 0 8px;
        }

        .nudge-zoom-out-container & {
            margin: 0 8px 0 -8px;
        }

        .rotate,
        .marquee-zoom,
        .glasses {
            svg {
                width: 32px;
                height: 32px;
                transform: translateY(11px);

                path {
                    fill: var(--tools-icon-fill, @c-txt-k);
                    transition: fill .25s @easing;
                }
            }
        }

        .marquee-zoom--on {
            --tools-icon-fill: @c-green
        }

        .pen-draw-icon,
        .pen-text-selection-icon {
            svg {
                width: 40px;
                height: 23px;
                transform: translateY(7px);
                margin-left: -10px;

                path,
                rect,
                polygon {
                    fill: @c-txt-k;
                    transition: fill .25s @easing;
                }

                .pen-text-selection-svg-cls-2 {
                    fill: #e51c23;
                }
            }
        }

        .pen-icon {
            svg {
                width: 23px;
                height: 23px;
                transform: translateY(7px);

                path,
                rect,
                polygon {
                    fill: @c-txt-k;
                    transition: fill .25s @easing;
                }
            }
        }

        .pen-text-selection-icon,
        .pen-draw-icon,
        .pen-icon {
            &.selected {
                path,
                rect,
                polygon {
                    fill: #e51c23;
                }

                .pen-text-selection-svg-cls-1 {
                    fill: @c-txt-k;
                }
            }
        }

        .glasses--active svg path {
            fill: @c-green;
        }

        &:hover .rotate,
        &:hover .glasses {
            svg path {
                fill: @c-txt;
            }
        }

        &:hover .glasses--active {
            svg path {
                fill: @c-green-d;
            }
        }
    }
}
