/* Copyright (C) PageProof Holdings limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Authored by: <adam@big-backyard.co.nz> - September 2014
 */

/*

	.LESS include - Modals

*/

.modal										{
	
	.fill; background: rgba(0,0,0,0.8); position: fixed; top: 0; left: 0; z-index: 9; display: none;
	
	.container								{
		width: 40%; max-height: 50%; margin: auto; background: @c-body; .round(@r); .abs(@t: 50%, @l: 50%); .transform( translateX(-50%) translateY(-50%) ); .drop( @strength:0.3, @blur:0, @col:0, @x:0, @y:3, @spread:0 );
		&.l-modal							{ height: 50%; }
		.close								{ 
			@w : 100px;
			.abs(@t: 0, @r: -@w - @gap); z-index: 2; .cen; width: @w; .block; margin: 0;
		}
		.content							{
			padding: @gap; height: 100%; overflow: auto; -webkit-overflow-scrolling: touch;
		}
	}
	
}
.modal-active 								{
	overflow: hidden;
	.modal									{ display: block; }
}

@media all and ( max-width: @break-3 ) 		{
	
	.modal .container						{ 
		width: 60%; max-height: 75%; 
		&.l-modal							{ height: 75%; }
	}
	
}
@media all and ( max-width: @break-m ) 		{
	
	.modal .container						{ 
		width: 90%; max-height: 75%; 
		.close								{
			right: 0; top: -36 - @gap;
		}
	}
	
}

.modal-open .old-modal {
	display: none;
}
