/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.BrandingUploadFile {
    display: flex;
    border-radius: 5px;
    min-height: 140px;
    padding: 20px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 3px dashed @c-green;
    transition: border-color .15s ease-in-out;

    &--active,
    &:hover {
        border-color: @c-green-d;
    }

    &--inactive {
      cursor: default;
    }

    &__enterprise-only {
      margin: 5px 0 0 0;
    }

    > h3 {
        color: @c-green;
        font-size: 24px;
        margin: 0;
    }

    &:not(&--inactive) > p {
        font-size: 16px;
        color: @c-txt-k;
        margin: 0;
    }

    @media (max-width: @break-m) {
        > p {
            font-size: 14px;
        }

        > h3 {
            font-size: 18px
        }
    } 
}
