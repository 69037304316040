/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__helper {
    &__arrow {
        font-family: 'ArrrowsRegular';
        font-weight: normal;

        &--reverse {
            display: inline-block;
            transform: rotate(180deg);
        }
    }
}