/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.keyboard-shortcuts 				{
    padding-left: 80px;
    overflow: hidden; background-color: #F9F9F9;
    .fix(@b:0px, @l:0px); width: 100%; border-top: @border; z-index: 5; .transition( 250ms ); .transform( translateY(100%) ); .cen;
    h4								{ .upper; font-size: 14px; margin: @gap 0 @gap/2; }
    .close							{ .abs(@t:@gap, @r:@gap); display: none; }
    .sc 							{ display: inline-block; padding: 0 @gap; font-size: 14px; line-height: 35px; font-size: 10px; color: #666; .upper }
    .key {
        &:extend(.keyboard-shortcut__key);
        &.staging {
            background-color: @c-green;
        }
    }
}

.keyboard-shortcuts__subheading {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 14px;
}

.keyboard-shortcut__key {
    @s: 30px;
    padding: 0 6px;
    user-select: none;
    .in-block; height: @s; min-width: @s; line-height: @s; .cen; background: @c-grey-xd; .round(@r*2); font-size: 11px; font-weight: bold; color: #fff;
    &.arr 						{ text-transform: none; }

    &.staging {
        background-color: @c-green;
    }
}

.show-keyboard-shortcuts			{

    .proof-footer					{ .transform( translateY(100%) ); }
    .page-trigger					{ opacity: 0; .transform( scale(0) ); pointer-events: none; }
    .keyboard-shortcuts				{ .transform( translateY(0) ); }

}