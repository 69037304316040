/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

html {
    clip: rect(0, 0, 0, 0);
}

.stitch-embed {
	all: unset;
	width: 100%;
	height: 0;
}

.stitch-embed-eager {
	position: absolute;
	top: unset;
	left: unset;
	right: 100%;
	bottom: 100%;
	width: 0;
	height: 0;
	z-index: 1; // overridden on a case-by-case basis, but needed to prevent it from displaying below the main page contents by default.
}

@keyframes smart-compare-diff-overlay-jiggle {
	0%, 100% {
		transform: translate3d(0, 0, 0);
	}
	12.5%, 62.5% {
		transform: ~"translate3d(calc(0px - var(--smart-compare-diff-overlay-jiggle-distance)), 0, 0)";
	}
	37.5%, 87.5% {
		transform: ~"translate3d(var(--smart-compare-diff-overlay-jiggle-distance), 0, 0)";
	}
}

@keyframes smart-compare-diff-overlay-rainbow {
	0%, 100% {
		filter: hue-rotate(0deg);
	}
	50% {
		filter: hue-rotate(180deg);
	}
}

/* LESS Includes ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
.slide,
.slideBack {
  position: absolute;
  left: 0;
  padding-top: 100px;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: @c-grey-ll;

	@media (max-width: @break-m) {
		padding-top: 67px;
	}
}

.brief-upload-progress {
	height: 100%;
}

.is-safari #main {
	perspective: 1000;
	-webkit-transform: translateY(0);
}

@import (inline) '../node_modules/angular/angular-csp.css';

/* Reset -----------------------------------------------------------------------------------------------------------------*/
@import "imports/reset.less";

/* Colours ---------------------------------------------------------------------------------------------------------------*/
@import "imports/color.less";

/* MQ Definitions --------------------------------------------------------------------------------------------------------*/
@import "imports/media-queries.less";

/* LESS vars & mixin classes ---------------------------------------------------------------------------------------------*/
@import "imports/mixins.less";

/* Default Styles --------------------------------------------------------------------------------------------------------*/
@import "imports/global.less";

/* Typography ------------------------------------------------------------------------------------------------------------*/
@import "imports/typography.less";

/* Trumps ========================================================================== */
@import "imports/trumps.less";

/* Buttons ---------------------------------------------------------------------------------------------------------------*/
@import "imports/buttons.less";

/* Forms -----------------------------------------------------------------------------------------------------------------*/
@import "imports/forms.less";

/* Modals ----------------------------------------------------------------------------------------------------------------*/
@import "imports/modals.less";

/* Interactive elements --------------------------------------------------------------------------------------------------*/
@import "imports/interactive.less";

/* Master page example styles --------------------------------------------------------------------------------------------*/
@import "imports/master.less";

/* Main nav styles -------------------------------------------------------------------------------------------------------*/
@import "imports/main-nav.less";

@import "imports/footer.less";

@import "imports/spinner";

/* Site Css ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* Structure -------------------------------------------------------------------------------------------------------------*/
@head-h : 100px;

html, body							{ .fill; }
header 								{ backdrop-filter: blur(5px) }
header, .proof-footer				{ height: @head-h; border-bottom: @border; .cen; .fix(@t: 0, @l: 0); width: 100%; z-index: 2; background:fade( @c-grey-ll, 90% ); }
.proof-footer						{ top: auto; bottom: 0; border-bottom: none; border-top: @border; }

#main	{ 
  height: 100%; 
  padding-top: @head-h; 
  .rel; 
  z-index: 1; 

  html.takingSnapshot & {
    z-index: 10000000001;
  }
}

.touch *:hover 						{ }

header h1							{
	line-height: @head-h;
	margin: 0 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 26px;
	margin: 0 250px;

    @media (max-width: @break-m) {
        font-size: 12px;
        line-height: 66px;
        margin: 0 130px;
    }
}
@media (max-width: @break-m) {
	.proof-comments-active {
		.page__proof__container {
			bottom: -10px;
		}
	}
	header {
		height: 67px;
	}
}

.opps-img {
  background-color: #F3F3F3;
  margin: 0px;
}

.opps-inner {
  display: block; margin: auto;
}

/* Common Containers & Subclasses ----------------------------------------------------------------------------------------*/

/* Logo ======================================================================================== */
.logo								{
	position: fixed;
	top: 20px;
	left: @gap;
	width: 60px;
	height: 60px;
	overflow: hidden;
	text-align: left;
	z-index: 2;
	transition: opacity .3s ease-in-out;

	html.feature-no-logo & {
		opacity: 0 !important;
		pointer-events: none;
		transition-duration: 0;
	}

	@media (min-width: @break-m) {
		html.feature-no-header &:not(:hover),
		html.hide-header &:not(:hover) {
			opacity: .6;
			transition-duration: 2s;
		}
	}

	@media (max-width: @break-m) {
		.proof-comments-active & {
			display: none;
		}
	}

	html.no-feature-dashboard & {
		cursor: default;
	}

	img {
		height: 60px;
	}

	@media (max-width: @break-m) {
		transform: scale(.66, .66);
		top: 4px;
		left: 7px;
	}
}

.stitch-dialog-header-cover {
	position: fixed;
	width: 100%;
	top: 0;
	height: 100px;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.35);
}

/* Flyouts ===================================================================================== */
.flyout {
	.none;
    .fix(@t: 0, @r: 0);
    width: 600px;
    height: 100%;
    max-width: 50%;
    z-index: 5;
    .transition(200ms);
    .transform(translateY(-120%));
    background: darken(@c-body, 5%);

    &.active {
		.block;
        .transform(translateY(0));
    }

    .head {
        text-align: left;
        .outline;
        padding: 0 @gap*2;
        .rel;

        h2 {
            line-height: @head-h;
            margin: 0;
        }

        .close {
            .abs(@t: 50%, @r: @gap*2);
            .transform( translateY(-50%) );
        }
    }

    &--proof-info .head {
			display: flex;
			align-items: center;
			justify-content: space-between;

        @media (max-width: @break-m) {
            padding-left: 20px;
        }
	}

    &__collection-manage {
        background-color: @c-green;
        color: @c-white;

        .head {
            display: flex;
            height: 100px;
            padding: 0 40px;
            align-items: center;

            .flyout__collection-manage__head {
                flex-grow: 2;
                padding-right: 20px;
                font-size: 28px;
                line-height: 28px;

                &__sub {
                    margin-top: 8px;
                    font-size: 14px;
                    line-height: 14px;
                    opacity: .7;
                    max-width: 320px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            @media (max-width: @break-m) {
                height: 67px;
                padding: 0 20px;
                align-items: center;
                margin-bottom: 1px;

                .flyout__collection-manage__head {
                    font-size: 22px;
                    line-height: 22px;

                    &__sub {
                        margin-top: 0;
                        font-size: 12px;

                        span {
                            max-width: 210px;
                        }
                    }
                }
            }
        }

		&__content {
			padding: 40px;
		}
	}

    @media (max-width: @break-m) {
        width: 100%;
        max-width: 100%;
    }

    .active {
        visibility: visible;
    }

    &:not(.active) {
        visibility: hidden;
    }
}
.flyout-cover						{
	.none;
	.fix(@t: 0, @r: 0); .fill; background: @c-body; z-index: 4; pointer-events: none; .transition( 200ms ); opacity: 0;

    .no-pointer-events & {
        display: none;
    }

	&.active						{ .block; opacity: 0.9; pointer-events: auto; }
}

/* Main menu =================================================================================== */
@import "imports/main-menu.less";

/* Proof info ================================================================================== */
@import "imports/proof-info.less";

/* Loading  ==================================================================================== */
@import "imports/loading.less";

/* Slide animation ============================================================================= */


// @media (prefers-reduced-motion: no-preference) {
// 	.slide.ng-enter,
// 	.slide.ng-leave,
// 	.slideBack.ng-enter,
// 	.slideBack.ng-leave,
// 	.scaleDown.ng-leave 				{ .transition( 1000ms ) };
// 	.disappear.ng-leave				{ .transition( 250ms ) };
// 	.slide.ng-enter 					{ .transform( translateX(100%) ); }
// 	.slide.ng-enter-active 				{ .transform( translateX(0) ); }
// 	.slide.ng-leave 					{ .transform( translateX(0) ); }
// 	.slide.ng-leave-active 				{ .transform( translateX(-100%) ); }
// 	.slideBack.ng-enter 				{ .transform( translateX(-100%) ); }
// 	.slideBack.ng-enter-active 			{ .transform( translateX(0) ); }
// 	.slideBack.ng-leave 				{ .transform( translateX(0) ); }
// 	.slideBack.ng-leave-active 			{ .transform( translateX(100%) ); }
// 	.slideRight {
// 		.transition(250ms);
// 		&.ng-enter-active,
// 		&.ng-leave {
// 			.translate-X(0)
// 		}
// 		&.ng-enter,
// 		&.ng-leave-active {
// 			.translate-X(-100%)
// 		}
// 	}
// 	.scaleDown.ng-leave					{ .transform( scale(1) ); }
// 	.scaleDown.ng-leave-active			{ .transform( scale(0) ); }
// 	.disappear.ng-leave					{ opacity: 1; }
// 	.disappear.ng-leave-active			{ opacity: 0; }
// }

/* Tags ======================================================================================== */
.tag, a.tag							{

	.in-block; .round(100); background: @c-grey-d; color: @c-grey-xd; font-size: 12px; .bold; .upper; .rel; padding: @gap/4 @gap/2; margin: 0 @gap/2 0 0; outline: none;
	&:hover,
	&:active						{
		background: @c-grey-xd; color: #fff;
	}

	@media (max-width: @break-m) {
		margin-top: 5px;
	}
}

/* Avatars ===================================================================================== */
@import "imports/avatars.less";

/* Custom Containers & Subclasses ----------------------------------------------------------------------------------------*/

.server-msg							{
	background: @c-grey; .round(@r*4); .cen; padding: @gap; .rel; margin-bottom: @gap*2;
	&:not(.server-msg__no-pointer):after							{ content:'z'; .arr; font-size: 40px; .abs(@b: -@gap, @l: 0); width: 100%; line-height: 1; color: @c-grey; }

	h3 {
		margin: 0;
	}

	p {
		margin: 10px 0 0;
		color: #878787;
		font-size: 14px;
	}

	a {
		text-decoration: underline;
	}

	.green-link {
		color: @c-green;
		cursor: pointer;

		&:hover {
			color: #999;
		}
	}

	&.server-msg-warning,
	&.server-msg-danger				{
		h3							{ color: #fff; }
		p, a						{ color: rgba(255,255,255,0.75); }
		a:hover,
		a:active					{ color: #fff; }

	}
	&.server-msg-warning			{
		background: @c-warning;
		&:after						{ color: @c-warning; }
	}
	&.server-msg-danger				{
		background: @c-danger;
		&:after						{ color: @c-danger; }
	}

	transition: transform .2s @easing,
				opacity .2s @easing;

	&.ng-enter {
		opacity: 0;
		transform: translateY(-100px);
	}

	&,
	&.ng-enter-active,
	&.ng-leave {
		opacity: 1;
		transform: translateY(0);
	}

	&.ng-leave-active {
		opacity: 0;
		transform: translateY(-100px);
	}
	@media (max-width: @break-m) {
		h3 {
			font-size: 18px;
		}
	}

	button {
		margin-top: 24px;
	}
}

.server-msgs {
	position: relative;
	z-index: 1;

	.server-msg {
		&.ng-leave {
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
}

/* Workflow structure ========================================================================== */
@import "imports/workflow.less";

/* Domain admin structure ====================================================================== */
@import "imports/domain-admin.less";

/* 2 column layout ============================================================================= */
.two-col-layout						{

	overflow: hidden; margin-top: @gap*3; //padding-top: @gap*4;

	.tc-actions,
	.tc-content						{ float: left; padding: 0 @gap*2 @gap*2; .transition(500ms); .rel; left: 0; }

	.tc-actions						{
		width: 25%; border-right: @border;
		h1							{ font-size: 22px; padding: 0 0 @gap*2 @gap; border-bottom: @border; margin: 0; }
		nav 						{
			margin-bottom: @gap*2;
			a						{
				.block; border-bottom: @border; font-size: 16px; .weight(100); padding: @gap 0; .rel; .transition( 200ms ); padding-left: @gap; color: @c-txt-m;

				&:before 			{ content:''; .block; width: @gap*2; height: 100%; .abs(@t:0px, @l:-@gap*2); }
				&:hover,
				&:active			{ background: #fff; }

				&.selected			{
					color: @c-green; background: #fff;
				}

			}
		}
		.btn 						{ margin-left: @gap; }
	}
	.tc-content						{
		width: 75%;
		.tc-msg						{
			font-size: 24px; .cen; color: @c-txt-l; padding-top: @gap*2;
			.arr					{
				.block; font-size: 40px;
			}
		}
	}

	&.no-sidebar						{
		.tc-actions						{ .transform( translateX(-100%) ); }
		.tc-content						{ left: -12.5%; }
	}

}
@media all and ( max-width: @break-1 ) 	{

	.two-col-layout						{
		.tc-actions						{
			h1							{ font-size: 18px; }
			nav a 						{
				font-size: 14px;
				&:after 				{ font-size: 20px; }
			}
		}
	}

}
@media all and ( max-width: @break-3 ) 	{

	.two-col-layout						{
		.tc-actions						{
			h1							{ padding: 0 0 @gap*2; }
			nav a 						{
				padding: @gap/2;
			}
			.btn 						{ margin: 0; .block; width: 100%; }
		}
	}

}
@media all and ( max-width: @break-3 ) 	{

	.two-col-layout						{
		.tc-actions						{
			padding: 0 @gap;
			.btn 						{ padding-left: 0; padding-right: 0; }
		}
		.tc-content						{
			padding: 0 @gap;
		}
	}

}

/* Dashboard =================================================================================== */

@proof-thumb-anim-t : 300ms;

.dashboard 							{ .cen; padding-top: @gap*3; }
.db-search							{
	display: block;
	max-width: 600px;
	@media (max-width: 900px) {
		max-width: 450px;
	}
	margin: 0 auto;
	@icon-w : 20px;
	margin-bottom: @gap*2; 
	position: relative;
	input							{ padding: 0 @icon-w + @gap/2; }
	.ico 	 						{
		.block; .dim(@icon-w,46px); .sprite(5,0); .abs(@t:0px); .transition(200ms); opacity: 0.5;
		z-index: 2;
	}
	input:focus + .ico 				{ opacity: 1; }
	.load-holder					{
		.dim(60,60); .origin(0,0); .transition( 250ms ); opacity: 0;
		background: @c-body;
		.abs(@t:8px, @l:-6px); .transform( scale(0.5) );
		z-index: 2;
	}
	&.loading-results .load-holder	{ opacity: 1; }

	&__download {
		position: absolute;
		top: 10px;
		right: 0;
		background-color: transparent;
		border: none;
		padding: 0;

		svg {
			width: 23px;
			height: 23px;
			fill: @c-txt-k;

		}

		&:hover {
			svg {
				fill: #a1a1a1;
			}
		}
	}
}

.loader-message {
	width: 60%;
	top: 50%;
	margin: 80px 20% 0 20%;
	text-align: center;
	position: absolute;
	color: #128739;
	font-size: 16px;
	@media (max-width: 600px) {
		margin: 60px 15% 0 15%;
		width: 70%;
	}
}

.sub-text-per {
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	color: #999999;
	text-align: center;
	margin-bottom: 20px;
	margin-top: 60px;

	&--internal-margin {
		margin: @gap auto;
	}

}

.sub-text-per a {
  text-decoration: underline;
  color: #999999;
}

.db-nav								{
	position: sticky;
	background: fade(@c-grey-ll, 90%);
	top: 0px;
	z-index: 2;
	height: 60px;
	margin: auto;
  	margin-bottom: @gap*3; .cen; .transition( 1000ms ); .transform( translateY(0) );
  	width: 100%;
	padding: 15px 0;

	html.is-dark-mode & {
		background: fade(@c-black-ll, 90%) ;
	}

	a 								{
		font-size: 12px; .upper; .bold; color: @c-txt-m; margin: 0 @gap; padding-bottom: @gap/2;
		&:hover,
		&:active					{ color: @c-txt; }
		&.selected					{
			color: @c-green; border-bottom: 1px solid @c-green; pointer-events: none;
		}
		span {
			display: inline-block;
			font-size: 10px;
			margin-left: 5px;
			transform: translateY(-1px);
			font-weight: normal;
		}
	}

	@media screen and (max-width: @break-m) {
		width: 100%;
		a {
			margin: 0 10px;
		}
	}
	.not-select-recent {

	  //border-bottom: 1px solid #000000; pointer-events: none;

	  border-bottom-width: 1px;
	  border-bottom-style: solid;
	  border-bottom-color: #ff0000;

	  -webkit-animation: pulsate 2s infinite;
	  -moz-animation: pulsate 2s infinite;
	  -animation: pulsate 2s infinite;

	}

	@-webkit-keyframes pulsate {
	  0%   { border-color: rgba(255, 0, 0, 1); }
	  50%  { border-color: rgba(255, 0, 0, 0); }
	  100% { border-color: rgba(255, 0, 0, 1); }
	}
	@-moz-keyframes pulsate {
	  0%   { border-color: rgba(255, 0, 0, 1); }
	  50%  { border-color: rgba(255, 0, 0, 0); }
	  100% { border-color: rgba(255, 0, 0, 1); }
	}
	@keyframes pulsate {
	  0%   { border-color: rgba(255, 0, 0, 1); }
	  50%  { border-color: rgba(255, 0, 0, 0); }
	  100% { border-color: rgba(255, 0, 0, 1); }
	}


}

.db-proofs							{

	display: none; padding: @gap*2 @gap*8 0; margin-top: -@gap*2; .transition( 1250ms ); .transform( translateY(0) ); // overflow: hidden;
	&.selected						{
		.block;
	}
	&.active						{
		.proof-thumb,
		.db-add-proof				{ opacity: 1; .transform( none ) !important; }
	}
	@media (max-width: @break-m) {
		padding: @gap*2 @gap 0;
	}
}

.db-add-proof .file-dropper__hint				{

  height: 90%;
  width: 100%;

}

.db-proofs-proof-info p				{

	color: #999999;
	font-size: 12px;
	font-weight: bold;
	height: 50%;
	padding: 22% 0 0;
	text-transform: uppercase;
	transform: translateY(20px);
	transition: all 250ms cubic-bezier(0.77, 0, 0.175, 1) 0s;
	width: 180px;

}

.db-proofs-proof-info 				{

  	background-image: url("../../img/interface/@2x/bg-add-proof.png");
  	background-size: contain;
	cursor: pointer;
	display: inline-block;
	position: relative;
	transform-origin: 50% 50% 0;
	transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1) 0s;
	vertical-align: top;
	width: 180px;
	height: 240px;
	// margin-top: 70px;

	.file-dropper__hint {
		position: absolute;
		left: 0;
		right: 0;
		top: 30px;
		transition: opacity .2s cubic-bezier(0.77,0,.175,1),
					top .2s cubic-bezier(0.77,0,.175,1);
		font-size: 14px;
		line-height: 16px;
		color: #138b3b;
	}

}

.owned-by .owned-email {
  color: @c-txt-k;
  font-size: 10px;
  left: 50%;
  line-height: 1;
}

.green {
  color:#128739;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  outline: medium none;

	&-small {
		text-transform: inherit;
		font-size: 14px;
	}
}

.green-text {
	color: @c-green;
}

.one-liner {
	white-space: nowrap;
}

.placeholder {
	color: @c-txt-m;
}

.unlock-proof {

  	bottom: auto;
  	left: auto;
  	margin-top: -57px;
  	position: absolute;
  	right: 20px;
  	top: 50%;

}

.underline {
	text-decoration: underline;
}

.attachment-warning {
  color: #999999;
  font-size: 10px;
  margin: -5px 0 20px;
  padding-left: 5px;
  text-transform: uppercase;
  display: none;
}

.login-date {
  text-align: center;
  margin-top: -61px;
  margin-bottom: 61px;
  color: #999999;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

.btn.btn-at, .btn.btn-at:active, .btn.btn-at:visited {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #138b3b;
  color: #138b3b;
}

.message-div {
  width: 300px; height: auto; background-color: #fffde9; width: 30%; margin: auto; padding: 20px; margin-bottom: 40px;
  .message {
	margin-bottom: 5px !important;
	text-align: left; color: #999999;
  }
  span {
	float: left;
  }
}


.btn.btn-at:hover, .btn.btn-at:active:hover, .btn.btn-at:visited:hover, .btn.btn-at:active, .btn.btn-at:active:active, .btn.btn-at:visited:active, .btn.btn-at:focus, .btn.btn-at:active:focus, .btn.btn-at:visited:focus {
  background: #138b3b none repeat scroll 0 0;
  color: #fff;
}

.db-search-results					{
	//.fill;
	width: 100%; .abs(@t:0px, @l:0px); padding-top: 150px; padding-right:160px; padding-left:160px; .transition( 1250ms ); .transform( translateY(-100%) );
	//.outline;
	h1								{
		margin: 140px 0 @gap*2; font-size: 12px; .upper; .bold;
		color: @c-txt-m;
		strong						{ color: @c-green; .weight(normal); }
	}
	.proof-thumb					{ opacity: 1; }
}

#proof-info .contents .meta .thumb.thumb-action .overlay {
	background-color: fade(@c-green, 25%);
	strong { display: none; }
}

.thumb-action		{
	&.reverse-label-colour		{
		.overlay strong			{ color: #fff; }
	}
}

.thumb-closed,
.thumb-processing				{
  .img .overlay				{ background: fade(@c-grey-xd, 25%); }
  &.reverse-label-colour		{
	.overlay strong			{ color: #fff; }
  }
}


.proof-thumb--new-version-required {
	&.thumb-action .overlay {
		background-color: fade(@c-green, 25%) !important;
	}
	&.thumb-closed,
	&.thumb-processing {
		.overlay {
			background-color: fade(@c-grey-xd, 25%) !important;
		}
	}
}

.thumb-warning					{
	&:after 					{
		@s: 26px;
		content:''; .circ(@s); background: @c-danger; .abs(@t:-@s/2, @r:-@s/2); //border: 4px solid @c-body;
	}
	.meta						{
		p 						{ color: @c-danger; }
		.show-proof-info		{
			border-color: @c-danger; color: @c-danger;
		}
	}
}

.db-add-proof						{
	.dim(250,330); .in-block; .rel; margin: 0 @gap @gap*2; vertical-align: top;
	.origin(50%,50%); cursor: pointer;

	.file-dropper__hint 		    { padding: 0 40px; padding-top: 18%; line-height: 1.23; color: @c-green;}

	&:before {
		// Original size: 415 x 431

		content: ' ';
		width: 431px;
		height: 457px;
		background-image: url('../../img/interface/upload-icon-3.png');
		background-repeat: no-repeat;
		background-position: top left;
		background-size: 443px 467px;

		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;

		@media (max-width: @break-0) {
			background-size: 355px 380px;
		}
	}

	.file-dropper__hint {
		font-size: 16px;
	}

	@media (max-width: @break-0) {
		.file-dropper__hint,
		.file-dropper__hint p {
			font-size: 14px;
		}
	}
}

@media all and ( max-width: @break-0 ) 	{

	.proof-thumb						{
		width: 200px;
	}
	.db-add-proof						{
		.dim(205, 290);
	}

}

/* Profile ===================================================================================== */
@import "imports/profile.less";

/* <END> Site Css \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

/* Non-semantic Helper Classes -------------------------------------------------------------------------------------------*/

.ir					{ text-indent: 100%; white-space: nowrap; overflow: hidden; }

/* Hide from both screenreaders and browsers: h5bp.com/u */
.hidden 			{ display: none !important; visibility: hidden; }

/* Hide only visually, but have it available for screenreaders: h5bp.com/v */
.visuallyhidden 	{ border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p */
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }

/* Hide visually and from screenreaders, but maintain layout */
.invisible 			{ visibility: hidden; }

/* Contain floats: h5bp.com/q */
.clearfix:before,
.clearfix:after 	{ content: ""; display: table; }
.clearfix:after 	{ clear: both; }
.clearfix 			{ *zoom: 1; }

a {
	cursor: pointer;
}

#first_letter {
	font-size: 16px !important;
}

/* Media queries for responsive design -----------------------------------------------------------------------------------*/
@import "imports/media-queries.less";

/* Print styles --------------------------------------------------------------------------------------------------------*/
@import "imports/print-legacy.less";

.pp-draggable--dragging {
	will-change: transform, -webkit-transform, -ms-transform;
	pointer-events: none;
}

.page--docs {
	max-width: 1200px;
	padding: 0 30px 80px;
	margin: 0 auto;

	p,
	ol,
	h4 {
		color: #999999;
	}

	.page--docs__heading {
		text-align: center;

		h1 {
			text-align: center;
			padding-top: 60px;
			font-size: 40px;
			margin: 0 0 43px 0;
		}

		h3 {
			font-size: 24px;
			margin: 0 0 20px 0;
		}
	}
}

a.sub {
	display: inline;
	padding: 10px 15px;
	margin-left: -10px;
	margin-right: -10px;
	font-size: 12px;
	text-transform: uppercase;
	text-decoration: underline;
	font-weight: bold;
	color: #999;

	&:hover {
		color: @c-green;
	}
}

.header--user-avatar {
	all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
	cursor: pointer;
	
	.lbl {
		font-size: 20px;
		line-height: 60px;
	}
	.avatar {
		width: 60px;
		height: 60px;
	}
	.loading-avi {
		transform: scale(.6);
		background: transparent;
	}
}

iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
	background: transparent;
}

.msg-error > span:not(:first-of-type) {
    display: none;
}

.db-tab {
	transition: all 300ms @easing;

	&:not(.selected) {
		position: absolute;
		display: none;
		visibility: hidden;
		pointer-events: none;
		z-index: -1;
	}
	&:not(.active) {
		transform: translateY(-20px);
		opacity: 0;
	}
	&.active {
		transform: translateY(0);
		opacity: 1;
	}
}

.picker.modal-dialog-bg {
	z-index: 1000;
}

#page-load {
    background-color: #F9F9F9;
}

/* Intercom styles ---------------------------------------------------------------------------------------------------------*/
@import "imports/intercom.less";

.file-dropper__hint--hide {
    opacity: 0 !important;
    pointer-events: none;
}

.file-dropper__hint__muted {
    font-size: 10px;
    font-weight: normal;
    text-transform: uppercase;
    color: #666;
    margin-top: 8px;
}

.file-dropper__import {
    position: absolute;
    top: 40px;
    left: 30px;
    right: 30px;
    pointer-events: none;
    opacity: 0;

    @media (max-width: @break-0) {
        top: 30px;
    }

    .file-dropper--new-file & {
        top: 24px;
    }
}

.file-dropper__import--show {
    opacity: 1;
	cursor: default;
    pointer-events: all;
}

.file-dropper__import__options {
    font-size: 0;
}

.file-dropper__import__option {
    display: inline-block;
    width: 34px;
    height: 34px;
    padding: 6px;
    margin: 0 3px;
    transition: transform 0.2s @easing;

    &--url {
        padding: 2px;
        margin-left: 8px;
        margin-right: 8px;
    }

    &:hover {
        transform: scale(1.2);
    }

    svg {
        width: 100%;
        height: 100%;
    }

    @media (max-width: @break-0) {
        width: 30px;
        height: 30px;
    }

    .file-dropper--new-file & {
        width: 30px;
        height: 30px;
	}

	&--computer svg,
	&--url svg {
		height: 20px;
		width: 20px;
	}
}

.file-dropper--new-file {
	.file-dropper__import {
		width: 145px;
		transform: translateX(-13px);

		&__option {
			width: 23px;
			padding: 6px 3px;

			&--url {
				padding: 2px;
			}
		}
	}
}

.file-dropper__reveal {
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    transition: opacity .2s @easing,
                top .2s @easing;

    font-size: 14px;
	line-height: 16px;
    color: @c-green;

    &.file-dropper__reveal--hide {
        opacity: 0 !important;
        pointer-events: none;
    }
}

.file-dropper__reveal__subtext {
	margin-top: 6px;
	text-transform: uppercase;
    color: #666;
	font-size: 10px;
}

.error-page-overlay {
	position: absolute;
	top: -100px;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: white;
	z-index: 1000;
}

.error-not-found {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200px;
	height: 150px;
	font-size: 76px;
	text-align: center;
	margin: -75px 0 0 -100px;
	color: #666;
	z-index: 1001;
}

.error-not-found__emoji {
	font-size: 70%;
}

.error-not-found__message {
	font-size: 16px;
}

.modal-title {
	margin-top: 0;
	font-size: 32px;
}

.modal-message {
	font-size: 16px;
}

.loader-button {
	position: absolute;
	top: 50%;
	margin-top: 170px;
	width: 60%;
	margin-left: 20%;
	margin-right: 20%;
	text-align: center;
	@media (max-width: 600px) {
		margin-left: 5%;
		margin-right: 5%;
		width: 90%;
		margin-top: 160px;

		.btn, .btn-sub {
			display: block;
			width: 100%;
			margin: 0 auto 10px auto;
			padding: 15px 0;
		}
	}
}

.loader-muted {
	display: block;
	font-size: 10px;
	margin-top: 10px;
	text-transform: uppercase;
	color: @c-txt-k;
}

.tabs {
	&__nav {
		display: block;
		list-style: none;
		font-size: 0; /* Fix new line invisible space character */
		text-align: center;
		margin: 0;

        &__item {
            position: relative;
            display: inline-block;
            font-size: 12px;
            line-height: 14px;
            text-transform: uppercase;
            font-weight: bold;
            color: #999;
            cursor: pointer;
            margin: 0 20px;
            padding: 10px 0;

            &:not(&--selected):hover {
                color: #444;

                .tabs__nav__item__tip {
                    color: #999;
                }
            }

			&--selected {
				color: @c-green;
				border-bottom: 1px solid @c-green;
			}

			&--highlighted:not(&--selected) {
				@keyframes tab-highlighted {
					0% { border-bottom-color: transparent }
					50% { border-bottom-color: @c-danger }
					100% { border-bottom-color: transparent }
				}

				animation-iteration-count: infinite;
				animation-duration: 2s;
				animation-name: tab-highlighted;
				animation-fill-mode: forwards;
				border-bottom: 1px solid @c-danger;
			}

      @media (max-width: @break-m) {
        margin: 0 10px;
      }
		}
	}
	&__tabs {
		position: relative;
	}
	&__tab {
		position: absolute;
		left: 0;
		right: 0;
		margin: 20px 0;
		transition: opacity .15s @easing,
					transform .15s @easing;

		&.ng-hide-remove,
		&.ng-enter {
			opacity: 0;
			transform: translateY(-20px);
			transition-delay: .2s;
		}

		&.ng-hide-remove-active,
		&.ng-enter-active,

		&.ng-hide-add,
		&.ng-leave {
			transform: translateY(0);
			opacity: 1;
		}

		&.ng-hide-add-active,
		&.ng-leave-active {
			opacity: 0;
			transform: translateY(-20px);
		}
	}
}


.file-select {
	position: relative;

	&__element,
	&__wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0.0000000001;
		width: 100%;
		height: 100%;
	}

	&__element,
	&__wrapper,
	&__element::-webkit-file-upload-button {
		cursor: pointer !important;
	}

	&__wrapper {
		overflow: hidden;
	}
}

.dashboard {
	.file-select {
		display: inline-block;
	}
}

.x-special__mark-as-todo,
.x-special__mark-as-done {
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	padding-left: 5px;
	padding-right: 5px;
	cursor: pointer;

	> span {
		display: inline-block;
		border-radius: 100%;
		margin-left: 4px;
		@media (min-width: @break-m) {
			width: 24px;
			height: 24px;
			margin-bottom: -8px;
		}
		@media (max-width: @break-m) {
			width: 20px;
			height: 20px;
			margin-bottom: -6px;
		}
	}
}

.x-special__mark-as-todo {
	color: @c-danger;

	> span {
		background-color: @c-danger;
	}
}

.x-special__mark-as-done {
	color: @c-green;

	> span {
		background-color: @c-green;
	}
}

.x-special__mobile-login {
	display: table;
	position: absolute;
	width: 100%;
	height: 100%;
	margin-bottom: -40px;
	text-align: center;
	background-color: @c-green;

	> span {
		display: table-cell;
		vertical-align: middle;
		color: #fff;
		padding: 20px;
	}

	h2 {
		color: #fff;
	}

	p {
		font-size: 16px;
	}
}

.proof-screen__button-placeholder {
	position: absolute;
	right: 20px;
	bottom: 30px;
	width: 160px;
	height: 40px;
}

.animation-transition-clone {
  position: absolute !important;
  z-index: 1001;
}

.form-control {
	position: relative;
}

.typeahead {
	position: absolute;
	left: 0;
	right: 0;
	z-index: 100;

	li {
		cursor: pointer;
		text-align: left;
	}

	&__options {
		margin: 0;
		padding: 0;
		list-style: none;

		border: 1px solid #dcdcde;
		background-color: #eff1f2;

		html.is-dark-mode & {
			background-color: @c-txt-d;
			border: 1px solid @c-black;
		}
	}

	&__option {
		padding: 12.5px 15px;
		color: #999;
		position: relative;
		font-size: 14px;

		html.is-dark-mode & {
			color: @c-white;
		}

		&:hover,
		&:focus,
		&--selected {
			background: rgba(19, 139, 59, 0.25);
			color: @c-grey-xxdd;

			html.is-dark-mode & {
				color: @c-white;
				background: @c-grey-xxdd;
			}
		}

		> span {
			max-width: calc(~'100% - 19px');
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			display: inline-block;
			span {
				vertical-align: unset;
			}
		}

		&:not(:last-of-type) {
			border-bottom: 1px solid #dcdcde;

			html.is-dark-mode & {
				border-bottom: 1px solid @c-black;
			}
		}
	}

  	&.reverse {
	  bottom: 60px;
	  top: auto;
	}

	.highlight {
		background-color: @c-yellow;
		border-radius: 2px;

		html.is-dark-mode & {
			background-color: @c-txt-k;
		}		
	}

	// addressbook

	.option-delete {
		color: #999;
		font-size: 15px;
		width: 14px;
		height: 14px;
		line-height: 13px;
		cursor: pointer;
		float: right;
		margin-top: 4px;
	}

	.option-delete:hover {
		color: #444;
	}
}

.print-comments {
	display: inline-block;
	width: 30px;
	vertical-align: middle;

	img {
	  position: absolute;
	  width: 18px;
	  height: 18px;
	  margin-top: -10px;
	}

	&:not(:hover) img {
	  opacity: .75;
	}
}



.user-details {
	position: absolute;
	width: 100%;
  	height: 100%;
	margin: auto;
  	top: 0%;
	z-index: 100;
  	background: rgb(255, 255, 255);
  	text-align: center;


  	.details{
		@media (max-width: @break-m) {
		width: 90%;
			.avatar {
				width: 60px;
				height: 60px;
				line-height: 60px;
				font-size: 20px;
				transform: scale(0.67) translateX(-80%);
			}
			h3 {
				font-size: 20px;
			}
	}

	  	position: relative;
	  	width: 50%;
		margin: auto;
	  	padding: 20px;
	  	top: 15%;
	  	height: 245px;

	  	h3{
		  	margin: 0 0 10px 0;
		}

	  	#user-avatar {
		  //float:left;
		}

	  	.delete{
			color: #999;
			font-size: 38px;
			top: 6px;
			width: 40px;
			height: 40px;
			line-height: 13px;
			cursor: pointer;
			float: right;
		  	margin-right: -18px;
		  	margin-top: -14px;
		  	border: 2px solid #999;
		  	padding-top: 9px;
		  	text-align: center;
		  	opacity: 0.5;
		}

	  	.delete:hover{
		  	opacity: 1;
		}

	  	.user-text{
		  	padding: 5px;
			margin-top: 50px;

				&__full-stop {
					margin-left: -3px;
				}
		}

		.user-email {
			overflow-wrap: break-word;
		}

		.proof-status-dot{
			display: block;
			width: 12px;
			height: 12px;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			position: relative;
		  	margin: auto;
		  	margin-top: 16px;
		}

	  	.green{
		  	background: #138b3b;
		}

	  	.red{
		  	background: #e51c23;
		}

		.locker {
		  width: 16px;
		  height: 24px;
		  margin: auto;
		  margin-top: 9px;
		  opacity: 0.5;
		  background-image: url("../../img/interface/padlock-grey.png");
		  background-size:   cover;
		  background-repeat: no-repeat;
		  background-position: center center;

		  @media (max-width: @break-m) {
			  margin-top: 55px;
		  }
		}

	  .btn-area {
		padding-top: 10px;

		  .btn-sub, .btn {
		    @media (max-width: @break-6) {
			  display: block;
			  width: 100%;
		  	  margin: 0 0 10px 0;
		  	  padding: 15px 0;
		  	}
		  }
	  }

	  .btn-area.hide-buttons {

		display: none;

	  }

	  .confirm-action {
		max-height: 0px;
		overflow: hidden;
	  }

	  .confirm-action.open{
		max-height: 500px;
		transition: max-height 0.2s ease-in;
	  }

	}

}

.tinker {
	* {
		font-size: 12px !important;
		line-height: 1.5;
	}

	strong {
		line-height: 2;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0 0 0 10px;
	}
}

.app__flyout--debug {
	min-width: 0 !important;
	max-width: 300px;

	&,
	.app__flyout__handle {
		background-color: rgba(0, 0, 0, .8) !important;
	}

	.app__flyout__handle {
		margin-top: -100px;
	}

	hr {
		border-top: 0;
	}

	*:not(input):not(button):not([class*="app__select"]) {
		color: #f9f9f9
	}
}

.app__header {
    @media (max-width: @break-m) {
        font-size: 14px;
    }

    &__decision-summary {
        position: relative;
        top: -25px;
        margin: 0 250px;

        @media (max-width: @break-m) {
            margin: 0 130px;
        }
    }
}

.app__header__tray-container {
    position: absolute;
    top: 0;
    right: 110px;
    height: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: @break-m) {
        height: 66px;
        right: 65px;
    }

    &--on-mobile-proofing {
        right: 10px;
    }
}

.app__header__subtitle,
.app__header__reviews-count-message,
.app__header__status-message {
    text-transform: uppercase;
    font-size: 10px;
    position: relative;
    top: -30px;
    font-size: 12px;
    font-weight: 700;
    margin: 0 250px;

	&__orange {
		color: @c-warning;
	}

	&__green {
		color: @c-green;
	}

	&__grey {
		color: @c-grey-xd;
	}

	@media (max-width: @break-m) {
		font-size: 9px;
		top: -22px;
		margin: 0 70px;
	}
}

.app__header__subtitle {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.app__header__reviews-count-message,
.app__header__status-message {
	top: -28px;

	@media (max-width: @break-m) {
		top: -22px;
	}

}

html:not(.global-dropping) .show-on-global-dropping {
	display: none;
}

.px-canvas {
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  bottom: -100px;
  z-index: 1; // Added for gridlines feature

	&__compare {
		top: -150px;
		bottom: -50px;
	}
}

.no-animation {
	transition: none !important;
}

@media (max-width: 750px) {
	.proof-comments-active {
		.proof-footer,
		header,
		.app__proof-action__textarea {
			display: none;
		}
	}
}

.depress-active {
	transition: transform .2s ease-in-out;

	&:active {
		transform: scale(0.9);
	}
}

.welcome-web {
	section {
		position: relative;
		border-radius: 6px;
		cursor: pointer;
		margin-top: 20px;
		margin-bottom: 20px;
		border: 2px solid rgba(0, 0, 0, 0.01);
		background: #fff;

		div {
			padding: 20px 40px;
			margin-right: 350px;
			font-size: 16px;
		}

		img {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 20px;
			width: 320px;
			display: none;
			z-index: 1;
			pointer-events: none;
		}

		&.active {
			background-color: @c-green;
			color: #fff;
			border-color: transparent;

			img {
				display: block;
			}
		}
	}

	@media (max-width: @break-m) {
		h1 {
			font-size: 30px;
		}

		section {
			div {
				padding: 20px;
				margin-right: 200px;
			}
			img {
				width: 200px;
				right: 0;
			}
		}
	}
}
.checkMark {
	float: right;
	margin-left: 10px;
}

#collection-contents, 
#manage-contents {
	overflow-y: auto;
	height: ~"calc(100% - 100px)";
	-webkit-overflow-scrolling: touch;
}

@keyframes animate-spin {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
}

.animate-spin {
    animation: animate-spin 1s infinite linear;
}

@import 'imports/page/dashboard';

@import 'imports/app';

@import 'imports/page';

@import 'imports/next.less';

.login-password-input:disabled {
	border-bottom: 1px solid !important;
	border-color: #dcdcde !important;
}

[data-mention-user-id] {
    background-color: #cfe8d7;
}

@import 'imports/status-dots.less';

@import 'imports/dark-mode.less';

.tip-pill {
	font-weight: bold;
	font-size: 0.8em;
	text-transform: uppercase;
	padding: 3px 10px;
	background-color: @c-grey;
	color: @c-grey-xxd;
	border-radius: 100px;
}
