/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__compare {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // width:100%;
    height: 100%;
    transition: right .2s ease-in-out;

    &__img-comp-slider {
        --sliderColor: #8c8c8c;

        position: absolute;
        z-index: 9;
        cursor: ew-resize;
        top: -100px;
        width: 2px;
        height: 120%;
        background-color: #87949f;
        opacity: 0.7;
        border-radius: 50%;
        visibility: hidden;

        &-active {
            visibility: visible;
        }

        &__divider {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2px;
            height: 100%;
            background-color: var(--sliderColor);
            margin-left: -1px;
            cursor: ew-resize;
            &::before {
                content: '';
                position: absolute;
                display: flex;
                width: 3em;
                height: 3em;
                background-color: #f5f5f5;
                border: 2px solid var(--sliderColor);
                border-radius: 50%;
                transition: all 0.1s ease-in-out;
            }
        }
        &__arrows {
            z-index: 2;
            display: flex;
            gap: 0.65rem;
            justify-content: center;
            align-items: center;

            &::before,
            &::after {
              content: '';
              display: block;
              width: 0;
              height: 0;
              top: 50%;
              left: 0;
              border-top: 0.5rem solid transparent;
              border-bottom: 0.5rem solid transparent;
            }

            &::before {
              border-right: 0.65rem solid var(--sliderColor);
            }

            &::after {
              border-left: 0.65rem solid var(--sliderColor);
            }
        }
    }

    &__left,
    &__right,
    &__center {
        position: absolute;
        top: 0px;
        bottom: 0px;
        text-align: -webkit-center;

        &-container {
            width: 100%;
            height: 100%;
            //position: relative; //added for pins position, let's see if works

            &__overlay {
                position: absolute;
                top: -150px;
                width: 100%;
                height: ~'calc(100% + 150px)';
                z-index: 2;

                &:hover {
                    background-color: rgba(255, 10, 10, .25);
                }
            }

            &__msg {
                position: relative;
                top: 50%;
                transform: translateY(-15px);
                color: #138b3b;
                text-align: center;

                > p {
                    font-size: 16px;
                }
            }

            &__image {
                position: absolute;
                left: 50%;
                top: 50%;
                max-width: 100%;
                height: 100%;
                object-fit: contain;
                transform: translate3d(-50%, -50%, 0);
                //transition: width 1s @easing,
                //            height 1s @easing,
                //            opacity 1s @easing;

                &-animate {
                    opacity: .2;
                }

                &-button {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-top: -20px;
                    margin-left: -20px;
                    width: 40px;
                    height: 40px;
                    background-color: #fff;
                    border-radius: 100%;

                    &:hover {
                        #Icons-AV {
                            fill: #999;
                        }
                    }

                    #Icons-AV {
                        fill: #B9C0C1;
                    }

                    svg {
                        width: 25px;
                        height: 25px;
                        position: relative;
                        top: 6px;
                        left: 4px;
                    }
                }
            }

            &__pagination {
                top: 50%;
                //z-index: 1;
                //position: relative;

                &-item {
                    position: absolute;
                    top: 50%;
                    height: 100%;
                    margin-top: -15px;

                    > a {
                        color : #999;

                        svg {
                            width: 30px;
                            height: 30px;
                            fill: #138b3b;
                        }
                    }

                    > a:hover {
                        color : #dcdcde;

                        svg {
                            fill: #728182;
                        }
                    }
                }

                &-text {
                    position: absolute;
                    top: 100%;
                    cursor: pointer;
                    color: #999;
                    font-size: 14px;
                    transform: translate(-15px, 10px);
                }
            }

            &__pagination-left {
                right: 100%;
            }

            &__pagination-right {
                left: 100%;
            }

            &__scrubber {
                .VideoScrubber {
                    top: inherit;
                    bottom: -10px;
                }
            }
        }
    }

    &__left {
        left: 60px;
        right: ~'calc(50% + 60px)';
        z-index: 1; //something playing with z-index of left or center, which causing next arrow of left container not clickable

        &-merge {
            left: 0px;
            right: 0px;
        }

        &-active {
            z-index: 2;
        }

        &-linked {
            left: 90px;
            right: ~'calc(50% + 30px)';
        }
    }

    &__right {
        left: ~'calc(50% + 60px)';
        right: 60px;
        z-index: 1;

        &-merge {
            left: 0px;
            right: 0px;
        }

        &-active {
            z-index: 2;
        }

        &-linked {
            left: ~'calc(50% + 30px)';
            right: 90px;
        }
    }

    &__center {
        left: 0px;
        right: 0px;
        opacity: 0;
        z-index: 0;

        &-show {
            opacity: 1;
        }

        &-active {
            z-index: 2;
        }
    }

    &__screen {

            .pin {
                z-index: 2;

                &:hover {
                    opacity: 1; z-index: 20;
                }
            }
    }

    &__toolbar {
        &__icon-exit {
            z-index: 11;
            height: 32px;
            width: 32px;
            position: relative;
        }
    }

    &__mode-on {

    }

    &__mode-off {

    }

    &__merge-on {
        .app__compare__left-container__pagination,
        .app__compare__right-container__pagination {
            //display: none;
        }

        .app__comparison__scrubber__left-version,
        .app__comparison__scrubber__right-version,
        .rzslider {
            display: block;
        }
    }

    &__merge-off {
        .app__comparison__scrubber__left-version,
        .app__comparison__scrubber__right-version,
        .rzslider {
            display: none;
        }
    }

}
