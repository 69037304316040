/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__btn {
    position: relative;
    text-decoration: none;
    background: transparent;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    display: inline-block;
    border: 1px solid transparent;
    border-radius: var(--Button-border-radius, 1px);
    padding: var(--Button-padding, 10px 20px);
    text-align: center;
    appearance: none;
    max-width: 100%;
    user-select: none;
    transition: transform .1s ease-in;

    &:not(:disabled)&:not(&--disabled):active {
        transform: scale(0.9);
    }

    html:not(.is-safari) &:active:before {
        content: ' ';
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
    }

    //line-height: 23px;
    //border-radius: 3px;
    //box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, .1);

    @media (max-width: @break-m) {
        font-size: 12px;
    }

    &--small {
        --Button-border-radius: 4px;
        --Button-padding: 6px 12px;
    }

    &--medium {
        --Button-border-radius: 1px;
        --Button-padding: 10px 20px;
    }

    &--large {
        --Button-padding: 19px 30px;
        --min-width: 180px;
    }

    &:not(&--auto-width) {
        min-width: 160px;
    }

    &--wide {
        min-width: 250px !important;
    }

    &:not(&--no-shadow):active {
        box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, .05);
    }

    &--loading {
        &:not(&-add) {
            transition-delay: 4s;
            transition: color .5s @easing;
        }

        color: transparent !important;
    }

    .spinner {
        transform: scale(.5);
        opacity: .75;

        &__inner:before {
            background-color: transparent;
        }

        &__top,
        &__left,
        &__right,
        &__bottom {
            background-color: white;
        }
    }

    &--main {
        padding: 10px 30px 10px;

        @media (max-width: @break-m) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &--block {
        display: block;
        width: 100%;
    }

    &--fix-height {
        display: inline-block;
        height: 40px;
        text-wrap: nowrap;
    }

    &--thin {
        font-weight: normal;
    }

    &--white,
    &--hover--white:hover,
    &--hover--white:focus,
    &--hover--white&--hover,
    &--hover--white.js-active {
        background-color: #fff;
    }

    &--white-outline:not(:hover):not(&--hover):not(.js-active) {
        border: 1px solid #fff;
    }

    &--hover--white-outline:hover,
    &--hover--white-outline:focus,
    &--hover--white-outline&--hover,
    &--hover--white-outline.js-active {
        border: 1px solid #fff;
    }

    &--green {
        background-color: @c-green;
    }

    &--green-outline:not(:disabled):not(:hover):not(&--hover):not(.js-active) {
        border: 1px solid @c-green;
    }

    &--hover--green:hover,
    &--hover--green:focus,
    &--hover--green&--hover,
    &--hover--green.js-active {
        background-color: @c-green-d;
    }

    &--hover--green-outline:hover,
    &--hover--green-outline:focus,
    &--hover--green-outline&--hover,
    &--hover--green-outline.js-active {
        border: 1px solid @c-green;
    }

    &--grey {
        background-color: #E1E5E6;
    }

    &--grey-outline:not(:hover):not(&--hover):not(.js-active) {
        border: 1px solid #737373;
    }

    &--hover--grey:hover,
    &--hover--grey:focus,
    &--hover--grey&--hover,
    &--hover--grey.js-active {
        background-color: #737373;
    }

    &--hover--grey-outline:hover,
    &--hover--grey-outline:focus,
    &--hover--grey-outline&--hover,
    &--hover--grey-outline.js-active {
        border: 1px solid #7F8C8D;
    }

    &--red {
        background-color: @c-danger;
    }

    &--red-outline:not(:hover):not(&--hover):not(.js-active) {
        border: 1px solid @c-danger;
    }

    &--orange-outline:not(:hover):not(&--hover):not(.js-active) {
        border: 1px solid @c-warning;
        color: @c-warning;
    }

    &--hover--red:hover,
    &--hover--red:focus,
    &--hover--red&--hover,
    &--hover--red.js-active {
        background-color: @c-danger-d;
    }

    &--hover--red-outline:hover,
    &--hover--red-outline:focus,
    &--hover--red-outline&--hover,
    &--hover--red-outline.js-active {
        border: 1px solid @c-danger-d;
    }

    &--hover--orange-outline:hover,
    &--hover--orange-outline:focus,
    &--hover--orange-outline&--hover,
    &--hover--orange-outline.js-active {
        border: 1px solid @c-warning-d;
    }

    &--red-text,
    &--hover--red-text:hover,
    &--hover--red-text:focus,
    &--hover--red-text&--hover,
    &--hover--red-text.js-active {
        color: @c-danger;
    }

    &--dark-red-text,
    &--hover--dark-red-text:hover,
    &--hover--dark-red-text:focus,
    &--hover--dark-red-text&--hover,
    &--hover--dark-red-text.js-active {
        color: @c-danger-d;
    }

    &--white-text,
    &--hover--white-text:hover,
    &--hover--white-text:focus,
    &--hover--white-text&--hover,
    &--hover--white-text.js-active {
        color: #fff;
    }

    &--green-text,
    &--hover--green-text:hover,
    &--hover--green-text:focus,
    &--hover--green-text&--hover,
    &--hover--green-text.js-active {
        color: @c-green;
    }

    &--dark-green-text,
    &--hover--dark-green-text:hover,
    &--hover--dark-green-text:focus,
    &--hover--dark-green-text&--hover,
    &--hover--dark-green-text.js-active {
        color: @c-green-ld;
    }

    &--grey-text,
    &--hover--grey-text:hover,
    &--hover--grey-text:focus,
    &--hover--grey-text&--hover,
    &--hover--grey-text.js-active {
        color: #737373;
    }

    &--black-text,
    &--hover--black-text:hover,
    &--hover--black-text:focus,
    &--hover--black-text&--hover,
    &--hover--black-text.js-active {
        color: #333;
    }

    &--orange-text,
    &--hover--orange-text:hover,
    &--hover--orange-text:focus,
    &--hover--orange-text&--hover {
        color: @c-warning;
    }
    
    &--icon {
        padding: 0;
        fill: @c-grey-icon;
        
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &--hover--icon:hover,
    &--hover--icon:focus,
    &--hover--icon&--hover {
        fill: #444;
    }

    &--orange {
        background-color: @c-warning;
    }

    &--hover--orange:hover,
    &--hover--orange:focus,
    &--hover--orange&--hover,
    &--hover--orange.js-active {
        background-color: @c-warning-d;
    }

    &:disabled,
    &--disabled {
        background-color: @c-grey-ld;
        color: @c-txt-k;
        opacity: .5;
        cursor: default;
    }

    &__alt-text {
        opacity: .7;
    }

    &--round-light-green {
        background-color: rgba(17, 139, 58, 0.15);
        color: @c-green;
        height: 24px;
        border-radius: 24px;
        padding: 0 10px;
        font-size: 12px;
    }
}
