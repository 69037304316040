/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.notification-tray {
    width: 50px;

    &__button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }

    &__icon,
    &__button {
        display: block;
        width: 45px;
        height: 45px;
    }

    &__icon {
        padding: 10px;
        transition: stroke .2s @easing;
        cursor: pointer;
    }

    &__count {
        position: absolute;
        top: 5px;
        right: 5px;
        height: 10px;
        min-width: 10px;
        border-radius: 10px;
        background-color: @c-danger;
        transition: opacity .2s @easing,
                    transform .2s @easing;
        font-size: 6px;
        font-weight: bold;
        line-height: 10px;
        color: #fff;
        padding: 0 3px;
    }

    .notification-icon-path {
        stroke: @c-grey-icon;
        transition: stroke .2s @easing;
    }

    &__button:hover .notification-icon-path {
        stroke: darken(@c-grey-icon, 20%);
    }

    @media (max-width: @break-m) {
        width: 30px;
    }
}

.notifications {
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 100;
    transition: transform .2s @easing,
                width .2s @easing;

    background-color: #ececec;
    border-left: 1px solid #cbcbcb;
    height: 100%;

    width: 30%;
    max-width: 600px;
    min-width: 500px;

    @media (max-width: @break-0) {
        width: 40%;
    }

    @media (max-width: @break-3) {
        width: 50%;
    }

    @media (max-width: @break-m) {
        width: 100%;
        min-width: 0;
    }

    &--active {
        transform: translateY(100%);
    }

    &__close-button {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        line-height: 0;
        outline: none;
        border-color: #7f8c8d;
        background-color: transparent;
        padding: 0;

        &:before {
            //transform: translateY(-4px);
            color: #7f8c8d;
        }
    }

    &__snooze-btn{
        position: absolute;
        top: 39px;
        right: 90px;

        @media (max-width: @break-m) {
            top: 23px;
            right: 35px;
        }

    }

    &__header {
        position: absolute;
        background-color: #e1e1e1;
        padding: 0 100px 0 40px;
        width: 100%;

        @media (max-width: @break-m) {
            height: 70px;
            padding-left: 20px;
        }
    }

    &__title {
        display: block;
        font-size: 36px;
        line-height: 100px;
        cursor: pointer;

        @media (max-width: @break-m) {
            font-size: 22px;
            line-height: 67px;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__feed {
        position: absolute;
        top: 100px;
        bottom: 0;
        width: 100%;
        overflow-y: auto;
        padding: 0 12px 20px;
        -webkit-overflow-scrolling: touch;

        @media (max-width: @break-m) {
            top: 70px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    &__separator {
        margin: 20px 0;
        color: #888;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
    }

    .app__btn {
        position: relative;
        display: block;
        margin: 40px auto 60px;

        &.loading-more {
            cursor: default;
            color: transparent;
        }
    }
}

.notification {
    position: relative;
    width: 100%;
    margin: 10px 0 10px 10px;
    padding: 20px;
    font-weight: 500;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 3px;
    box-shadow: 0 5px 0 0 rgba(0, 0, 0, .03);
    padding-left: 80px;
    overflow: hidden;
    transition: box-shadow .2s @easing,
                opacity .2s @easing;

    html.is-dark-mode & {
        border-color:  @c-txt-k;
    }

    &:hover .avatar__inner {
        filter: grayscale(0);
    }

    @media (max-width: @break-m) {
        padding: 15px 35px 15px 15px;
    }

    @media (min-width: @break-m) {
        padding-right: 40px;
    }

    //pp-avatar,
    //&__fallback-image {
    //    position: absolute;
    //    top: 20px;
    //    left: 20px;
    //
    //    @media (max-width: @break-m) {
    //        top: 15px;
    //        left: 15px;
    //    }
    //}

    &--actionable {
        cursor: pointer;

        &:hover {
            box-shadow: 0 4px 2px 0 rgba(0, 0, 0, .05);
        }
    }

    &__time {
        font-size: 10px;
        text-transform: uppercase;
    }

    &__message {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__info-button {
        display: block;
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        padding: 5px;
        border: none;
        background: transparent;
        outline: none;
        transition: transform .2s @easing,
                    opacity .2s @easing;

        svg {
            width: 20px;
            height: 20px;

            @media (max-width: @break-m) {
                width: 16px;
                height: 16px;
            }
        }

        g > * {
            fill: #999;
            transition: fill .2s @easing;
        }

        &:hover g > * {
            fill: #666;
        }

        @media (max-width: @break-m) {
            right: 2px;
        }
    }

    &__label {
        @size: 15px;

        position: absolute;
        top: -(@size / 2);
        right: -(@size / 2);
        width: @size;
        height: @size;
        transform: rotate(45deg);
    }

    &__thumb {
        position: absolute;
        width: 40px;
        top: 20px;
        left: 20px;

        @media (max-width: @break-m) {
            display: none;
        }

        //pp-avatar {
        //    display: none;
        //    position: absolute;
        //    right: 100%;
        //    bottom: 100%;
        //    transform: translate3d(50%, 50%, 0) scale(.6) !important;
        //}

        img {
            max-width: 100%;
            max-height: 40px;
            transition: opacity .2s @easing;
        }
    }

    &--actioned:not(:hover) {
        opacity: .5;
    }

    a {
        transition: color .2s @easing;
    }

    span {
        color: @c-green;
    }

    span.proof-version {
        font-weight: normal;
    }

    &:hover a,
    &:hover a span {
        color: @c-green-d;
    }

    &:not(:hover) &__thumb img {
        opacity: .8;
    }

    //&--actioned &__label {
    //    background-color: @c-green;
    //}

    @media (min-width: @break-m) {
        &:not(:hover) &__info-button {
            transform: translateY(40px);
            opacity: 0;
        }
    }
}

.notification-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: .8;

    &--green-dot {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #138b3b;
    }

    &--red-dot {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #e51c23;
    }

    &--lock,
    &--unlock,
    &--download,
    &--star,
    &--reply,
    &--mention {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &--lock {
        width: 9px;
        height: 14px;
        background-image: url('../../img/interface/padlock-grey.png');
    }

    &--unlock {
        width: 9px;
        height: 16px;
        background-image: url('../../img/interface/padlock-unlock-grey-4.png');
    }

    &--download {
        width: 13px;
        height: 17px;
        background-image: url('../../img/interface/download-icon.png');
    }

    &--star {
        width: 15px;
        height: 14px;
        background-image: url('../../img/interface/star-icon.png');
    }

    &--reply {
        width: 20px;
        height: 20px;
        opacity: .4;
        background-image: url('../../../img/icons/replies.svg');
    }

    &--mention {
        width: 12px;
        height: 12px;
        opacity: .4;
        background-image: url('../../img/content/proof/icons/mention_icon.png');
    }
}

.NotificationsTabs {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    padding: 5px 0 12px 0;
    top: 0;
    z-index: 1;
    background-color: #ececec;

    .Tabs__navigation {
        background-color: transparent;

        html.is-dark-mode & {
            background-color: @c-black-ld;
        }
    }
  
    #TabButton-attention-needed{
        color: @c-warning;
        &.TabButton--selected {
            border-bottom-color: @c-warning;
        }
        &:not(.TabButton--selected):hover{
            color: @c-warning-d;
        }
    }

    @media (max-width: @break-m) {
        .TabButton {
            font-size: 12px;
            margin: 0 10px;
        }
    }

    html.is-dark-mode & {
        background-color: @c-black-ld;
    }
}
