/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__context {
    @content-padding: 10px;

    position: relative;

    &,
    &__subject,
    &__overlay {
        display: inline-block;
    }

    &--active {
        z-index: 1;
    }

    &__overlay {
        position: absolute;
        top: 0;
        min-height: 100%;
        min-height: ~'calc(100% + 20px)'; // Change if @content-padding changes
        background-color: white;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .05);
        border-radius: 2px;
        padding: 10px;
        z-index: -1;
        transition: transform .25s @easing, opacity .25s @easing;
        transform: translateX(0) translateY(0) scale(0);
        opacity: 0;

        .app__context--active & {
            opacity: 1;
        }

        //

        .app__context--right & {
            text-align: left;
            padding-right: 15px;
            transform-origin: top left;
            left: 0;
        }

        .app__context--left & {
            text-align: right;
            padding-left: 15px;
            transform-origin: top right;
            right: 0;
        }

        .app__context--right.app__context--active & {
            transform: translateX(-@content-padding) translateY(-@content-padding) scale(1);
        }

        .app__context--left.app__context--active & {
            transform: translateX(@content-padding) translateY(-@content-padding) scale(1);
        }
    }
}