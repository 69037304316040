/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
// Top level comment
&__ncomment {
    position: relative;
    background-color: #fff;
    border-radius: 3px;
    padding: 1px;
    margin-bottom: 20px;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, .025);

    &.ng-leave {
        transition: none;
    }

    &,
    &__reply {
        transition: background-color .25s @easing;
    }

    &--selected {
        background-color: @c-yellow;
    }

    textarea::-webkit-input-placeholder {
        color: rgba(0, 0, 0, .5);
    }

    &__content {
        position: relative;
        margin: 20px;
        font-size: 14px;
        padding-left: 50px;

        @media (max-width: @break-m) {
            padding-left: 40px;
        }

        &__questioned {
            color: @c-warning;
        }

        &__highlighted {
            background-color: #cfe8d7;
            color: #444;

            &._UN39XUIH70HI1YEH,
            html.not-production &._UIBA2OWAA8RUNBYM,
            html.not-production &._UBTUCK7UYG00T8AD {
                @opacity: .2;
                background-color: #fff;
                background-image: linear-gradient(-45deg,
                    rgba(77, 20, 140, @opacity) 0%,
                    rgba(77, 20, 140, @opacity) 18%,
                    rgba(27, 119, 205, @opacity) 18%,
                    rgba(27, 119, 205, @opacity) 33%,
                    rgba(54, 160, 48, @opacity) 33%,
                    rgba(54, 160, 48, @opacity) 50%,
                    rgba(255, 210, 0, @opacity) 50%,
                    rgba(255, 210, 0, @opacity) 66%,
                    rgba(255, 160, 0, @opacity) 66%,
                    rgba(255, 160, 0, @opacity) 83%,
                    rgba(207, 15, 15, @opacity) 83%,
                    rgba(207, 15, 15, @opacity) 100%);
            }

            &._U2HSVXVQI0GQDD8H {
                padding-left: 22px;
                background-image: url(/img/special/jar-jar-standard-64.png);
                background-position: 2px center;
                background-size: 18px 18px;
                background-repeat: no-repeat;
            }
        }

        .px-mention__highlight {
            border: 1px solid transparent;
            padding: 4px;
        }

        textarea {
            display: block;
            margin: 0;
            border: 1px solid @c-grey-d;
            font-size: inherit;
            max-height: none !important; // Prevents scrollbar ugliness
            min-height: 17px !important;
            background-color: #fff;
            padding: 4px;
        }
    }

    &__content__options {
        margin-top: 15px;
        //margin-bottom: -2px;
    }

    &__content__label {
        position: relative;
        padding-left: 26px;
        overflow: hidden;
        line-height: 20px;
        font-weight: bold;
        color: @c-grey-icon;
        text-transform: uppercase;
        font-size: 12px;
        margin-right: 15px;
        transition: color .25s @easing;
        cursor: pointer;

        &:before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            height: 17px;
            width: 17px;
            border: 3px solid @c-grey-icon;
            border-radius: 100%;
            transition: border-color .25s @easing,
                        background-color .25s @easing;
        }

        &--active,
        &:hover {
            @hover-color: darken(@c-grey-icon, 20%);

            color: @hover-color;

            &:before {
                border-color: @hover-color;
            }
        }

        &--active:before {
            background-color: darken(@c-grey-icon, 20%);
        }
    }

    &__actions {
        z-index: 1;
        font-size: 0; // Prevents spaces between the buttons
        vertical-align: top;
        width: 80px;

        .file-select {
            display: block;
        }

        button,
        label {
            display: block;
            float: left;
            border: 0;
            padding: 0;
            margin: 0;
            outline: none;
            background-color: transparent;

            path {
                transition: fill .25s @easing;
            }
        }
    }

    &__commit-action,
    &__attach-action {
        width: 35px;
        height: 40px;

        svg {
            width: 24px;
            height: 24px;
        }

        inline-svg {
            display: block;
        }

        &.active path {
            fill: @c-green;
        }

        &.active:hover path {
            fill: @c-green-d;
        }
    }

    &__commit-action {
        path {
            fill: @c-green;
        }
        &.active path,
        &:hover path {
            fill: @c-green-d;
        }
        &:disabled path {
            fill: @c-grey-icon !important;
        }
    }

    &__content--editing > &__actions {
        position: absolute;
        bottom: -7px;
        right: -20px;
    }

    &__user + &__content {
        margin-top: -5px; // 15px space between the user & comment
    }

    &__transclude {
        background-color: #f3f3f3;
        margin: 0 -1px;
        padding: 0 1px;
    }

    &__content__textarea + &__attachments,
    &__content__edit + &__attachments,
    &__content__text + &__attachments,
    &__content__options + &__attachments {
        margin-top: 15px;
        margin-bottom: 2px;
    }

    &__message {
        margin-top: 10px;
        font-size: 10px;
        text-transform: uppercase;
        color: #737373;

        &--file-error {
            color: @c-danger;
        }
    }

    &__icon-button {
        inline-svg {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &__button {
        position: relative;
        display: inline-block;
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        outline: 0;
        vertical-align: top;
        height: 20px;
        margin-left: 10px;
        transition: transform .1s ease-in,
                    background-color .1s @easing;
        transform-origin: center center;

        &:not(.disabled):active {
            transform: scale(0.9);
        }

        &.disabled {
            cursor: default;
        }

        > span {
            display: block;
            font-size: 12px;
            line-height: 20px;
            font-weight: bold;
            color: #aaa;
        }

        .page__proof__dialog & {
            margin-left: 8px;
            margin-right: 8px;
        }
    }

    &__button {
        @keyframes pop {
            0% {
                opacity: 0;
                transform: scale(1);
            }
            40% {
                opacity: 1;
            }
            100% {
                opacity: 0;
                transform: scale(3);
            }
        }

        &--agree {
            margin-right: 5px;

            svg {
                width: 20px;
                height: 20px;
            }

            path,
            polygon {
                fill: #aaaaaa;
            }

            &:not(.disabled):hover {
                path,
                polygon {
                    fill: darken(#aaaaaa, 5%);
                }
            }

            .pop {
                pointer-events: none;
            }
        }

        &--active&--agree:not(&--agree-heart) {
            span {
                color: #f6a500;
            }

            path,
            polygon {
                fill: #f6a500;
            }

            &:not(.disabled):hover {
                span {
                    color: darken(#f6a500, 5%);
                }

                path,
                polygon {
                    fill: darken(#f6a500, 5%);
                }
            }

            &:not(.disabled) .pop svg {
                animation: pop .8s;

                path,
                polygon {
                    fill: #f6a500 !important;
                }
            }
        }

        &--active&--agree-heart {
            span {
                color: @c-danger;
            }

            path,
            &:not(.disabled) .pop svg path {
                fill: @c-danger;
            }

            &:not(.disabled):hover {
                span {
                    color: darken(@c-danger, 5%);
                }

                path {
                    fill: darken(@c-danger, 5%);
                }
            }

            &:not(.disabled) .pop svg {
                animation: pop .8s;

                path {
                    fill: @c-danger !important;
                }
            }
        }
    }

    &__button {
        &:before {
            content: ' ';
            position: absolute;
            top: -6px;
            left: -6px;
            right: -6px;
            bottom: -6px;
        }
    }

    &__icon-button {
        padding-right: 25px;
    }

    &__delete-confirm {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background-color: #f3f3f3;
        margin: -1px;

        .app__btn-group {
            padding-bottom: 2px;
        }

        p {
            color: #666;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 15px;
            margin: 0;
        }

        hr {
            margin: 15px 0;
            border-top-color: #eaeaea;
        }
    }

    .px-mention__textarea {
        background-color: #fff;

        textarea {
            background-color: transparent;
        }
    }

    &__button--metadata {
        border-radius: 20px;
        background-color: #e6e6e6;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;

        &:hover {
            background-color: darken(#e6e6e6, 5%);
        }

        svg {
            height: 20px;
            width: 20px;
        }

        .svg-more {
            fill: rgba(0, 0, 0, .5);
        }
    }

    &__metadata-arrow {
        position: absolute;
        margin-left: 18px;
        margin-top: 24px;
        content: ' ';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 10px 7px;
        border-color: transparent transparent #e6e6e6 transparent;
        transition: opacity .2s ease-in-out,
                    margin-top .2s ease-in-out;

        &:not(&--show) {
            opacity: 0;
            transition-delay: .1s;
        }

        &--show {
            margin-top: 18px;
        }
    }

    &__metadata {
        border-radius: 4px;
        background-color: #e6e6e6;
        padding: 11px 16px 5px;
        margin-right: 16px;
        margin-left: 74px;
        margin-bottom: 10px;
        color: #666;
        font-size: 14px;

        section {
            margin-top: 5px;
            margin-bottom: 10px;
            word-wrap: break-word;
        }

        strong {
            display: block;
            margin-bottom: 2px;
            text-transform: uppercase;
            font-size: 12px;
        }

        .app__btn&__restore {
            padding: 4px 4px;
            float: right;
            margin: -2px -4px;

            svg {
                display: block;
                width: 20px;
                height: 20px;
            }

            .svg-restore {
                fill: #555;
            }

            &:hover .svg-restore {
                fill: #333;
            }
        }

        &__page {
            svg {
                display: inline-block;
                transform: translateY(3px);
                width: 16px;
                height: 16px;
            }

            .svg-top,
            .svg-left {
                fill: @c-txt-k;
            }
        }

        &__path:not(:hover) span {
            display: none;
        }
    }
}
