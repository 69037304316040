/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__tooltip {
    position: absolute;
    top: -100000%;
    left: -100000%;
    display: block;
    white-space: pre-wrap;
    max-width: 450px;
    z-index: 99999999; // Any layer has the ability to show a tooltip (max priority)
    overflow: hidden;

    // This is added in case we have any tooltips that have not been updated to remove the old title/description formatting
    b,
    strong {
        font-weight: 500 !important;
    }

    &--default,
    &--workflow,
    &--proof-info-workflow,
    &--utilities,
    &--email,
    &--popover,
    &--buttons {
        background-color: #333;
        box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
        border-radius: 3px;
        color: #fff;
        font-size: 14px;
        padding: 8px 12px;
        line-height: 1.5;
    }

    &--note {
        background-color: #fffde9;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, .1);
        border-radius: 2px;
        padding: 10px;
        font-size: 12px;

        strong {
            color: #444;
        }

        p {
            color: @c-txt-k;
            margin-bottom: 0;
            font-size: inherit;
        }
    }

    &--ready {
        opacity: 0;
        transform: scale(.5);
    }

    &:not(.app__tooltip--show) {
        pointer-events: none;
    }

    &--hide,
    &--show {
        transition: transform .25s @easing,
            opacity .25s @easing;
    }

    &--down&--right {
        transform-origin: top left;
    }

    &--down&--left {
        transform-origin: top right;
    }

    &--up&--right {
        transform-origin: bottom left;
    }

    &--up&--left {
        transform-origin: bottom right;
    }

    &--up&--center {
        transform-origin: bottom center;
    }

    &--down&--center {
        transform-origin: top center;
    }

    &--show {
        opacity: 1;
        transform: scale(1);
    }

    &--workflow,
    &--popover {
        max-width: ~"min(290px, 90vw)"
    }

    &--proof-info-workflow {
        max-width: ~"min(230px, 90vw)"
    }

    &--utilities {
        max-width: ~"min(270px, 90vw)"
    }

    &--email {
        width: auto;
        max-width: 90vw;
    }

    &--buttons {
        max-width: ~"min(300px, 90vw)"
    }

    &--popover {
        a {
            color: #fff;
            text-decoration: underline;

            &:hover {
                font-weight: 600;
            }
        }

        &:hover {
            background-color: #555;
        }
    }

    &:not(&--enable-mobile) {
        @media (max-width: @break-m) {
            display: none;
        }
    }

}