/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Avatar {
    display: inline-block;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    vertical-align: bottom;

    &:focus {
        outline: none;
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        transition: filter .25s @easing;
    }

    &:not(:hover):not(:active):not(:focus):not(.js-active):not(.Avatar--active) &__image, // remove this line if you don't want grayscale avatars
    &--disabled &__image {
        filter: grayscale(1);
    }

    &__initials {
        display: block;
        text-align: center;
        background-color: @c-green;
        color: #fff;
        transition: background-color .25s @easing,
                    color .25s @easing;
    }
}
