/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.BriefInfoContainer {
  width: 100%;
  text-align: center;

  &__server-msg {
    background-color: @c-grey;
    border-radius: 4px;
    text-align: center;
    padding: 20px;
    margin: 40px;

     > p {
       color: @c-txt-k;
     }
    
     html.is-dark-mode & {
       background-color: @c-black-l;
     }
  }

  &__meta {
    padding: 40px;

    @media (max-width: @break-m) {
      padding: 20px;
    }

    fieldset {
      position: relative;
    }

    textarea {
      font-size: 16px;
      padding-right: 20px;

      @media (max-width: @break-m) {
        font-size: 12px;
      }
    }

    input {
      font-size: 16px;
      height: 25px;

      .alert {
        color: @c-danger;
      }
    }

    html.is-dark-mode & {
      background-color: @c-black-d;
    }
  }

  &__title textarea {
    margin-bottom: 5px;
    text-align: center;
    font-size: 22px;

    @media (max-width: @break-m) {
      font-size: 18px;
    }
  }

  &__status {
    font-size: 18px;
    margin-bottom: 20px;
    color: @c-green;

    @media (max-width: @break-m) {
      font-size: 14px;
    }
  }

  &__button-holder {
    width: 30px;
    margin: 0 auto;
  }

  &__slide-info {
    max-width: 250px;
    margin: 0 auto;
    padding: 20px 0 0;

    &__vertical-line {
      width: 1px;
      height: 20px;
      background-color: @c-txt-m;
      margin: 6px auto;
    }
  }

  &__slide-info > div {
    font-size: 10px;
  }

  &__thumbnail {
    display: flex;
    padding: 20px 0;

    .app__proof-thumbnail {
      margin-bottom: 5px;
    }

    &__extension {
      text-transform: uppercase;
      font-size: 10px;
      margin-top: 10px;

      &--with-file-dropper {
        margin-top: 15px;
      }
    }

    &__info {
      text-align: left;
      padding-left: 40px;
      padding-top: 10px;
      flex-grow: 2;

      @media (max-width: @break-m) {
        padding-left: 20px;
      }

      &__item {
        margin-bottom: 10px;

        @media (max-width: @break-m) {
          font-size: 12px;
        }
      }
    }

    &__date {
      @media (max-width: @break-m) {
        padding-left: 0;
        padding-right: 0;

        input {
          font-size: 12px !important;
        }
      }

      &--overdue textarea {
        color: @c-danger;
      }

      &__datetimepickerHolder {
        position: absolute;
        right: 0;
        height: 25px;
        width: 25px;

        @media (max-width: @break-m) {
          top: -16px;
        }
      }
    }

    &__tags-button {
      display: inline-block;
      border-radius: 100px;
      border: none;
      outline: none;
      background-color: @c-grey-d;
      color: @c-grey-xd;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 5px 10px;
      margin-top: 15px;
      cursor: pointer;

      &:hover {
        background: @c-grey-xd;
        color: @c-white;
      }

      @media (max-width: @break-m) {
        font-size: 10px;
      }
    }
  }

  &__users {
    padding: 40px;
    border-left: 1px solid @c-border;
    background-color: @c-grey-ll;

    @media (max-width: @break-m) {
      padding: 20px;
    }

    fieldset {
      margin-top: 20px;
      background-color: @c-yellow;
      padding: 20px;
      border-radius: 2px;

      html.is-dark-mode & {
        background-color: @c-black-d;
      }

      textarea {
        margin-top: 0px;
        background: @c-white;
        border: 1px solid @c-border;
        padding: 20px;
        font-size: 14px;
        min-height: 62px;

        html.is-dark-mode & {
          border: 1px solid @c-black-l;
          background: @c-black-ll;
          color: @c-txt-l;
        }
      }
    }

    .FormControl {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }

    html.is-dark-mode & {
      background-color: @c-black-xxd;
      border-left-color: @c-black-ll;
    }

    &__section {
      margin-top: 20px;
    }

    &__avatar {
      display: inline-block;
      padding: 10px;
    }

    &__button-add {
      position: absolute;
      top: 5px;
      left: 100%;

      .app__btn {
        min-width: 80px;
      }
    }

    &__error {
      color: @c-danger;
      text-align: left;
    }
  }

  &__message {
    &-heading {
      text-align: left;
      font-size: 14px;
    }
  }
}