/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__shortcuts {
    .clearfix();

    display: flex;
    margin: 0;
    flex-direction: row;
    justify-content: space-between;

    &__col {
        display: block;
        float: left;
        list-style: none;
        padding: 0;
        margin: 0;

        > li {
            margin: 10px 0;
            text-transform: uppercase;
            font-size: 10px;
            text-align: left;
        }
    }

    .keyboard-shortcut__key {
        margin: 0 5px;

        &--disabled {
            background-color: @c-white;
            color: @c-grey-xd;
            border: @c-grey-xd solid 1px;
        }

        &--mark-selected-comment-status {
            font-size: 15px;
        }
    }

    @media (max-width: @break-m) {
        flex-direction: column;
    }
}
