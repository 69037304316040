/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
fieldset {
    max-width: 600px;

    p,
    ul {
        color: @c-txt-k;
        font-size: 20px;
    }

    @media (max-width: @break-6) {
        padding-left: 20px;
        padding-right: 20px;

        p {
            font-size: 16px;
        }

        ul {
            font-size: 14px;
        }

        h1 {
            font-size: 30px;
        }

        .form-control {
            label {
                display: block;
                position: static;
                width: auto;
                text-align: left;
            }
            .msg-error,
            .msg-note {
                position: static;
                transform: none;
                width: auto;
                padding: 10px 0 0;
            }
        }

        .btn,
        .btn-sub,
        .app__btn {
            display: block;
            width: 100%;
            margin: 0 0 10px 0 !important;
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
}

.o-form-control {
    .c-share-header {
        position: relative;
        right: 0;
        padding: 0 5px;
    }
}
