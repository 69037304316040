/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/*

	.LESS include - Typography

*/

/* Fonts -----------------------------------------------------------------------------------------------------------------*/

@font-face 		{
	font-family: 'ArrrowsRegular';
	src: url('https://static-assets.pageproof.com/fonts/arrrows/regular.eot');
	src: url('https://static-assets.pageproof.com/fonts/arrrows/regular.eot') format('embedded-opentype'),
		url('https://static-assets.pageproof.com/fonts/arrrows/regular.woff') format('woff'),
		url('https://static-assets.pageproof.com/fonts/arrrows/regular.ttf') format('truetype'),
		url('https://static-assets.pageproof.com/fonts/arrrows/regular.svg#ArrrowsRegular') format('svg');
	font-weight: normal;
	font-style: normal;
}

.ll				{ font-family: 'Circular Pro', 'HelveticaNeue', 'Helvetica Neue', Helvetica, 'Segoe UI', Arial, sans-serif; }

.txt			{ .ll; }
.hel			{ font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, "proxima-nova", "Segoe UI", Arial, sans-serif; }
.arr			{ font-family: "ArrrowsRegular"; .weight(normal); }
.no-dec			{ text-decoration: none; }
.sml-txt		{ font-size: 10px; .upper; }

body, select, input, textarea 	{
	color: @c-txt; line-height: 1.4; .txt;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 			{ text-rendering: optimizeLegibility; font-variant-ligatures: common-ligatures; color: @c-txt-d; .weight(100); }
h1 								{ font-size: 40px; margin: 0 0 @gap 0; }
h2 								{ font-size: 36px; margin: 0 0 @gap 0; }
h3 								{ font-size: 24px; margin: 0 0 @gap 0; }
h4 								{ font-size: 18px; margin: 0 0 @gap 0; .weight(600); }

p, ul, ol						{ font-size: 14px; margin: 0 0 @gap 0; line-height: 1.7; }
ul, ol							{ color: @c-txt-m; margin-left: @gap; padding-left: 0; }

hr								{ border: none; border-top: @border; margin: @gap*2 0; }

a					 			{ color: @c-link; .no-dec; }
a:hover 						{ color: @c-link-h; }

::-moz-selection 				{ background: @c-select; color: #fff; text-shadow: none; }
::selection 					{ background: @c-select; color: #fff; text-shadow: none; }
a:link 							{ -webkit-tap-highlight-color: rgba(0,0,0,0); }

blockquote						{
	padding: 0 @gap*2; color: @c-txt-m; margin: @gap*4 0; font-style: italic; font-size: 20px; .cen;
	.source						{ .block; font-style: normal; font-size: 14px; .upper; margin-top: @gap; }
}

@media all and ( max-width: @break-3 ) 		{
	
	
	
}

.registered-symbol {
	font-size: 0.9em
}