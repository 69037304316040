/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Tags {
    display: flex;
    flex-wrap: wrap;
    min-height: 46px;
    border-bottom: 1px solid #dcdcde;
    padding-top: 9px;
    padding-bottom: 5px;
    transition: border-bottom .2s @easing;

    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }

    &:not(&--readonly) {
        cursor: text;

        .Tag {
            cursor: default;
        }
    }

    &--focused {
        border-bottom-color: #a8a8ad;
    }

    .TagInput {
        float: left;
        margin-right: 10px;
        margin-bottom: 5px;
        max-width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__tag-wrapper {
        margin-right: 10px;
        margin-bottom: 5px;
    }
}