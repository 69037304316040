/* Copyright (C) PageProof Holdings limited - All Rights Reserved	
 * Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential	
* September 2014	
*/	

/*	
   .LESS include - Print	
*/	

@media print {	
 * { background: transparent !important; color: black !important; text-shadow: none !important; filter:none !important;	
 -ms-filter: none !important; } /* Black prints faster: sanbeiji.com/archives/953 */	
 a, a:visited { color: #444 !important; text-decoration: underline; }	
 a[href]:after { content: " (" attr(href) ")"; }	
 abbr[title]:after { content: " (" attr(title) ")"; }	
 .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after { content: ""; }  /* Don't show links for images, or javascript/internal links */	
 pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }	
 thead { display: table-header-group; } /* css-discuss.incutio.com/wiki/Printing_Tables */	
 tr, img { page-break-inside: avoid; }	
 @page { margin: 0.5cm; }	
 p, h2, h3 { orphans: 3; widows: 3; }	
 h2, h3{ page-break-after: avoid; }	
}