/* Copyright (C) PageProof Holdings limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * September 2014
 */

/*

	.LESS include - Global

*/

*,
*:before,
*:after							{ -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }
.standard-box 					{ -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box; }
html 							{ -webkit-font-smoothing: antialiased; }
body 							{ background: @c-body; overflow-x: hidden; overflow-y: hidden; touch-action: none;}
