/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__on-off-toggle {
    --toggle-width: 68px;
    --toggle-height: 36px;
    --toggle-padding: 4px;

    min-height: 36px + 12px;
    padding: 6px 0;
    position: relative;

    &--minimal {
        min-height: 0;
        padding: 0;
    }

    &--small {
        --toggle-width: 42px;
        --toggle-height: 24px;
        --toggle-padding: 4px;
    }

    &--tiny {
        --toggle-width: 32px;
        --toggle-height: 18px;
        --toggle-padding: 3px;
    }

    &__options {
        float: left;
        margin-right: 20px;
        background-color: @c-grey-toggle-l;
        transition: background-color 0.1s ease-in-out, opacity 0.1s ease-in-out;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
        border-radius: 1000px;
        overflow: hidden;
        position: relative;
        width: var(--toggle-width);
        height: var(--toggle-height);
        cursor: pointer;

        &--dark-grey {
            background-color: @c-grey-icon-dark;
        }

        html.is-dark-mode & {
            background-color: @c-grey-icon-dark;
        }

        &--red {
            background-color: @c-danger;

            html.is-dark-mode & {
                background-color: @c-danger;
            }
        }
    }

    &--on &__options {
        background-color: @c-green;

        html.is-dark-mode & {
            background-color: @c-green;
        }

        &--dark-green {
            background-color: @c-green-600;
        }
    }

    &--small &__options {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    &__message {
        padding-top: 8px;
        font-size: 14px;
        color: #999;
        display: inline-block;
        max-width: 450px;

        @media (max-width: @break-m) {
            display: inline-block;
        }

        &--white {
            color: @c-white;
        }
    }

    &__slider {
        position: absolute;
        left: 0;
        width: ~"calc(var(--toggle-height) - (var(--toggle-padding) * 2))";
        height: ~"calc(var(--toggle-height) - (var(--toggle-padding) * 2))";
        margin: var(--toggle-padding);
        border-radius: 50%;
        box-shadow: inset 0 -3px 0 rgba(0, 0, 0, .1);
        transition: left 0.2s @easing;
        background-color: @c-white;
    }

    &--on &__slider {
        left: ~"calc(100% - var(--toggle-height))";
    }

    &--disabled &__slider,
    &--disabled &__options {
        opacity: 0.6;
        cursor: not-allowed;
    }
}
