/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__pin-surface {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: grab;
    transition: top .5s @easing,
                left .5s @easing,
                right .5s @easing,
                bottom .5s @easing,
                opacity .5s @easing;

    &--hidden {
        top: 50%;
        left: 50%;
        right: 50%;
        bottom: 50%;
        opacity: 0;
    }

    &--commenting {
        cursor: crosshair;
    }
}
