/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/*

	.LESS include - Avatars

*/

.render-avatar(@s:60px)				{
	.dim(@s,@s); .in-block; .rel; .cen;
}


.avatar__inner {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: filter .25s @easing;
}

.avatar								{
	border-radius: 100%;
	.render-avatar; font-size: 20px; vertical-align: top; .rel; .no-select;
	.lbl							{ position: absolute; .block; width: 100%; height: 100%; .transition(250ms); background: @c-grey; color: @c-txt-m; .round(100); overflow: hidden; }
  	.lbl-test						{ position: absolute; .block; width: 100%; height: 100%; .transition(250ms); .round(100); overflow: hidden; }
	.avatar__inner					{ .greyscale; }
	.name							{ .sml-txt; .abs(@b: -@gap, @l: 50%); line-height: 1; color: @c-txt-k; .transition(250ms); .transform( translateX(-50%) translateY(@gap) ); opacity: 0; }
	&.is-mandatory					{
		&:after 					{
			@s: 28px;
			content:'M'; .circ(@s); background: @c-warning; color: #fff; .abs(@t:-4px, @r:-4px); line-height: @s - 8; .cen; .bold; font-size: 12px; border: 4px solid @c-body;
		}
	}
	&.is-approver, &.is-final-approver {
	  &:after 					{
		@s: 28px;
		content:'A'; .circ(@s); background: @c-warning; color: #fff; .abs(@t:-4px, @r:-4px); line-height: @s - 8; .cen; .bold; font-size: 12px; border: 4px solid @c-body;
	  }
	}
	.loading-avi					{ .fill; .round(200); .abs(@t:0, @l:0); background: rgba(255, 255, 255, 0.75); }
	&.avatar-m .loading-avi			{
		.load-anim:before 			{ .dim(100,100); margin: -50px 0 0 -50px; }
	}
	&.avatar-s .loading-avi			{
		.transform( scale(0.5) ); background: none;
		.load-anim:before 			{ .dim(80,80); margin: -40px 0 0 -40px; background: rgba(255, 255, 255, 0.75); }

		//.load-anim:before 			{ .dim(40,40); margin: -20px 0 0 -20px; }
	}
	&.not-registered				{
		.lbl						{ background: #fff; border: 1px dashed @c-border; }
		&.is-mandatory				{
			&:after 				{ background: darken(@c-grey,10%); }
		}
	}
	.proof-status					{
		@s : 6px;
		.circ(@s); .abs(@b:-@s*2, @l:50%); margin: 0 0 0 -@s/2; .transition( 250ms );
		&.status-g					{ background: @c-green; }
		&.status-d					{ background: @c-danger; }
	}

	.wf-step-proofers & 		{ margin-bottom: 40px !important; }

	&__revealOptions,
	&:hover,
	&:active {
		.name						{ .transform( translateX(-50%) translateY(0) ); opacity: 1; }
		.avatar-options				{ .transform( translateX(-50%) translateY(0) ); opacity: 1; pointer-events: auto; }
		.proof-status				{ .transform( translateY(24px) ); pointer-events: auto; }
	}
	&.being-dragged					{
		opacity: 0.25;
		.name,
		.avatar-options				{ opacity: 0; }
	}

	&.in-colour .avatar__inner		{ .greyscale(0); }
	@media (max-width: @break-m) {
		transform: scale(0.67);
	}
}



#proof-info .avatar 				{
	.name							{ bottom: -@gap*1.25; }
	&:hover,
	&:active						{
		.avatar__inner				{ .greyscale(0); }
		.proof-status				{ .transform( translateY(22px) ); }
	}
}

.owned-by,
.wf-owned-by						{
	padding-bottom: @gap; .cen;
	p 								{ .sml-txt; margin: 0 0 @gap/2; }
	.avatar 						{
		.name						{ .transform( translateX(-50%) translateY(0) ); opacity: 1; }
		@media (max-width: @break-m) {
			height: 60px;
			width: 60px;
			line-height: 60px;
			font-size: 20px;
		}
	}
	&.wf-owned-by					{ padding-bottom: @gap*3; }
}

.final-wf-step .avatar 				{
	.proof-status					{ .transform( translateY(24px) ); }
}

.editor-email-input					{
  	width:70%;
}

.avatar-options	{
	position: absolute;
	bottom: 100%;
	left: 50%;
	border-radius: 3px;
	background-color: #333;
	box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	margin: 0 0 20px 0;
	line-height: 1.5;
	width: max-content;
	list-style: none;
	text-align: center;
	z-index: 1000;
	padding: 6px 0;
	pointer-events: none;
	cursor: default;
	opacity: 0;
	transform: translateX(-50%) translateY(-20px);
	transition: transform 250ms @easing,
	            opacity 250ms @easing;

	&:after {
		.bottomArrow;
		color: #333;
		text-shadow: rgba(0,0,0, 0.05) 0 5px 5px;
		z-index: -1;
	}

	li {
		height: 38px;
		cursor: pointer;
		line-height: 38px;
		border-radius: 3px;
		text-align: left;
		padding: 0 20px;

		&:hover {
			background-color: #555;
		}
	}



	.ico							{ .circ(20px); .in-block; vertical-align: middle; line-height: 20px; .cen; margin-right: @gap/2; background: #ccc; color: #fff; }
	.i-remove						{ background: @c-danger; }
	.i-mandatory					{ background: @c-grey; }
	.i-replace						{ background: @c-green; }
	.i-nudge						{ background: @c-grey; }
	.i-skip							{ background: @c-grey; }
  	.i-admin						{ background: @c-grey; }
  	.i-approver						{ background: @c-grey; }

}

.avatar.is-mandatory				{
	.avatar-options					{
		.i-mandatory 				{ background: @c-warning; }
	}
}
.avatar.is-nudged					{
	.avatar-options					{
		.i-nudge 					{ background: @c-warning; }
	}
}
.avatar.is-skipped					{
	.avatar-options					{
		.i-skip 					{ background: @c-danger; }
	}
}
.avatar.is-admin					{
  .avatar-options					{
	.i-admin 						{ background: @c-warning; }
  }
}
.avatar.is-approver					{
  .avatar-options					{
	.i-approver 					{ background: @c-warning; }
  }
}
.avatar:not(.not-registered):hover,
.avatar:not(.not-registered):active,
.final-wf-step--active .avatar:not(.not-registered) {
	.avatar__inner					{ .greyscale(0); }
	.lbl							{ background: @c-green; color: #fff; }
}

.final-wf-step--finished {
  .avatar__inner				{ .greyscale(0); }
  .lbl							{ background: @c-green; color: #fff; }
}

.avatar-s							{
	.render-avatar(40px); font-size: 12px;
	&.is-mandatory:after 			{ top: -8px; right: -8px; .transform( scale(0.75) ); }
	//.loading-avi					{ .transform( scale(0.65) ); }
}
.avatar-xs							{
  	.render-avatar(30px); font-size: 8px; .upper;
  	.loading-avi					{ .transform( scale(0.55) ); }
}
.avatar-m							{
	.render-avatar(100px); font-size: 40px; .upper;
	//.lbl							{ color: @c-txt; }
}
.avatar-l							{
	.render-avatar(150px); font-size: 80px; .upper;
	//.lbl							{ color: @c-txt; }
}

#proof-info {
	.wf-step .avatar {
		margin-left: -15px !important;
	}
}

.avatar {
	.header__avatar__tooltip {
		position: absolute;
		font-size: 10px;
		width: 300px;
		top: 0;
		left: 0;
		line-height: 60px;
		text-align: right;
		text-transform: uppercase;
		color: #999;
		transition: opacity 0.25s @easing,
					transform 0.25s @easing;
		@media (max-width: @break-m) {
			display: none;
		}
	}
	&:not(:hover) .header__avatar__tooltip {
		opacity: 0;
		transform: translateX(-400px);
	}
	&:hover .header__avatar__tooltip {
		transform: translateX(-310px);
		opacity: 1;
	}
}
