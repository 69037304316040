/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Volume {
  position: relative;
  display: inline-block;

  &--icon {
    svg {
      fill: @c-grey-icon;
      width: 28px;
      height: 28px;
    }
  }

  &--icon:hover {
    svg {
      fill: #595959;
    }
  }

  &--slider {
    position: absolute !important;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    height: 0 !important;
    width: 16px !important;
    transition: .25s @easing width,
                .25s @easing height;

    .Range__button {
      width: 26px;
      height: 26px;
      display: none;
    }
  }

  &:hover {
    .Volume--slider {
      height: 100px !important;
      border: 1px solid @c-border;
      border-radius: 10px;
    }

    .Range__button {
      display: inherit;
    }
  }
}
