/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Range {
  position: relative;
  border-radius: 30px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .05);
  background-color: #fff;
  cursor: pointer;

  html.touch &:before {
    content: ' ';
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    border-radius: 100px;
  }

  &__track {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__line {
    position: absolute;
    background-color: #fbfbfb;
    border-radius: 6px;
  }

  &--horizontal &__line {
    top: ~"calc(50% - 3px)";
    left: 0;
    height: 6px;
    width: 100%;
  }

  &--vertical &__line {
    bottom: 0;
    left: ~"calc(50% - 3px)";
    width: 6px;
    height: 100%;
  }

  &__button {
    position: absolute;
    width: 30px;
    height: 30px;
  }
  
  &__button:before {
    content: ' ';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background-color: @c-green;
    box-shadow: inset 0 -3px 0 rgba(0, 0, 0, .15);
    border-radius: 100%;
    transform-origin: center center;
    transition: transform .15s ease-in-out,
                background-color .2s ease-in-out;
  }

  &__button:hover:before,
  &--active &__button:before {
    background-color: @c-green-d;
  }

  &--active &__button:before {
    transform: scale(1.47);
  }

  &--horizontal {
    height: 30px;
  }

  &--vertical {
    width: 30px;
  }

  &--horizontal &__button {
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &--vertical &__button {
    left: 50%;
    transform: translateX(-50%) translateY(50%);
  }
}
