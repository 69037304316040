/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__select {
    position: relative;
    display: inline-block;
    text-align: left;
    line-height: 1.4;

    &--is-open {
        z-index: 102;
    }

    &:not(&--block) {
        max-width: 300px;
    }

    &--block {
        display: block;
    }

    &:not(&--is-open) &__options {
        opacity: 0;
        pointer-events: none;

        html.is-ie & {
            display: none;
        }
    }

    &--up:not(&--is-open) &__options {
        transform: translateY(46px);
    }

    &--down:not(&--is-open) &__options {
        transform: translateY(-46px);
    }

    &__label {
        position: relative;
        border: 1px solid #dcdcde;
        border-radius: 1px;
        font-size: 14px;
        background: #eff1f2;
        padding: 12.6px 60px 12.6px 15px;
        color: #7f8c8d;
        cursor: pointer;
        z-index: 101;
        outline: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (max-width: @break-m) {
            font-size: 13px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .app__select--is-open & {
            color: #4e5758;
            border-color: #a8a8ad;

            &:after {
                color: #a8a8ad;
                border-color: #a8a8ad;
            }
        }

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            content: 'u';
            font-family: 'ArrrowsRegular';
            font-weight: normal;
            line-height: 44px;
            text-align: center;
            padding: 0 14px;
            border-left: 1px solid #dcdcde;
            font-size: 20px;
            background: #f7f8f9;
            color: #dcdcde;

            @media (max-width: @break-m) {
                padding-left: 12px;
                padding-right: 12px;
                line-height: 40px;
                font-size: 18px;
            }
        }

        .app__select--up &:after {
            content: 'U';
        }
    }

    &__options {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 100%;
        margin: 0;
        padding: 0;
        border: 1px solid #dcdcde;
        border-top: none;
        border-bottom-right-radius: 1px;
        border-bottom-left-radius: 1px;
        max-height: 300px;
        overflow-y: auto;
        background: #eff1f2;
        z-index: 100;
        transition: transform .25s @easing,
                    opacity .25s @easing;

        .app__select--up & {
            top: auto;
            bottom: 100%;
            margin-bottom: -1px;
        }

        .app__select--down & {
            margin-top: -1px;
        }

        .page__proof__toolbar__group & {
            z-index: 551;
        }

    }

    &__option {
        display: block;
        font-size: 14px;
        padding: 12.6px 15px;
        text-decoration: none;
        border-top: 1px solid #dcdcde;
        white-space: nowrap;
        color: #999999;
        cursor: pointer;

        @media (max-width: @break-m) {
            font-size: 13px;
            padding-top: 9px;
            padding-bottom: 9px;
        }

        &:not(&--selected):hover {
            background-color: #fff;
        }

        &--selected {
            background-color: rgba(255, 255, 255, .8);
            cursor: default;
        }
    }
}
