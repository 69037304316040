/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Tabs {
    &__navigation {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        text-align: center;
        position: sticky;
        top: 0;
        background: fade(@c-grey-ll, 90%);
        z-index: 2;

        html.is-dark-mode & {
            background: fade(@c-black-ll, 89%);
        }

        @media (max-width: @break-m) {
            flex-wrap: wrap;
        }
    }

    &__content {
        position: relative;
        padding-top: 1px;
    }
}

.Tab--transition {
    &-enter,
    &-leave {
        top: 0;
        left: 0;
        right: 0;
        transition: opacity .15s @easing,
                    transform .15s @easing;
    }

    &-enter {
        opacity: 0;
        transform: translateY(-20px);
        transition-delay: .2s;
    }

    &-enter-active,
    &-leave {
        transform: translateY(0);
        opacity: 1;
    }

    &-leave {
        position: absolute;
    }

    &-leave-active {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.TabButton {
    position: relative;
    display: inline-block;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid transparent;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: bold;
    color: #999;
    margin: 0 20px;
    padding: 12px 0;
    transition: color .25s @easing,
                border-bottom-color .25s @easing;

    &:focus {
        outline: none;
    }

    &:not(&--selected):hover,
    &:not(&--selected):focus {
        color: #444;
    }

    &--selected {
        color: @c-green;
        border-bottom-color: @c-green;

        &:focus {
            color: @c-green-d;
            border-bottom-color: @c-green-d;
        }
    }
}
