/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.workflow-dashboard, .o-workflow-section {
    .dashboard__proof__preview__image img {
        position: relative;
        z-index: 1;
    }
    .dashboard__proof__preview:after {
        z-index: 0;
    }
    .create-workflow-icon {
        font-size: 80px;
        color: @c-green;
    }
    .dashboard__proof__new-workflow .dashboard__proof__meta__title {
        color: @c-green;
    }
    :not(.dashboard__proof__new-workflow) .dashboard__proof__meta__title {
        color: @c-txt;

        html.is-dark-mode & {
            color: @c-grey-l;
        }
    }
}