/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 40px);
    max-width: 500px;
    transform: translateX(-50%) translateY(-50%);

    &__progress {
        overflow: hidden;

        &,
        &__bar {
            height: 15px;
            border-radius: 7.5px;
            background-color: #d3d9da;
        }

        &__bar {
            position: relative;
            width: 0;
            background-color: @c-green;
            transition: width .1s @easing;
        }
    }

    &:not(.ng-leave) &__progress__bar {
        @keyframes swish {
            0% { transform: translate3d(-100%, 0, 0); opacity: 1; }
            20% { opacity: 1; }
            100% { transform: translate3d(0, 0, 0); opacity: 0; }
        }

        &:before {
            content: ' ';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba(255, 255, 255, .5);
            border-radius: 7.5px;
            animation: swish infinite 1.5s;
            transform: translate3d(0, 0, 0);
        }
    }

    &--red &__progress__bar {
        background-color: @c-danger;
    }

    &.ng-enter,
    &.ng-leave {
        transition: opacity .25s @easing;
    }

    &.ng-enter,
    &.ng-leave-active {
        opacity: 0;
    }

    &.ng-enter-active {
        opacity: 1;
    }

    &__progress,
    &__message {
        margin: 40px 0;
    }

    &__message {
        text-align: center;
        font-size: 16px;
        color: @c-green;
    }
}
