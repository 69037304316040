/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.DateRangePicker {
  flex-direction: column;

  .rdrDateDisplayWrapper {
    order: 2;
  }

  .rdrMonthAndYearWrapper {
    order: 1;
    select {
      font-size: 12px;
      color: @c-green;

      &:hover {
        background-position: right 8px center;
      }
    }

    select option {
      background-color: @c-txt-d;
      color: @c-body;
    }
  }

  .rdrMonths {
    order: 3;
    align-items: center;
  }

  .rdrMonth {
    width: 20em;
  }

  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    top: 4px;
    bottom: 4px;
  }

  .rdrDayStartOfMonth .rdrStartEdge,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayStartOfWeek .rdrStartEdge,
  .rdrDayEndOfWeek .rdrStartEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge,
  .rdrStartEdge {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .rdrDayEndOfMonth .rdrEndEdge,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayEndOfWeek .rdrEndEdge,
  .rdrDayStartOfWeek .rdrEndEdge,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge,
  .rdrEndEdge {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .rdrDayToday .rdrDayNumber span:after {
    background: @c-green;
  }

  .rdrNextPrevButton {
    background: none;
    position: relative;
    width: 20px;
    height: 20px;

    &:hover {
      background: none;
    }

    i {
      opacity: 0;
    }

    .arrow {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background-image: url("../../img/interface/arrow-down.svg");

      &--pulsing-circle {
        position: absolute;
        top: 5px;
        left: 5px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.1);
        opacity: 0.5;

        &:before {
          content: '';
          position: relative;
          display: block;
          width: 300%;
          height: 300%;
          box-sizing: border-box;
          margin-left: -100%;
          margin-top: -100%;
          border-radius: 45px;
          background-color: #929bc0;
          animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
        }
      }

      @keyframes pulse-ring {
        0% {
          transform: scale(.1);
        }
        50% {
          transform: scale(1);
        }
        80%, 100% {
          transform: scale(2);
          opacity: 0;
        }
      }
    }

  }

  .rdrPprevButton {
    .arrow {
      transform: rotate(90deg);
    }
  }

  .rdrNextButton {
    .arrow {
      transform: rotate(270deg);
    }
  }
}
