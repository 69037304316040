/* Copyright (C) PageProof Holdings limited - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * September 2014
 */

/*

        .LESS include - Forms

*/

input, textarea, button, select { font-family: inherit; font-size: inherit; }

select:not(.unstyled),
textarea,
input[type="text"],
input[type="email"],
input[type="date"],
input[type="password"]			{

    border: none; border-bottom: @border; font-size: 18px; outline: none; background: none; .transition( 200ms ); .round(0);
    padding: 0; height: 46px; width: 100%;
    -webkit-appearance: none; -webkit-font-smoothing: antialiased; .hwAccel;
    &:focus						{ border-color: darken( @c-border, 20% ); }
    //&:disabled, &[disabled]     { border-bottom: none }

    @media (min-width: @break-m) {
        &.w-man-switch {
            padding-right: 120px;
        }
    }

}
textarea						{ height: 100px; padding-top: 6px; resize: none; }
.auto-textarea					{
	height: 40px; max-height: 350px; transition: none; overflow-x: hidden; overflow-y: auto;
	//&.set 						{ overflow: scroll; }
    @media (max-width: @break-m) {
        font-size: 18px;
        padding-top: 6px;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 0) {

    select:not(.unstyled)						{
        background: url(img/interface/bg-select.png) no-repeat 100% 0; background-size: 45px 90px; cursor: pointer;
        &:hover					{ background-position: 100% 100%; }
    }

}


label:not(.unstyled)							{ font-size: 12px; .bold; .upper; .rgt; color: @c-txt-m; padding: @gap*.85 @gap 0 0; }

.msg-error,
.msg-note {
    font-size: 10px;
    .upper;
    .abs(@t: 50%, @l: 100%);
    transform: translateY(-50%);
    padding: 0 0 0 @gap;
    width: 300px;

    &__message {
        line-height: 25px;
        display: flex;

        &__inner {
            margin-left: 10px;
        }
    }


    @media (min-width: 600px) and (max-width: 650px) {
        transform: translateX(-25px);
}}
.msg-error						{
    color: @c-danger; display: none;
    &:before					{ background: @c-danger; }
}
.msg-note						{ color: @c-txt-m; }

.placeholderTxt					{ color: #ccc; }
::-webkit-input-placeholder 	{ .placeholderTxt }
:-moz-placeholder				{ .placeholderTxt }
::-moz-placeholder				{ .placeholderTxt }
:-ms-input-placeholder			{ .placeholderTxt }


fieldset {
    padding: 0;
    border: none;
    max-width: 600px;
    margin: auto;

    h1 {
        .cen;
        padding-top: @gap*3;
        margin-bottom: @gap*2 + 3;
    }

    .small-caps {
        text-transform: uppercase;
        font-size: 12px;
        text-align: center;
    }

    .centeredAccessMessage {
       text-align: center;
    }

    .access-message-show {
        animation-name: show;
        animation-duration: 1.5s;
        display: block;
    }

    .access-message-hide {
        animation-name: hide;
        animation-duration: 1.5s;
        display: none;
    }

    @-webkit-keyframes hide {
        0% {opacity: 1;}
        100% {opacity: 0;}
    }

    @-webkit-keyframes show {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }
}

.form-control {
    width: 100%;
    margin-bottom: @gap;
    .rel;

    label:not(.unstyled):not(.c-share-header) {
        .abs(@t: 0, @r: 100%);
        width: 200px;
        .hwAccel;

        @media (max-width: @break-m) {
            position: static;
        }

        .form-control__container--small & {
            @media (max-width: @break-4) {
                position: static;
            }
        }

        .form-control__container--medium & {
            @media (max-width: @break-3) {
                position: static;
            }
        }
    }

    &.in-error {
        label {
            color: @c-danger;
        }

        input {
            border-color: @c-danger;
        }

        .msg-error {
            .block;
        }
    }

    input {
        &:disabled,
        &.disabled,
        &[disabled] {
            border-bottom: none;
        }
    }

    &__message {
        padding-top: 15px;
        font-size: 14px;
        color: @c-txt-m;
    }

    @media (max-width: @break-m) {
        ::-webkit-input-placeholder, input {
            font-size: 18px;
        }
    }

    .app__select {
        margin-top: 10px;
    }
}

.radio-check					{
    min-height: 46px;
    .options					{
        label					{
            .in-block; width: auto; position: static; .weight(normal); text-transform: none; font-size: 16px; cursor: pointer; padding: @gap*.65 @gap 0 0;
            input				{ margin-right: @gap/2; }
            &:hover				{ color: @c-txt; }
        }
    }
}
.man-switch						{
    @s: 24px;
    .abs(@t: 50%, @r: 0); margin-top: -15px;
    input						{ display: none; }
    label						{
        position: static; margin: 0; width: auto; padding: 0; cursor: pointer; font-size: 0;
        span					{ .in-block; vertical-align: middle; font-size: 12px; }
        .lbl					{
            color: @c-grey; padding-right: @gap/4; .rel; top: 1px; .hwAccel; .transition( 500ms );
        }
        .ico					{
            .dim(@s,@s); .round(@s); background: @c-grey; color: @c-grey; .cen; line-height: @s + 2; font-size: 10px; .weight(normal); .transition( 500ms );

            line-height: 24px;

            &:hover				{ background: @c-grey-xd; color: @c-grey-xd; }
        }
    }
    input:checked + label 		{
        .lbl					{ color: @c-warning; }
        .ico					{ background: @c-warning; color: #fff; }
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    .man-switch label .ico      { line-height: 26px; }

}

.file-input										{
	.rel; margin-bottom: @gap;
	input[type=text]							{ pointer-events: none; height: 36px; }
	input[type=file]							{ .abs(@t: 0, @r: 0); width: 100%; opacity: 0; cursor: pointer; }
	span										{
		.block; line-height: 36px; background: @c-grey-d; color: @c-txt; .bold; padding: 0 @gap; .abs(@t: 0, @r: 0); .round(@r); font-size: 12px; .upper;
	}
	&:hover span								{ background: @c-grey-xd; }
}
.form-actions					{ padding-top: @gap*2; .cen; }

.login-form-actions				{
	//.rgt;
	p							{ font-size: 12px; .bold; .upper; color: @c-txt-m; margin-bottom: @gap*3;}
	p strong					{ color: @c-txt; }
	p a							{
		text-decoration: underline; color: @c-txt-m;
		&:hover,
		&:active				{ color: @c-green; }
	}
  @media (max-width: 600px) {
    &__forgot-password {
      position: absolute;
      left: 0;
      right: 0;
      transform: translateY(135px);
    }
  }
}

.auto-tags						{

	.tag-output					{ min-height: 46px; border-bottom: @border; padding-top: 7px; }
	.fake-tag-input				{ border: none; .in-block; width: 100px; height: 30px; margin-bottom: @gap/2 - 4; vertical-align: middle; }
    .tag-output {
        cursor: pointer;
        margin-bottom: @gap/2;
        vertical-align: middle;

        @media (max-width: @break-m) {
            font-size: 10px;
        }
    }
	&.active					{
		.tag-output				{ border-color: darken( @c-border, 20% ); }
	}

}

.datatimepicker-holder {
    position: absolute;
    right: 32px;
    .transition(250ms);

    &__right {
        transform: translateX(32px);
    }
}

.reminder-holder {
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 1000;
}

@media (max-width: @break-m) {
    .datatimepicker-holder,
    .reminder-holder {
        top: 27px;
    }
}

.autocomplete-output			{ .abs(@t: 6px, @l: 0); width: 100%; font-size: 24px; z-index: -1; color: #ccc; }

.in-form-loader					{
	.abs(@t: 50%, @l: -@gap*2); .transform( scale(0.5) );
	.load-anim					{ top: 0; left: 0; }
}

.select-replace                 {
    width: 100%; .rel; z-index: 2;
    .output                     {
        width: 100%;
        &.selection-made        { color: @c-txt; }
    }
    &.disable-mouse              { pointer-events: none; }
}

@media all and ( max-width: @break-4 ) 	{

	fieldset							{ max-width: 400px; }

}

.app__activate {
    fieldset {
        max-width: 660px;
    }

    &-user_message {
        &__text {
            color: @c-txt-k;
            font-size: 24px;

            .green {
                text-transform: none;
                font-size: 24px;
                font-weight: normal;
                word-break: break-word;
            }

            @media (max-width: @break-m) {
                font-size: 20px;

                .green {
                  font-size: 20px;
                }
            }
        }

        &__small-caps {
            color: #999;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.adobe-indesign {
    max-width: 625px;
}

.user-profile {
  max-width: 650px;

  @media (max-width: @break-m) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
