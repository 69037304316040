/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/*

	.LESS include - Main menu

*/
#show-menu {
    @s : @head-h - 40;
    .block;
    .dim(@s, @s);
    .round(@s);
    overflow: visible;
    .abs(@t: 50%, @r: @gap);
    margin-top: -@s/2;
    .cen;

    > span {
        .block;
        .fill;
        line-height: @s;
        .sml-txt;
        color: @c-txt-m;
    }

    .avatar {
        .abs(@t:0px, @l:0px);

        @media (max-width: @break-m) {
            left: 16px;
        }
    }
}
#main-menu,
#team-menu {
    background: @c-green;

    h2 {
        color: #fff;
    }

    .contents {
        overflow-y: auto;
        height: ~"calc(100% - 100px)";
        -webkit-overflow-scrolling: touch;
    }

    @media (max-width: @break-m) {
        .head {
            height: 67px;
            padding: 0 20px;
        }

        h2 {
            font-size: 22px;
            line-height: 67px;
        }

        .contents {
            height: ~"calc(100% - 67px)";
        }
    }
}
.main-menu-options {
    padding: @gap*2;

    a {
        .block;
        color: #fff;
        font-size: 12px;
        padding: @gap 5px;
        .rel;
        .transition( 200ms );
        font-weight: bold;
        text-transform: uppercase;
        height: 57px;
        overflow: hidden;

        &:hover,
        &:active {
            background: rgba(255,255,255,0.05);

            .main-menu-options__tip {
                opacity: 1;
                transform: translateX(0);
            }
        }

        > span:not(.main-menu-options__tip) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -5px;
            left: auto;
            bottom: auto;
            width: 35px;
            height: 35px;

            svg {
                fill: #fff;
                transform: rotate(270deg);
            }
        }
    }

    &__tip {
        display: inline-block;
        color: #fff;
        font-size: 12px;
        text-transform: none;
        font-weight: normal;
        opacity: 0;
        margin-left: 20px;
        transform: translateX(130px);
        transition: opacity 0.2s @easing,
                    transform 0.2s @easing;

        @media (max-width: @break-m) {
            display: none;
        }
    }

    &__separator {
        height: 2px;
        background-color: rgba(255, 255, 255, .2);
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
