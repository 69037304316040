/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.u-text-centre {
  text-align: center;
}

.u-text-left {
  text-align: left !important;
}

.u-text-right {
  text-align: right;
}

.u-no-top-pad {
  padding-top: 0 !important;
}

.u-no-top-mar {
  margin-top: 0 !important;
}

.u-max-width-eighty {
  max-width: 80% !important;
}

.u-first-top-mar-gap {
  .no-arrow {
    margin: @gap 0 0 0 !important;
  }
}

.u-no-after {
  &:after {
    content: none !important;
  }
}

.u-v-align-top {
  vertical-align: top !important;
}

.u-no-input-border {
  input[type="text"] {
    border: none !important;
  }
}

.u-creator-gap {
  padding: @gap 0 @gap*4 0 !important;

  @media (max-width: @break-m) {
    padding: @gap 0 @gap*2 0 !important;
  }
}