/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.domain-admin-dashboard {
    @media (min-width: @break-m) {
        margin-left: 230px;
    }

    .tabs__nav {
        display: none;
    }

    .dashboard__proof {
        &__thumb {
            height: 187px;
            background-color: @c-grey;
            position: relative;

            .avatar {
                position: absolute;
                top: 50%;
                left: 50%;
                .transform(translate3d(-50%, -50%, 0));

                width: 150px;
                height: 150px;
                display: inline-block;
                text-align: center;
                line-height: 150px;
                font-size: 70px;

                :not(:hover) .lbl {
                    background-color: #fff;
                }
            }
        }

        &__preview {
            &::after {
                display: none;
            }

            &__image {
                width: 200px;
            }
        }

        &--not-activated {
            .dashboard__proof__footer__status {
                display: none;
            }

            &:not(:hover) .dashboard__proof__footer__options {
                transform: translateY(0) !important;
                opacity: 1 !important;
            }
        }

        &--is-admin .dashboard__proof__thumb {
            background-color: @c-green;

            .avatar:hover .lbl {
                background: @c-txt-m;
            }
        }

        &--list {
            .avatar {
                width: 60px;
                height: 60px;
                line-height: 60px;
                font-size: 28px;
            }

            // Show both delete and info icon on mobile list view
            @media (max-width: @break-m) {
                .dashboard__proof__footer__info {
                    display: none;
                }

                .dashboard__proof__footer__options {
                    display: block;
                    bottom: 32px !important;
                }
            }
        }
    }
}
