/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Dropdown {
    position: relative;
    display: inline-block;
    max-width: 100%;
    text-align: left;
    -webkit-user-select: none;

    &:not(.Dropdown--open) &__options {
        visibility: hidden;
        opacity: 0;
    }

    &__options {
        position: absolute;
        z-index: 9999999999;
        padding-top: 6px;
        padding-bottom: 6px;
        transition: opacity .25s ease-in-out;
        overflow-y: auto;
        max-height: 75vh;
        min-width: 100%;
        word-wrap: break-word;

        @media(max-height: @break-6) {
            max-height: 50vh;
        }

        .show-label {
            display: none;
        }
    }

    &--nowrap {
        .Dropdown__options {
            white-space: nowrap;
        }
    }

    &--up &__options {
        bottom: 100%;
    }

    &__label {
        .show-options {
            display: none;
        }
    }

    &__label &Option__label {
        padding-right: 42px;
    }

    &__arrow {
        position: absolute;
        top: ~"calc(50% - 3px)";
        right: 16px;
        cursor: pointer;

        // http://apps.eky.hk/css-triangle-generator/
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 5px 0 5px;
    }

    &--up &__arrow {
        border-width: 0 5px 6px 5px;
    }

    &--default {
        color: #333;

        &.Dropdown--down .Dropdown__arrow {
            border-color: #fff transparent transparent transparent;
        }

        &.Dropdown--up .Dropdown__arrow {
            border-color: transparent transparent #fff transparent;
        }

        .Dropdown {
            &__options {
                border-radius: 4px;
                background-color: #fff;
                box-shadow: 0 2px 10px rgba(0, 0, 0, .1);

                html.is-dark-mode & {
                    background-color: #2d2d2d;
                }
            }

            &__label .DropdownOption {
                border-radius: 4px;
                background-color: #444;
                color: #fff;
                transition: background-color .15s ease-in-out;
                box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .4);

                .Dropdown--open &,
                &:active,
                &:hover,
                &.hover {
                    background-color: #333;
                }

                .DropdownOption__icon path:not([fill="none"]) {
                    fill: #fff !important;
                }
            }

            &__label .DropdownOption--muted {
                @primary: @c-grey-xd;

                box-shadow: inset 0 0 0 1px @c-grey-d;
                background-color: #fff;
                color: @primary;

                .Dropdown--open &,
                &:active,
                &:hover,
                &.hover {
                    background-color: #f6f6f6;
                }

                .DropdownOption__icon path:not([fill="none"]) {
                    fill: @primary !important;
                }

                ~ .Dropdown__arrow {
                    border-top-color: @primary;
                    border-bottom-color: @primary;
                }
            }

            &__label &Option__icon path:not([fill="none"]) {
                fill: rgba(255, 255, 255, .75) !important;
            }
        }
    }

    &--light {
        color: @c-grey-xd;

        &.Dropdown--open .Dropdown__label .DropdownOption {
            color: #4e5758;
            border-color: #a8a8ad;
        }

        &.Dropdown--down .Dropdown__arrow {
            border-color: @c-grey-xd transparent transparent transparent;
        }

        &.Dropdown--up .Dropdown__arrow {
            border-color: transparent transparent @c-grey-xd transparent;
        }

        .Dropdown {
            &__options {
                background-color: @c-grey-l;
                border-radius: 1px;
                padding-top: 0;
                padding-bottom: 0;
            }

            &__label .DropdownOption {
                border-radius: 1px;
                background-color: @c-grey-l;
                color: @c-grey-xxdd;
                border: 1px solid @c-border;

                &:hover,
                &:active,
                &.hover {
                    background-color: @c-grey-l;
                }
            }

            &Option {
                height: 46px;
                border: 1px solid @c-border;
                transition: background-color .15s ease-in-out;

                &:hover,
                &.hover {
                    background-color: rgba(19, 139, 59, .25);;
                    color: @c-grey-xxdd;
                }

                &__label {
                    line-height: 2;

                    strong {
                        font-weight: normal;
                    }
                }
            }
        }

        &.Dropdown--up .Dropdown__options {
            padding-bottom: 1px;

            .DropdownOption {
                border-bottom: none;

                &--selected {
                    color: @c-green;
                    background-color: @c-white;
                }
            }
        }

        &.Dropdown--down .Dropdown__options {
            padding-top: 1px;

            .DropdownOption {
                border-top: none;

                &--selected {
                    color: @c-green;
                    background-color: @c-white;
                }
            }
        }
    }

    &--icon-based,
    &--video-quality {
        vertical-align: middle;
        color: @c-grey-xd;

        .Dropdown {
            &__arrow {
                display: none;
            }

            &__options {
                background-color: #fff;
                margin-bottom: 20px;
                padding-bottom: 0;
            }

            &__label {
                .DropdownOption {
                    background-color: #fff;
                    color: @c-grey-xd;

                    &__icon path:not([fill="none"]) {
                        fill: @c-grey-xd;
                    }

                    &__label {
                        display: none;
                    }
                }
            }

            &__label &Option__icon path:not([fill="none"]) {
                fill: @c-grey-xd;
            }

            &Option {
                &__label {
                    padding-right: 10px;
                }

                &__icon {
                    line-height: 35px;
                    width: 25px;

                    svg {
                        margin-top: 0;
                    }
                }
            }

            &Separator {
                margin-left: 16px;
            }
        }

        .DropdownOption:hover .DropdownOption__label {
            color: #444;
        }
    }

    &--video-quality {
        .Dropdown__options {
            .DropdownOption--has-icon {
                padding-left: 16px;
            }

            .DropdownOption__icon {
                display: none;
            }
        }

        .DropdownOption {
            padding-right: 30px;
        }

        .hd-icon {
            height: 24px;
            position: absolute;
            top: 4px;
            right: 5px;
        }

        .Dropdown__label {
            .show-options, .DropdownOption__label > div > div {
                display: inline;
            }

            .show-options {
                padding-left: 10px;
            }
        }
    }

    &--tool {
        .Dropdown {
            &__label {
                .DropdownOption {
                    height: 42px;
                    width: 42px;
                    background-color: #fff;
                    box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.2);
                    border-radius: 100%;
                    padding-left: 0;

                    &__label {
                        padding: 13px 0;
                    }
                }

                .Dropdown__arrow {
                    display: none;
                }
            }

            &__options {
                background-color: #fff;
                width: 44px;
                border-radius: 44px;
                box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.2);
                margin-bottom: 14px;
                padding-top: 0;
                padding-bottom: 0;

                .DropdownOption {
                    padding-left: 0;

                    &__label {
                        padding: 14px 8px;

                        strong {
                            font-weight: 400;
                        }
                    }
                }
            }

            &Option__label {
                text-align: center;
                color: @c-txt-m;
                font-size: 12px;
            }
        }

        &.Dropdown--open .Dropdown__label .DropdownOption {
            background-color: @c-grey;
        }
    }

    &--comment-options {
        color: #fff;
        width: 20px;
        height: 20px;

        .Dropdown {
            &__arrow {
                display: none;
            }

            &Option {
                &__icon {
                    width: 20px;
                    height: 20px;
                    left: -2px;
                    top: -2px;
                    transform: none;

                    svg {
                        transform: rotate(90deg);
                        margin-top: 0;

                        path {
                            fill: @c-txt-m;
                        }
                    }
                }

                &--has-icon {
                    padding-left: unset;
                }

                &:hover {
                    background-color: unset;
                }
            }

            &__options {
                right: 0;
                top: 24px;
                padding-top: 0;
                padding-bottom: 0;
                background-color: #333;
                border-radius: 3px;

                .DropdownOption:hover {
                    background-color: #555;
                }
            }
        }
    }
}

.DropdownOption {
    position: relative;
    cursor: pointer;
    padding-left: 16px;

    &--has-icon {
        padding-left: 52px;
    }

    &--has-button {
        display: flex;
        align-items: center;
    }

    &:focus {
        outline: none;
    }

    strong {
        font-weight: 500;
    }

    &:hover,
    &.hover,
    &:active,
    &--selected {
        background-color: rgba(0, 0, 0, .05);
    }

    &--has-button,
    &--selected {
        cursor: default;
    }

    &--nowrap {
        .DropdownOption__label {
            &, &__title {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                &--flex {
                    display: flex;
                }

                &--truncated {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }

    &__icon {
        position: absolute;
        left: 6px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0;
        top: 50%;
        transform: translateY(-50%);

        svg {
            width: 24px;
            height: 24px;
            fill: rgba(0, 0, 0, .75);
        }
    }

    &__label {
        font-size: 14px;
        // padding: 4px 16px 4px 0;
        // line-height: 1.6;
        padding: 8px 16px 8px 0;
        line-height: 1.4;

        &__aside {
            margin-left: 8px;

            .Dropdown__options & {
              display: none;
            }

        }

        &__show_aside {
            margin-left: 8px;

            .Dropdown__options & {
                display: block;
              }

        }
    }

    &__button {
        padding-right: 16px;
    }
}

.DropdownSeparator,
.DropdownLabel {
    display: block;
    margin: 6px 16px 6px 52px;
}

.DropdownSeparator--fullWidth {
    margin-left: 16px;
}

.DropdownSeparator {
    height: 2px;
    background-color: rgba(0, 0, 0, .05);
}

.DropdownLabel {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    opacity: .5;
    margin-top: 8px;
}
