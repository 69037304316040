/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.Translation {
  &__interpolated {
    html.not-production &--missing {
      border-radius: 4px;
      background-color: white;
      box-shadow: 0 0 0 4px @c-danger;
    }
  }
}