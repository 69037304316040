/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
.app {
    @import 'app/modal';
    @import 'app/flyout';
    @import 'app/tools';
    @import 'app/comments';
    @import 'app/comment';
    @import 'app/comments-page';
    @import 'app/video-player';
    @import 'app/console';
    @import 'app/select';
    @import 'app/btn';
    @import 'app/btn-group';
    @import 'app/pin';
    @import 'app/pin-surface';
    @import 'app/shortcuts';
    @import 'app/file-preview';
    @import 'app/helper';
    @import 'app/proof-action';
    @import 'app/drag-drop';
    @import 'app/context';
    @import 'app/orb-select';
    @import 'app/toolbar';
    @import 'app/carousel';
    @import 'app/quote';
    @import 'app/comparison-toolbar.less';
    @import 'app/comparison-screen.less';
    @import 'app/loader';
    @import 'app/audio-waveform';
    @import 'app/proof-manage';
    @import 'app/tooltip';
    @import 'app/on-off-toggle';
    @import 'app/proof-thumbnail';
    @import 'app/ellipsis-loader';
    @import 'app/magic-menu';
}

tool-option {
    display: inline-block;
}

// Old namespaced components
@import 'app/forms';
@import 'app/mention';
@import 'app/proof-comparison-slider';
@import 'app/notifications';
@import 'app/keyboard-shortcuts';
@import 'page/workflow-dashboard';
@import 'app/slide-reveal';
