/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
@import 'color';

@easing: cubic-bezier(0.770, 0.000, 0.175, 1.000);

@keyframes spinner {
    to { transform: rotate(360deg) }
}

.spinner {
    @size: 30px;
    @weight: 4px;
    @duration: 2.5s;

    width: @size * 2;
    height: @size * 2;

    transition: transform 250ms @easing;

    --spinner-color: #138b3b;

    &--center & {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        z-index: 2;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, .5);
        z-index: 1;
    }

    &__outer {
        display: inline;

        &.spinner--center {
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }

    &__inner {
        width: @size;
        height: @size;

        margin-top: -(@size / 2);
        margin-left: -(@size / 2);

        &::before {
            content: ' ';
            position: absolute;
            top: 50%;
            left: 50%;
            width: @size * 2;
            height: @size * 2;
            margin-top: -@size;
            margin-left: -@size;

            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, .1);
            border-top-color: rgba(0, 0, 0, .5);
            animation: spinner linear infinite;
            border-width: 4px;
            border-color: rgba(0, 0, 0, .1);
            border-top-color: var(--spinner-color);
            animation-duration: 600ms;
        }
    }

    &--white &__inner::before {
        border-color: rgba(255, 255, 255, .2);
        border-top-color: #fff;
    }

    &__message {
        color: @c-green;
        font-size: 16px;
        text-align: center;
        transition: opacity .25s @easing;

        position: absolute;
        top: 100px;
        left: 50%;
        width: 400px;
        transform: translateX(-50%);

        @media (max-width: 750px) {
            font-size: 14px;
        }
    }
}
