/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__carousel {
    display: block;

    &__items {
        display: block;
        position: relative;
        overflow: hidden;
    }

    &__item {
        display: block;
        width: 100%;
        transition: transform 1s @easing;

        .app__carousel--forward &.ng-enter {
            transform: translateX(100%);
        }

        .app__carousel--back &.ng-enter {
            transform: translateX(-100%);
        }

        .app__carousel--forward &.ng-enter-active,
        .app__carousel--back &.ng-enter-active,
        .app__carousel--forward &.ng-leave,
        .app__carousel--back &.ng-leave {
            transform: translateX(0);
        }

        &.ng-leave {
            position: absolute;
            top: 0;
            left: 0;
        }

        .app__carousel--forward &.ng-leave-active {
            transform: translateX(-100%);
        }

        .app__carousel--back &.ng-leave-active {
            transform: translateX(100%);
        }
    }

    &__nav {
        text-align: center;

        &__button {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 0;
            margin: 0 4px;
            transition: background-color .25s @easing;
            cursor: pointer;

            background-color: #e1e5e6;

            &:hover {
                background-color: darken(#e1e5e6, 10%);
            }

            &--active {
                background-color: @c-green;

                &:hover {
                    background-color: @c-green-d;
                }
            }
        }
    }
}