/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
&__console {
    position: fixed;
    top: 40px;
    left: 40px;
    width: 800px;
    max-width: calc(~'100% - 80px');
    padding: 10px;
    background-color: rgba(0, 0, 0, .8);
    z-index: 2000;

    &__messages {
        max-height: 400px;
        overflow-y: auto;
        list-style: none;
        margin: 0;
    }

    &__messages,
    &__macros,
    &__macros a,
    input {
        font-family: 'Courier New', Courier, monospace;
        font-size: 13px;
        color: #fff;
    }

    .spinner__outer {
        position: absolute;
        transform: scale(0.32) translateX(40px);
        transform-origin: top left;
    }

    .spinner__top,
    .spinner__left,
    .spinner__right,
    .spinner__bottom {
        background-color: #fff;
    }

    input {
        display: block;
        background-color: transparent;
        width: 100%;
        padding: 0;
        border: 0;
        outline: none;
        height: 20px;
    }

    &__macros {
        a {
            display: inline-block;
            text-decoration: none;
            margin-right: 10px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
